import React, { createContext, useState, useEffect } from 'react';
import useScrollBlocker from 'utils/hooks/useScrollBlocker';
import usePageContext from 'utils/hooks/usePageContext';

import QuickView from './QuickView';

const QuickViewContext = createContext({});

export const QuickViewProvider = ({ children }) => {
  const [quickViewProps, setQuickViewProps] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = usePageContext();

  const showQuickView = _quickViewProps => {
    setQuickViewProps(_quickViewProps);
    setIsOpen(true);
  };

  const hideQuickView = () => {
    setQuickViewProps(null);
    setIsOpen(false);
  };

  useScrollBlocker(isOpen);

  useEffect(() => {
    if (isOpen) {
      hideQuickView();
    }
  }, [pathname]); // Close quick view when pathname changes

  return (
    <QuickViewContext.Provider value={{ showQuickView, hideQuickView }}>
      <>
        {children}
        <QuickView isOpen={isOpen} hideQuickView={hideQuickView} quickViewProps={quickViewProps} />
      </>
    </QuickViewContext.Provider>
  );
};

export default QuickViewContext;
