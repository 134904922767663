const axios = require('axios');
const HttpAgent = require('agentkeepalive');
const { HttpsAgent } = require('agentkeepalive');

const axiosInstances = {};

const getHttpInstance = host => {
  if (!host) {
    throw new Error('Missing host for http instance');
  }

  if (!axiosInstances[host]) {
    if (typeof window !== 'undefined') {
      axiosInstances[host] = axios.create();
    } else {
      const httpAgent = new HttpAgent({ keepAlive: true, maxSockets: 64 });
      const httpsAgent = new HttpsAgent({ keepAlive: true, maxSockets: 64 });

      axiosInstances[host] = axios.create({
        httpAgent,
        httpsAgent,
      });
    }
  }

  return axiosInstances[host];
};

module.exports = {
  getHttpInstance,
};
