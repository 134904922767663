import groupBy from 'lodash/groupBy';
import camelCase from 'lodash/camelCase';

import { getURLSlugComponents } from 'utils/globals';
import { getLanguage } from 'utils/queriesUtil';
import { createDate } from 'utils/date';
import {
  transformFiltersToParams,
  getContextualFilters,
  getFiltersFromRouteContext,
  getFilterUsage,
} from 'utils/globals/filters';

import {
  ENTITY_TYPE,
  ENTITY_MAIN_TABS,
  ENTITY_DETAILS_TAB,
  URL_SLUG_TYPES,
  URL_STATIC_PATHS,
  BASE_PAGE_ROUTES,
} from 'constants/index';
import { CASTING_TOOL_FILTERS, FILTER_TYPES } from 'constants/filters';

export const removeLanguageSuffixFromPath = (path, language) => {
  const regex = new RegExp(`/${language}$`);
  const [pathname, queryString] = path?.split('?');

  const updatedPathname = pathname?.replace(regex, '');

  if (queryString) {
    return `${updatedPathname}?${queryString}`;
  }

  return updatedPathname;
};

const getPathsAndLanguage = (action, language = getLanguage()) => {
  const paths = [...(action || [])];
  const lastIndex = paths?.length - 1;

  if (lastIndex > -1 && paths[lastIndex] === language) {
    paths.splice(lastIndex, 1);
  }

  return { paths, language };
};

const getMainSubPaths = (paths = [], context, edit = false, entityType) => {
  let mainPath = null;
  let subPath = null;
  let remainingPaths = paths;

  if (edit) {
    if (
      [
        URL_STATIC_PATHS.MODIFY_PRODUCTION,
        URL_STATIC_PATHS.REVIVALS,
        URL_STATIC_PATHS.SEARCH_PRODUCTIONS,
        URL_STATIC_PATHS.VALIDATION,
        URL_STATIC_PATHS.AGENCY_ARTIST_ADD,
        URL_STATIC_PATHS.AGENCY_MODIFY,
        URL_STATIC_PATHS.AGENCY_SINGLE_ARTIST_UPDATE,
      ].includes(paths?.[0])
    ) {
      return {
        mainPath: paths?.[0],
        subPath: null,
        remainingPaths: paths?.slice(1),
      };
    }

    return {
      mainPath: null,
      subPath: null,
      remainingPaths: paths,
    };
  }

  if (createDate(paths?.[0]).isValid()) {
    remainingPaths = paths;
  } else if (Object.values(ENTITY_MAIN_TABS).includes(paths?.[0])) {
    mainPath = paths?.[0];
    remainingPaths = paths?.slice(1);
  }

  if (mainPath) {
    if (
      [
        ENTITY_DETAILS_TAB.DIGITAL,
        ENTITY_DETAILS_TAB.GENERAL,
        ENTITY_DETAILS_TAB.INTRO_SHOWREEL,
        ENTITY_DETAILS_TAB.AUDITION_SHOWREEL,
        ENTITY_DETAILS_TAB.PRODUCTIONS,
        ENTITY_DETAILS_TAB.PAST,
      ].includes(remainingPaths?.[0])
    ) {
      subPath = remainingPaths?.[0];
      remainingPaths = remainingPaths?.slice(1);
    } else if (remainingPaths?.length === 1 && context?.[URL_SLUG_TYPES.PRODUCTION]?.length === 1) {
      subPath = ENTITY_DETAILS_TAB.PRODUCTIONS;
    }
  } else if (
    entityType === ENTITY_TYPE.PRODUCTION &&
    remainingPaths?.[0]?.length > 0 &&
    createDate(remainingPaths?.[0]).isValid()
  ) {
    remainingPaths = remainingPaths?.slice(1);
  }

  return {
    mainPath,
    subPath,
    remainingPaths,
  };
};

export const getValidFilters = ({ entityType, pathname, mainPath }) => {
  if (pathname?.startsWith(BASE_PAGE_ROUTES.CASTING)) {
    if (entityType === ENTITY_TYPE.ARTIST) {
      if (mainPath === ENTITY_MAIN_TABS.BIO) {
        return [];
      }

      return [
        FILTER_TYPES.GENRE,
        FILTER_TYPES.PROFESSION,
        FILTER_TYPES.CREATOR,
        FILTER_TYPES.WORK,
        ...CASTING_TOOL_FILTERS,
      ];
    }

    return [FILTER_TYPES.BOOLEAN_SEARCH, FILTER_TYPES.COUNTRY, FILTER_TYPES.SINCE_YEAR, ...CASTING_TOOL_FILTERS];
  }

  return getContextualFilters({ entityType, tabKey: mainPath || ENTITY_MAIN_TABS.HOME });
};

const getBasePath = ({ url, paths, slug, country }) => {
  const [urlPart] = url?.split('#')[0]?.split('?') || [];
  const encodedPaths = paths?.map(item => encodeURIComponent(item));

  const slugRegex = new RegExp(`/${slug}.*$`, 'g');

  const basePath = urlPart
    .replace(paths.join('/'), '')
    .replace(encodedPaths.join('/'), '')
    .replace(slugRegex, '')
    .replace(country, '')
    .replace(/\/+$/, '');

  return basePath;
};

const getEntityTypeFromBasePath = path => {
  switch (path) {
    case BASE_PAGE_ROUTES.ARTISTS:
    case BASE_PAGE_ROUTES.CASTING: {
      return ENTITY_TYPE.ARTIST;
    }
    case BASE_PAGE_ROUTES.COMPOSERS: {
      return ENTITY_TYPE.COMPOSER;
    }
    case BASE_PAGE_ROUTES.PRODUCTIONS: {
      return ENTITY_TYPE.PRODUCTION;
    }
    case BASE_PAGE_ROUTES.VENUES: {
      return ENTITY_TYPE.VENUE;
    }
    case BASE_PAGE_ROUTES.WORKS: {
      return ENTITY_TYPE.WORK;
    }
    case BASE_PAGE_ROUTES.COMPANIES: {
      return ENTITY_TYPE.ORGANIZATION;
    }
    case BASE_PAGE_ROUTES.FESTIVALS: {
      return ENTITY_TYPE.ORGANIZATION;
    }
    case BASE_PAGE_ROUTES.COMPETITIONS: {
      return ENTITY_TYPE.COMPETITION;
    }
    case BASE_PAGE_ROUTES.MANAGERS: {
      return ENTITY_TYPE.MANAGER;
    }
    default: {
      return null;
    }
  }
};

const preProcessQueryParams = query => {
  const { lng: _lng, '[...action': _action, subpath: _subpath, ...restQueryParams } = query || {};

  const { utmParams, queryParams } = Object.keys(restQueryParams).reduce(
    (acc, key) => {
      if (key.startsWith('utm_')) {
        acc.utmParams[camelCase(key)] = restQueryParams[key];
      } else {
        acc.queryParams[key] = restQueryParams[key];
      }

      return acc;
    },
    { utmParams: {}, queryParams: {} },
  );

  return {
    utmParams,
    queryParams,
  };
};

const getRouteContext = (router, requestLanguage) => {
  // TODO: Remove entitySlug and entityType dependency
  const { query, asPath, isLoggedIn } = router;
  const { action, country, entitySlug, entityType, id, program_published, ...rest } = query || {};
  const { utmParams, queryParams } = preProcessQueryParams(rest);
  const { paths, language } = getPathsAndLanguage(action, requestLanguage);
  const cleanedAsPath = asPath?.split('?')?.[0];
  const pagePathWithoutLanguage = removeLanguageSuffixFromPath(cleanedAsPath, language);

  const urlSlugs = paths?.map(item => getURLSlugComponents(item));

  const { ignore: _ignore, ...context } = groupBy(urlSlugs, item => item?.slugType || 'ignore');

  const basePath = getBasePath({ url: pagePathWithoutLanguage, paths, language, slug: entitySlug || id, country });
  const pro = pagePathWithoutLanguage.startsWith(BASE_PAGE_ROUTES.CASTING);
  let edit = false;
  let entityId = null;
  let slug = null;
  let type = pro ? null : getEntityTypeFromBasePath(basePath);
  let filterPaths = paths;
  let countrySlug = country;

  if (entitySlug || id) {
    const entitySlugComponents = getURLSlugComponents(entitySlug || id);

    if (type === ENTITY_TYPE.MANAGER && !entitySlugComponents?.slug) {
      countrySlug = id;
    } else {
      const page = pagePathWithoutLanguage.split('/').pop();
      entityId = entitySlugComponents?.id;
      slug = entitySlugComponents?.slug;
      type = entitySlugComponents?.entityType || entityType || getEntityTypeFromBasePath(basePath);
      edit = type === ENTITY_TYPE.MANAGER ? page !== id : false;
      filterPaths = pagePathWithoutLanguage.split(`${id}/`)?.[1]?.split('/') || [];
    }
  } else if ([ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(urlSlugs?.[0]?.entityType)) {
    entityId = urlSlugs?.[0]?.id;
    slug = urlSlugs?.[0]?.slug;
    type = urlSlugs?.[0]?.entityType;
    filterPaths = paths?.slice(1);

    if (urlSlugs?.[1]?.id === URL_STATIC_PATHS.EDIT) {
      edit = true;
      filterPaths = paths?.slice(2);
    }
  }

  Object.keys(queryParams).forEach(key => {
    if ([URL_SLUG_TYPES.BOOLEAN_SEARCH, URL_SLUG_TYPES.YEAR, URL_SLUG_TYPES.PRODUCTION_PERIOD].includes(key)) {
      context[key] = queryParams[key];
    } else if (URL_SLUG_TYPES.VIEW_MODE !== key) {
      const values = queryParams[key]?.split(',');

      if (Array.isArray(values) && values?.length > 0) {
        context[key] = values?.map(item => {
          const idString = item?.replace(/\D/g, '');

          return { id: idString ? parseInt(idString, 10) : null };
        });
      }
    }
  });

  const { mainPath, subPath, remainingPaths } = getMainSubPaths(filterPaths, context, edit, type);
  const productionId = context[URL_SLUG_TYPES.PRODUCTION]?.[0]?.id || null;

  const validFilters = getValidFilters({ entityType: type, mainPath, pathname: pagePathWithoutLanguage });
  const filters = getFiltersFromRouteContext({ validFilters, context, queryParams });
  const filterTypeUsage = getFilterUsage({ entityType: type, filters, isLoggedIn });
  const filterParams = transformFiltersToParams(filters);

  if ([BASE_PAGE_ROUTES.COMPANIES, BASE_PAGE_ROUTES.FESTIVALS].includes(basePath)) {
    if (remainingPaths?.[0] !== URL_STATIC_PATHS.SEARCH) {
      countrySlug = paths?.[0];
    }
  } else if (basePath === BASE_PAGE_ROUTES.SEASONS) {
    countrySlug = paths?.[1];
  }

  return {
    entityId,
    entitySlug: slug,
    entityType: type,
    program_published,
    pro,
    edit,
    countrySlug,
    context,
    paths: remainingPaths,
    basePath,
    mainPath,
    subPath,
    language,
    urlSlugs,
    productionId,
    queryParams,
    filters,
    filterParams,
    validFilters,
    filterTypeUsage,
    pathname: cleanedAsPath, // TODO: Check if we can start sending pagePathWithoutLanguage
    utmParams,
  };
};

export default getRouteContext;
