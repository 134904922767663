import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import useTracking from 'components/Globals/Analytics';
import {
  SECTIONS,
  COMPONENTS,
  ACTION_CALLBACK_STEPS,
  ACTION_CALLBACK_STATUS,
} from 'components/Globals/Analytics/constants';

import queries from 'containers/Globals/queries';
import { errorMessageConverter } from 'containers/Accounts/utils';

import { useQuery } from 'utils/react-query';
import { setCookie, getCookie, removeCookie } from 'utils/cookie';
import usePermissions from 'utils/permissions';
import { useDialogs } from 'utils/hooks/useDialogs';
import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';

import { LOGIN_SOURCE } from 'constants/index';
import routes from 'constants/routes';
import {
  OB_EMAIL_ID,
  RETURNING_USER,
  OB_VISITOR_ID,
  LOGIN_TYPE_RETURNING,
  RETURNING_USER_NAME,
} from 'constants/cookieConstants';

// 1. Provide Default Values
const defaultContextValue = {
  showPaywall: false,
  setShowPaywall: () => {},
  visitorId: '',
  setVisitorId: () => {},
};

const PaywallContext = createContext(defaultContextValue);

const PaywallContextProvider = ({ children }) => {
  const track = useTracking();
  const [showPaywall, setShowPaywall] = useState(false);
  const { paywallType } = useAppContext();
  const { pathname } = usePageContext();
  const { data: visitorData } = useVisitorData();
  const { isLoggedIn } = usePermissions();
  const { setIsLoginDialog } = useDialogs();
  const [showCastingToolSubscription, setShowCastingToolSubscription] = useState(false);
  const visitorIdCookie = getCookie(OB_VISITOR_ID) || visitorData?.visitorId;
  const isReturningUserCookie = getCookie(RETURNING_USER) === 'true';
  const { data: userFingerprintData } = useQuery(
    queries.getUserByVisitorId({
      id: visitorIdCookie,
      queryConfig: {
        enabled: !!visitorIdCookie && !isReturningUserCookie && !isLoggedIn,
        onSuccess: response => {
          const userEmail = response?.data?.[0]?.user?.email;
          const loginType = response?.data?.[0]?.user?.auth_via;
          const userName = response?.data?.[0]?.user?.name;
          if (userEmail) {
            setCookie(OB_EMAIL_ID, userEmail);
            setCookie(RETURNING_USER, true, 1);
            setCookie(LOGIN_TYPE_RETURNING, loginType, 1);
            setCookie(RETURNING_USER_NAME, userName, 1);

            if (!pathname.includes(routes.SET_PASSWORD) && !pathname.includes(routes.RESET_PASSWORD)) {
              setIsLoginDialog({ isOpen: true, source: LOGIN_SOURCE.RETURNING_USER });
            }

            track.actionCallback({
              section: SECTIONS.RETURNING_USER,
              component: COMPONENTS.MODAL,
              meta: {
                step: ACTION_CALLBACK_STEPS.VIEW_RETURNING_USER_MODAL,
                actionStatus: ACTION_CALLBACK_STATUS.SUCCESS,
                payload: {
                  userEmail,
                  loginType,
                },
              },
            });
          }
        },
        onError: error => {
          track.actionCallback({
            section: SECTIONS.RETURNING_USER,
            component: COMPONENTS.MODAL,
            meta: {
              step: ACTION_CALLBACK_STEPS.VIEW_RETURNING_USER_MODAL,
              actionStatus: ACTION_CALLBACK_STATUS.FAILURE,
              errorMessage: errorMessageConverter(error),
            },
          });
        },
      },
    }),
  );

  useEffect(() => {
    if (isLoggedIn) {
      removeCookie(RETURNING_USER);
    }
    if (
      isReturningUserCookie &&
      !isLoggedIn &&
      !pathname.includes(routes.SET_PASSWORD) &&
      !pathname.includes(routes.RESET_PASSWORD)
    ) {
      setIsLoginDialog({ isOpen: true, source: LOGIN_SOURCE.RETURNING_USER });
      track.actionCallback({
        section: SECTIONS.RETURNING_USER,
        component: COMPONENTS.MODAL,
        meta: {
          step: ACTION_CALLBACK_STEPS.VIEW_RETURNING_USER_MODAL,
          actionStatus: ACTION_CALLBACK_STATUS.SUCCESS,
        },
      });
    }
  }, [userFingerprintData, isLoggedIn, setIsLoginDialog, pathname]);

  useEffect(() => {
    if (paywallType) {
      track.actionCallback({
        section: SECTIONS.PAYWALL,
        component: COMPONENTS.MODAL,
        meta: {
          step: paywallType,
          actionStatus: ACTION_CALLBACK_STATUS.SUCCESS,
        },
      });
    }
  }, [paywallType, showPaywall, track]);

  // 3. Use useMemo to avoid unnecessary re-renders of context consumers
  const contextValue = useMemo(
    () => ({
      showPaywall: paywallType,
      setShowPaywall,
      setShowCastingToolSubscription,
      showCastingToolSubscription,
    }),
    [paywallType, showCastingToolSubscription],
  );
  return <PaywallContext.Provider value={contextValue}>{children}</PaywallContext.Provider>;
};

// 2. Make Custom Hooks for Context
const usePaywallContext = () => {
  const context = useContext(PaywallContext);

  // 4. Error Handling
  if (context === undefined) {
    throw new Error('usePaywallContext must be used within a PaywallContextProvider');
  }

  return context;
};

export { usePaywallContext, PaywallContextProvider };
