import { PRO_PAGES } from '../containers/ProPages/constants';
import { FESTIVAL_TAB_VALUES, PRO_TAB_VALUES } from './consts';

export const route = {
  ACCOUNT_SETTINGS: '/account-settings',
  ACCOUNT_SUBSCRIPTIONS: '/account-subscription',
  ARTICLES: '/articles',
  ARTISTS: '/artists/[[...action]]',
  CASTING_TOOL: '/casting/[[...action]]',
  COMPANIES: '/companies/[[...action]]',
  COMPETITIONS: '/competitions',
  COMPOSERS: '/composers',
  CONTACT_OB: '/contact',
  COOKIE_POLICY: '/cookpol',
  DYNAMIC_ID_ROUTE: rootRoute => `${rootRoute}/[id]`,
  DYNAMIC_ID_ROUTE_WITH_ACTION: rootRoute => `${rootRoute}/[id]/[[...action]]`,
  FESTIVALS: '/festivals/[[...action]]',
  HIGHLIGHTS: '/highlights',
  HOME: '/[[...action]]',
  VIDEOS: '/videos',

  INTRO: '/pro/[type]',
  INTRO_ALL: '/pro/all',
  INTRO_AGENTS: `/pro/${PRO_PAGES.AGENCIES}`,
  INTRO_AOS: `/pro/${PRO_PAGES.COMPANIES}`,
  INTRO_ARTISTS: `/pro/${PRO_PAGES.ARTISTS}`,
  INTRO_AUDIENCE: '/proaudience',
  INTRO_ACADEMIA: `/pro/${PRO_TAB_VALUES.ACADEMIA}`,
  INTRO_JOURNALISTS: `/pro/${PRO_TAB_VALUES.JOURNALIST}`,

  DETAILS: `/pro/[type]/details`,
  DETAILS_AGENTS: `/pro/${PRO_PAGES.AGENCIES}/details`,
  DETAILS_AOS: `/pro/${PRO_PAGES.COMPANIES}/details`,
  DETAILS_ARTISTS: `/pro/${PRO_PAGES.ARTISTS}/details`,

  INTRO_ARCHIVES: '/',
  INTRO_OB: '/intro',
  JOURNALISTS_AND_RESEARCH: '/journalist-and-research', // TODO change on real
  LIBRARY: '/library',
  LOGIN: '/login',
  MANAGERS: '/managers',
  MAPS: '/maps',
  NEW_RARE: '/new-and-rare',
  ON_THESE_DAY: '/onthisday',
  PAYMENT: '/payment',
  REACTIVATE: '/payment/reactivate',
  PRIVACY: '/privpol',
  PRODUCTIONS: '/productions',
  REGISTER: '/register',
  PRO_AUDIENCE: '/proaudience',
  RENTALS: '/rentals',
  SET_PASSWORD: '/login/set',
  RESET_PASSWORD: '/login/reset',
  SEASONS: '/seasons',
  SECURITY: '/security',
  STATISTICS: '/statistics',
  STATS_NUMBERS: '/stats/numbers',
  SEARCH: '/search',
  VISITS_HISTORY: '/search/visited',
  SEARCH_HISTORY: '/search/recent',
  TERMS_AND_CONDITIOS: '/termpol',
  VENUES: '/venues',
  WORK_WITH_OB: '/',
  WORKS: '/works',
  PAYMENT_TERMS: '/payment-terms',
  NOTIFICATIONS: '/notifications',
  LINK: '/link',
  SELECT_PROFILE: '/select-profile',
  COMPANIES_SEARCH: '/companies/[[...action]]',
  FESTIVALS_SEARCH: '/festivals/[[...action]]',
  ARTIST_SEARCH: '/artists/[[...action]]',
  MANAGERS_SEARCH: '/managers/search',
  VENUES_SEARCH: '/venues/search',
};

export const actionsPagesRoutes = {
  ADD_ARTIST_TO_ROSTER: 'agency_artist_add',
  ADD_AGREEMENTS: 'agency_single_artist_updt',
  EDIT_AGENCY: 'agency_modif',
};

export const companiesPaths = {
  country: `${route.COMPANIES}/[country]`,
  company: `${route.COMPANIES}/[country]/[id]/[[...action]]`,
  edit: `${route.COMPANIES}/[country]/[id]/edit`,
  revivals: `${route.COMPANIES}/[country]/[id]/edit/revivals`,
  searchProductions: `${route.COMPANIES}/[country]/[id]/edit/search-productions`,
  addProduction: `${route.COMPANIES}/[country]/[id]/edit/modify-production`,
};

export const agencyPaths = {
  agency: `${route.MANAGERS}/[id]`,
  edit: `${route.MANAGERS}/[id]/agency_modif`,
};

export const festivalPaths = {
  view: `${route.FESTIVALS}/[country]/[id]`,
  edit: `${route.FESTIVALS}/[country]/[id]/edit`,
  modifyProduction: `${route.FESTIVALS}/[country]/[id]/edit/modify-production`,
  contact: `${route.FESTIVALS}/[country]/[id]/${FESTIVAL_TAB_VALUES.CONTACT}`,
  venues: `${route.FESTIVALS}/[country]/[id]/${FESTIVAL_TAB_VALUES.VENUES}`,
  about: `${route.FESTIVALS}/[country]/[id]/${FESTIVAL_TAB_VALUES.ABOUT}`,
  performances: `${route.FESTIVALS}/[country]/[id]/${FESTIVAL_TAB_VALUES.PERFORMANCES}`,
  videos: `${route.FESTIVALS}/[country]/[id]/${FESTIVAL_TAB_VALUES.VIDEOS}`,
  images: `${route.FESTIVALS}/[country]/[id]/${FESTIVAL_TAB_VALUES.IMAGES}`,
  reviews: `${route.FESTIVALS}/[country]/[id]/${FESTIVAL_TAB_VALUES.REVIEWS}`,
  revivals: `${route.FESTIVALS}/[country]/[id]/edit/revivals`,
  festival: `${route.FESTIVALS}/[country]/[id]/[[...action]]`,
  searchProductions: `${route.FESTIVALS}/[country]/[id]/edit/search-productions`,
};

export const venuePaths = {
  venue: `${route.VENUES}/[country]/[id]/[[...action]]`,
};

export const videosPaths = {
  videos: `${route.VIDEOS}/`,
  videosSearch: `${route.VIDEOS}/search`,
};

export default route;
