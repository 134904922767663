export const primaryLanguageCodes = [
  {
    display_name: 'English',
    iso: 'en',
  },
  {
    display_name: 'Deutsch',
    iso: 'de',
  },
  {
    display_name: 'Italiano',
    iso: 'it',
  },
  {
    display_name: 'Français',
    iso: 'fr',
  },
  {
    display_name: 'Español',
    iso: 'es',
  },
];

export const allLanguageCodes = [
  {
    display_name: 'Català',
    iso: 'ca',
  },
  {
    display_name: 'Czech',
    iso: 'cs',
  },
  {
    display_name: 'Dansk',
    iso: 'da',
  },
  {
    display_name: 'Deutsch',
    iso: 'de',
  },
  {
    display_name: 'Eesti',
    iso: 'et',
  },
  {
    display_name: 'English',
    iso: 'en',
  },
  {
    display_name: 'Español',
    iso: 'es',
  },
  {
    display_name: 'Euskara',
    iso: 'eu',
  },
  {
    display_name: 'Français',
    iso: 'fr',
  },
  {
    display_name: 'Gaeilge',
    iso: 'ga',
  },
  {
    display_name: 'Italiano',
    iso: 'it',
  },
  {
    display_name: 'Latviešu',
    iso: 'lv',
  },
  {
    display_name: 'Lietuvių',
    iso: 'lt',
  },
  {
    display_name: 'Magyar',
    iso: 'hu',
  },
  {
    display_name: 'Nederlands',
    iso: 'nl',
  },
  {
    display_name: 'Norsk',
    iso: 'no',
    datetimeLocale: 'nb',
  },
  {
    display_name: 'Polski',
    iso: 'pl',
  },
  {
    display_name: 'Português',
    iso: 'pt',
  },
  {
    display_name: 'Română',
    iso: 'ro',
  },
  {
    display_name: 'Slovak',
    iso: 'sk',
  },
  {
    display_name: 'Slovenčina',
    iso: 'sl',
  },
  {
    display_name: 'Suomi',
    iso: 'fi',
  },
  {
    display_name: 'Svenska',
    iso: 'sv',
  },
  {
    display_name: 'bil-Malti',
    iso: 'mt',
  },
  {
    display_name: 'Íslenska',
    iso: 'is',
  },
  {
    display_name: 'Ελληνικά',
    iso: 'el',
  },
  {
    display_name: 'Български',
    iso: 'bg',
  },
  {
    display_name: 'Русский',
    iso: 'ru',
  },
  {
    display_name: '中文',
    iso: 'zh',
  },
  {
    display_name: '日本語',
    iso: 'ja',
  },
  {
    display_name: '한국어',
    iso: 'ko',
  },
];
