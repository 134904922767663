import { findIndex } from 'lodash';
import { changeItemInObjectsArray } from 'utils/globals/app';
import {
  getOrgSeasonsValueAction,
  getCompanyUnpublishedSeasonAction,
  updateOrgAction,
  getMusicalWorksListAction,
  getCompanyEditSeasonAction,
  getCompanyImagesAction,
  getCompanyVideosAction,
  getCompanyStaffAction,
  getOrgDescriptionsAction,
  createOrgDescriptionAction,
  updateOrgDescriptionAction,
  deleteOrgDescriptionAction,
  updateOrgVideoAction,
  addCompanyVideoAction,
  getCompanyProductionsAction,
  getCompanyEmployeesAction,
  getCompanyBannerImagesAction,
  getCompanyLsVodAction,
  getCompanyProductionVideosAction,
  getCompanyProductionImagesAction,
  getCompanySearchProductionsAction,
  getOrgTranslationsAction,
  deleteOrgTranslationsAction,
  getAboutOrgVideoAction,
  getCompanyReviewsAction,
  getCompanyProductionReviewsAction,
  getOrgVenuesAction,
  getOrgRelationAction,
  deleteOrgRelationAction,
  createOrgRelationAction,
  getOrgContactAction,
  getOrgAddressesAction,
  getOrgDataAction,
} from './actions';
import {
  listReducer,
  defaultListParams,
  entityReducer,
  defaultEntityParams,
  namedListParams,
} from '../../utils/crudUtils';
import { CLEAR_EDIT_SEASONS, SET_COMPANY, SET_SEASONS } from './constants';

export const key = 'Org';

export const initialState = {
  orgData: defaultEntityParams,
  editSeasonProductions: defaultListParams,
  unpublishedProductions: defaultListParams,
  venues: namedListParams('venues'),
  seasons: defaultListParams,
  error: null,
  musicalWorksList: defaultListParams,
  images: namedListParams('images'),
  productionImages: namedListParams('productionImages'),
  videos: namedListParams('videos'),
  productionVideos: namedListParams('productionVideos'),
  lsVod: namedListParams('lsVod'),
  searchProductions: namedListParams('searchProductions'),
  staff: namedListParams('staff'),
  isStaffUpdating: false,
  staffSuggestions: namedListParams('staffSuggestions'),
  descriptions: namedListParams('descriptions'),
  productions: namedListParams('productions'),
  employees: namedListParams('employees'),
  banner: namedListParams('banner'),
  aboutOrgVideo: namedListParams('aboutOrgVideo'),
  reviews: namedListParams('reviews'),
  productionReviews: namedListParams('productionReviews'),
};

const organizationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getOrgDataAction.TRIGGER:
    case getOrgDataAction.SUCCESS:
    case getOrgDataAction.FAILURE:
      return entityReducer(state, type, payload, 'orgData');

    case getOrgContactAction.TRIGGER:
    case getOrgContactAction.SUCCESS:
    case getOrgContactAction.FAILURE:
      return listReducer(
        state,
        type,
        payload.data
          ? { ...payload, data: payload.data.map(el => ({ ...el, name: el?.name?.toLowerCase() })) }
          : payload,
        'contacts',
      );

    case getOrgAddressesAction.TRIGGER:
    case getOrgAddressesAction.SUCCESS:
    case getOrgAddressesAction.FAILURE:
      return listReducer(
        state,
        type,
        payload.data
          ? { ...payload, data: payload?.data?.map(el => ({ ...el, name: el?.name?.toLowerCase() })) }
          : payload,
        'addresses',
      );

    case getCompanyEditSeasonAction.TRIGGER:
    case getCompanyEditSeasonAction.SUCCESS:
    case getCompanyEditSeasonAction.FAILURE:
      return listReducer(state, type, payload, 'editSeasonProductions');

    case getCompanyUnpublishedSeasonAction.TRIGGER:
    case getCompanyUnpublishedSeasonAction.SUCCESS:
    case getCompanyUnpublishedSeasonAction.FAILURE:
      return listReducer(state, type, payload, 'unpublishedProductions');

    case getOrgSeasonsValueAction.TRIGGER:
    case getOrgSeasonsValueAction.SUCCESS:
    case getOrgSeasonsValueAction.FAILURE:
      return listReducer(state, type, payload, 'seasons');

    case SET_SEASONS:
      return { ...state, seasons: payload.seasons };

    case getOrgVenuesAction.TRIGGER:
    case getOrgVenuesAction.SUCCESS:
    case getOrgVenuesAction.FAILURE:
      return listReducer(state, type, payload, initialState.venues.listName);

    case updateOrgAction.TRIGGER:
    case updateOrgAction.SUCCESS:
    case updateOrgAction.FAILURE:
      return entityReducer(state, type, payload, 'orgData');

    case SET_COMPANY:
      return { ...state, orgData: { ...state.orgData, entity: payload } };

    case CLEAR_EDIT_SEASONS:
      return { ...state, editSeasonProductions: { ...state.editSeasonProductions, data: [] } };

    case getMusicalWorksListAction.TRIGGER:
    case getMusicalWorksListAction.SUCCESS:
    case getMusicalWorksListAction.FAILURE:
      return listReducer(state, type, payload, 'musicalWorksList');

    case getCompanyImagesAction.FAILURE:
    case getCompanyImagesAction.TRIGGER:
    case getCompanyImagesAction.SUCCESS:
      return listReducer(state, type, payload, initialState.images.listName);

    case getCompanyProductionImagesAction.FAILURE:
    case getCompanyProductionImagesAction.TRIGGER:
    case getCompanyProductionImagesAction.SUCCESS:
      return listReducer(state, type, payload, initialState.productionImages.listName);

    case getCompanyBannerImagesAction.FAILURE:
    case getCompanyBannerImagesAction.TRIGGER:
    case getCompanyBannerImagesAction.SUCCESS:
      return listReducer(state, type, payload, initialState.banner.listName);

    case getCompanyVideosAction.FAILURE:
    case getCompanyVideosAction.TRIGGER:
    case getCompanyVideosAction.SUCCESS:
      return listReducer(state, type, payload, initialState.videos.listName);

    case getCompanyProductionVideosAction.FAILURE:
    case getCompanyProductionVideosAction.TRIGGER:
    case getCompanyProductionVideosAction.SUCCESS:
      return listReducer(state, type, payload, initialState.productionVideos.listName);

    case getCompanyLsVodAction.FAILURE:
    case getCompanyLsVodAction.TRIGGER:
    case getCompanyLsVodAction.SUCCESS:
      return listReducer(state, type, payload, initialState.lsVod.listName);

    case getCompanyStaffAction.FAILURE:
    case getCompanyStaffAction.TRIGGER:
    case getCompanyStaffAction.SUCCESS:
      return listReducer(state, type, payload, initialState.staff.listName);

    case getOrgDescriptionsAction.TRIGGER:
    case getOrgDescriptionsAction.SUCCESS:
    case getOrgDescriptionsAction.FAILURE:
      return listReducer(state, type, payload, 'descriptions');

    case getCompanySearchProductionsAction.FAILURE:
    case getCompanySearchProductionsAction.TRIGGER:
    case getCompanySearchProductionsAction.SUCCESS:
      return listReducer(state, type, payload, 'searchProductions');

    case createOrgDescriptionAction.SUCCESS:
      return { ...state, descriptions: { ...state.descriptions, data: [...state.descriptions.data, payload.entity] } };

    case updateOrgDescriptionAction.SUCCESS: {
      const descriptions = [...state?.descriptions?.data];
      const updatedDescriptionIndex = findIndex(state?.descriptions?.data, { id: payload?.entity?.id });
      descriptions.splice(updatedDescriptionIndex, 1, payload?.entity);
      return { ...state, descriptions: { ...state.descriptions, data: descriptions } };
    }

    case deleteOrgDescriptionAction.TRIGGER:
      return { ...state, descriptions: { ...state.descriptions, loading: true } };
    case deleteOrgDescriptionAction.SUCCESS: {
      return {
        ...state,
        descriptions: {
          ...state.descriptions,
          data: state.descriptions.data.filter(d => d?.id !== payload?.id),
          loading: false,
        },
      };
    }
    case deleteOrgDescriptionAction.FAILURE:
      return { ...state, descriptions: { ...state.descriptions, loading: false } };

    case updateOrgVideoAction.TRIGGER:
      return { ...state, mediaUpdating: true };
    case updateOrgVideoAction.SUCCESS: {
      return {
        ...state,
        [state.videos.listName]: {
          ...state[state.videos.listName],
          data: changeItemInObjectsArray(state[state.videos.listName]?.data, payload?.entity),
        },
        mediaUpdating: false,
      };
    }
    case updateOrgVideoAction.FAILURE:
      return { ...state, mediaUpdating: false };

    case addCompanyVideoAction.TRIGGER:
      return { ...state, mediaUpdating: true };
    case addCompanyVideoAction.SUCCESS: {
      return {
        ...state,
        videos: { ...state.videos, data: [...state.videos.data, payload?.entity] },
        mediaUpdating: false,
      };
    }
    case addCompanyVideoAction.FAILURE:
      return { ...state, mediaUpdating: false };

    case getCompanyProductionsAction.FAILURE:
    case getCompanyProductionsAction.TRIGGER:
    case getCompanyProductionsAction.SUCCESS:
      return listReducer(state, type, payload, state.productions.listName);

    case getCompanyEmployeesAction.FAILURE:
    case getCompanyEmployeesAction.TRIGGER:
    case getCompanyEmployeesAction.SUCCESS:
      return listReducer(state, type, payload, state.employees.listName);
    case getOrgTranslationsAction.TRIGGER:
    case getOrgTranslationsAction.SUCCESS:
    case getOrgTranslationsAction.FAILURE:
      return listReducer(state, type, payload, 'translations');
    case deleteOrgTranslationsAction.TRIGGER:
    case deleteOrgTranslationsAction.FAILURE:
      return { ...state, festivals: { ...state.translations, loading: true } };
    case deleteOrgTranslationsAction.SUCCESS: {
      return {
        ...state,
        translations: {
          ...state.translations,
          data: state.translations.data.filter(d => d?.id !== payload?.id),
          loading: false,
        },
      };
    }
    case getOrgRelationAction.TRIGGER:
    case getOrgRelationAction.SUCCESS:
    case getOrgRelationAction.FAILURE:
      return listReducer(state, type, payload, 'associatedOrgs');
    case deleteOrgRelationAction.TRIGGER:
    case deleteOrgRelationAction.FAILURE:
      return { ...state, associatedOrgs: { ...state.associatedOrgs, loading: true } };
    case deleteOrgRelationAction.SUCCESS: {
      return {
        ...state,
        associatedOrgs: {
          ...state.associatedOrgs,
          data: state.associatedOrgs.data.filter(d => d?.id !== payload?.id),
          loading: false,
        },
      };
    }
    case createOrgRelationAction.SUCCESS:
      return {
        ...state,
        associatedOrgs: { ...state.associatedOrgs, data: [...state.associatedOrgs.data, payload.entity] },
      };
    case getAboutOrgVideoAction.TRIGGER:
    case getAboutOrgVideoAction.SUCCESS:
    case getAboutOrgVideoAction.FAILURE:
      return listReducer(state, type, payload, 'aboutOrgVideo');
    case getCompanyReviewsAction.TRIGGER:
    case getCompanyReviewsAction.SUCCESS:
    case getCompanyReviewsAction.FAILURE:
      return listReducer(state, type, payload, state?.reviews?.listName);

    case getCompanyProductionReviewsAction.TRIGGER:
    case getCompanyProductionReviewsAction.SUCCESS:
    case getCompanyProductionReviewsAction.FAILURE:
      return listReducer(state, type, payload, state?.productionReviews?.listName);
    default:
      return state;
  }
};

export default organizationReducer;
