import { get, groupBy } from 'lodash';
import { createSelectFieldOptions, separateExcludedIncludedCountries } from 'utils/globals/app';
import { AGREEMENTS_CASES, AGREEMENTS_TYPES, professionAgreementsFormFields } from './constants';

export const getProfessionAgreementsFormValues = agreements => {
  const getCountriesOptions = agreement => {
    const regions = get(agreement, 'regions', []);
    const countries = get(agreement, 'countries', []);
    const regionsOptions = createSelectFieldOptions(regions, 'id', 'name', { type: 'region' }, true);
    const countriesOptions = createSelectFieldOptions(countries, 'id', 'name', { type: 'country' }, true);
    return [...regionsOptions, ...countriesOptions];
  };

  const updatedAgreements = {};
  agreements.forEach(agr => {
    const updatedAgr = { ...agr };
    const type = get(agr, 'agreementType', '');
    const isExclusive = get(agr, 'exclusive', false);

    if (isExclusive) {
      const countries = separateExcludedIncludedCountries(get(agr, 'countries', []));
      const regions = separateExcludedIncludedCountries(get(agr, 'regions', []));

      updatedAgr.includedCountries = getCountriesOptions({
        countries: get(countries, 'included', []),
        regions: get(regions, 'included', []),
      });
      updatedAgr.excludedCountries = getCountriesOptions({
        countries: get(countries, 'excluded', []),
        regions: get(regions, 'excluded', []),
      });
    } else {
      updatedAgr.includedCountries = getCountriesOptions(agr);
    }

    switch (type) {
      case AGREEMENTS_TYPES.OPERA:
        updatedAgreements[
          isExclusive ? AGREEMENTS_CASES.OPERA_EXCLUSIVE : AGREEMENTS_CASES.OPERA_NON_EXCLUSIVE
        ] = updatedAgr;
        break;
      case AGREEMENTS_TYPES.CONCERT:
        updatedAgreements[
          isExclusive ? AGREEMENTS_CASES.CONCERT_EXCLUSIVE : AGREEMENTS_CASES.CONCERT_NON_EXCLUSIVE
        ] = updatedAgr;
        break;
      case AGREEMENTS_TYPES.GENERAL_MENEGMENT:
        updatedAgreements[AGREEMENTS_CASES.GENERAL_MENEGMENT] = { ...agr, checked: true };
        break;
      default:
        break;
    }
  });
  return {
    [professionAgreementsFormFields.AGREEMENTS]: updatedAgreements,
    [professionAgreementsFormFields.CONTACT_PERSONS]: createSelectFieldOptions(
      get(agreements, '[0].contactPersons', []),
      'id',
      'agentName',
    ),
    [professionAgreementsFormFields.AGENTS]: get(agreements, '[0].agents', []),
  };
};

export const groupArtistAgreementsByProfession = allArtistAgreements =>
  groupBy(allArtistAgreements, 'agencyConnectionProfession.id');
