export const getProfileName = profile => {
  if (profile?.fullName?.length > 0) {
    return profile?.fullName;
  }

  let artistFirstAndLastName = '';

  if (profile?.firstName) {
    artistFirstAndLastName += profile?.firstName;
  }
  if (profile?.lastName) {
    if (artistFirstAndLastName?.length > 0) {
      artistFirstAndLastName += ' ';
    }

    artistFirstAndLastName += profile?.lastName;
  }

  if (artistFirstAndLastName?.length > 0) {
    return artistFirstAndLastName;
  }

  return profile?.name;
};
