export const ARTICLE_AGGREGATION_TYPES = {
  YEAR: 'year',
  TITLE: 'work_id',
  COMPANY: 'company',
};

export const MEDIA_TYPES_IDS = {
  IMAGE: 1,
  VIDEO: 2,
  AUDIO: 3,
  LS: 4,
  VOD: 5,
};

export const MEDIA_TAGS_TYPES_IDS = {
  PROFILE: 1,
  COMPANY: 2,
  VENUE: 3,
  PRODUCTION: 4,
  COMPOSER: 5,
  DATE: 6,
  FESTIVAL: 7,
  WORK: 8,
  CAST: 9,
  PRODUCTION_COMPANY: 10,
  PRODUCTION_FESTIVAL: 11,
};

export const ARTIST_SORT_ORDER = 'last_name,first_name';

export const performancesAggregationTypes = {
  agencyAgg: 'agency_id',
  companyAgg: 'company_id',
  composerAgg: 'work_creator_id',
  countryAgg: 'country_id',
  cityAgg: 'city_id',
  crewProfileRefAgg: 'crew_profile_reference',
  festivalAgg: 'festival_id',
  labelAgg: 'label_id',
  productionAgg: 'production_id',
  professionAgg: 'profession_id',
  professionQualificationAgg: 'profession_qualification_id',
  profileAgg: 'profile_id',
  profileTypeAgg: 'profile_type_id',
  queryAgg: 'aggregation_query',
  regionAgg: 'region_id',
  seasonAgg: 'season_id',
  typeAgg: 'aggregation_type',
  venueAgg: 'venue_id',
  workAgg: 'work_id',
  workRoleAgg: 'work_role_id',
  workTypeAgg: 'work_type_id',
  yearAgg: 'year_id',
  workRoleReferenceAgg: 'work_role_reference',
};

export const PRODUCTION_ADDED_IDENTIFIERS_IDS = {
  UNKNOWN: 1,
  ADMIN: 2,
  COMPANY: 3,
  ARTIST: 4,
  AGENCY: 5,
  FESTIVAL: 6,
};

export const ENTITY_ACTION_TYPE = {
  NEW: 'new',
  EDIT: 'edit',
};

export const AGENCY_ACTORS = {
  ARTIST: 'artist',
  AGENCY: 'agency',
};

export const PERFORMANCE_TYPES_ID = {
  WORLD_PREMIERE: 1,
  NATIONAL_PREMIERE: 2,
  CONCERT: 3,
  NEW_PRODUCTION: 4,
  CANCELLED: 5,
  TOUR: 6,
  FESTIVAL: 7,
  MATINEE: 8,
  DOUBLE_BILL: 9,
  RECITAL: 10,
  LIVESTREAM: 11,
  VIDEO_ON_DEMAND: 12,
};

export const PRODUCTION_DOCUMENT_TYPES = {
  SYNOPSIS: 'synopsis',
  BOOKLET: 'booklet',
  ACT: 'act',
  DESCRIPTION: 'description',
  SYNOPSIS_ACT: 'synopsisAct',
  BOOKLET_ACT: 'bookletAct',
  SUPPORT_PRODUCTION_PAGE: 'supportProductionPage',
  SIGNED_CONTRACT: 'supportSignedContract',
  PRODUCTION_REVIEW: 'supportProductionReview',
  PRODUCTION_PROGRAMME: 'supportProductionProgramme',
  PRODUCTION_CHANGE_REQUEST_PROOF: 'supportChangeRequestProof',
};

export const MEDIA_ATTRIBUTES_TYPES_IDS = {
  IMAGE_FORMAT: 1,
  WIDTH: 2,
  HEIGHT: 3,
  POSITION: 4,
  VIDEO_FORMAT: 5,
  DURATION: 6,
  GENRE: 7,
  TAGS: 8,
  AUDIO_FORMAT: 9,
  VIDEO: 11,
  IMAGE: 12,
  BANNER: 19,
  RECORDING_DATE: 60,
  ENTITY_ABOUT_VIDEO: 26,
};

export const PROFESSIONS_AVAILABILITY = {
  CREATE_PROFILE: 'create_profile',
  UPDATE_PROFILE: 'update_profile',
  GET_PROFILE: 'get_profile',
  CREATE_PRODUCTION: 'create_production',
  UPDATE_PRODUCTION: 'update_production',
  GET_PRODUCTION: 'get_production',
  CASTING_TOOL: 'casting_tool',
  CREATE_CREW_PROFILE: 'create_crew_profile',
};

export const VIDEO_ATTRIBUTE_TYPES_IDS = {
  START_DATE: 33,
  START_TIME: 34,
  END_DATE: 35,
  END_TIME: 36,
  TIMEZONE: 37,
  COST_TYPE: 41,
};

export const PRODUCTION_RENTALS_AGGREGATION_TYPES = {
  WORK_ID: 'work_id',
  PRODUCTION_TEAM: 'individual_id',
  COMPANY: 'organization_id',
  SORT: 'sort',
};

export const PRIVACY_ATTRIBUTE_IDS = {
  PRIVATE: 1,
  PUBLIC: 2,
  PRO: 3,
  FREE_AND_PRO: 4,
  CASTING: 5,
  ARTISTS: 6,
  AGENCY: 7,
  AOS: 8,
};

export const contactsIdentifiers = {
  phone: 0,
  email: 1,
  sns: 2,
  website: 3,
  address: 4,
  other: 5,
};

export const DIGITAL_CONTACTS = [contactsIdentifiers.sns, contactsIdentifiers.website, contactsIdentifiers.other];

export const REVIEW_ENITITES_TYPES = {
  WORKS: { VALUE: 1, NAME: 'works' },
  COMPOSER: { VALUE: 2, NAME: 'workComposers' },
  PRODUCTIONS: { VALUE: 3, NAME: 'productions' },
  PRODUCTION_DATE: { VALUE: 4, NAME: 'productionDates' },
  PRODUCTION_CAST: { VALUE: 5, NAME: 'productionCasts' },
  PROFILE: { VALUE: 6, NAME: 'profiles' },
  WORK_ROLE: { VALUE: 7, NAME: 'workRoles' },
  VENUE: { VALUE: 8, NAME: 'venues' },
  COMPANIES: { VALUE: 9, NAME: 'companies' },
  FESTIVALS: { VALUE: 10, NAME: 'festivals' },
  PRODUCTION_COMPANIES: { VALUE: 11, NAME: 'productionCompanies' },
  PRODUCTION_FESTIVALS: { VALUE: 12, NAME: 'productionFestivals' },
};

export const REPERTOIRE_SOURCE = {
  USER: 'user',
  PERFORMANCE: 'performance',
};

export const repertoireStatuses = {
  RETIRED: 'retire',
  WISHLIST: 'future',
  PAST: 'past', // active records. It is performances status actually
  PREPARED: 'prepared',
  UNPREPARED: 'unprepared',
};

export const futureRepertoireStatuses = [
  repertoireStatuses.WISHLIST,
  repertoireStatuses.PREPARED,
  repertoireStatuses.UNPREPARED,
];

export const SINGER_PROFESSION_TYPE_ID = 1;
export const CHOIR_PROFESSION_TYPE_ID = 24;
export const INSTRUMENTALIST_PROFESSION_TYPE_ID = 3;
export const ORCHESTRA_PROFESSION_TYPE_ID = 23;
export const PROFESSION_IDS = {
  COMPOSER: 5,
  LIBRETTIST: 16,
};
