import {
  ENTITY_TYPE,
  URL_SLUG_PREFIXES,
  BASE_PAGE_ROUTES,
  ENTITY_TYPE_ROUTE_MAP,
  ENTITY_MAIN_TABS,
  VALID_QUERY_PARAMS,
} from 'constants/index';
import { getLanguage } from 'utils/queriesUtil';
import { getFilterURLParams } from './filters';

// TODO: Remove in phase 3
const isV4Page = () => {
  if (typeof window !== 'undefined') {
    return window.location.pathname.startsWith('/v4');
  }

  return false;
};

// TODO: Remove in phase 3
const ORG_SUFFIX_MAP = {
  about: ENTITY_MAIN_TABS.ABOUT,
  contact: ENTITY_MAIN_TABS.CONTACT,
  images: ENTITY_MAIN_TABS.IMAGES,
  performances: ENTITY_MAIN_TABS.PERFORMANCES,
  reviews: ENTITY_MAIN_TABS.REVIEWS,
  venues: ENTITY_MAIN_TABS.VENUES,
  videos: ENTITY_MAIN_TABS.VIDEOS,
  'modify-production': 'modify-productions',
  revivals: 'revivals',
  'search-productions': 'search-productions',
};

export const getURLWithLanguage = (url, language) => {
  if (!language) {
    return url;
  }

  if (url?.length > 0) {
    const [path, query] = url?.split('?');
    const lastPathPart = path?.split('/')?.slice(-1)?.[0];

    if (lastPathPart?.length === 2 || lastPathPart === 'keys') {
      if (lastPathPart === language) {
        return url;
      }

      const replaceLength = lastPathPart === 'keys' ? -4 : -2;

      return `${path.slice(0, replaceLength)}${language}${query ? `?${query}` : ''}`;
    }

    const pathWithLang = `${path}${path.slice(-1) === '/' ? language : `/${language}`}`;

    if (query?.length > 0) {
      return `${pathWithLang}?${query}`;
    }

    return pathWithLang;
  }

  return url;
};

const getLinkProps = props => {
  const ifV4Page = isV4Page() && !props?.edit; // TODO: Remove in phase 3
  const {
    baseRoute,
    entityType,
    entity,
    country,
    path: pathValue,
    suffix: deprecatedPath,
    queryParams: initialQueryParams = {},
    filters = {},
    language: requestedLanguage,
    shallow = true,
    edit = false,
    pro = false,
    onlyLinkProps = false,
    rel,
    title,
    scrollTo,
  } = props || {};
  let suffix = pathValue || deprecatedPath || '';

  if (suffix.startsWith('/')) {
    suffix = suffix.slice(1);
  }

  const baseURL = process?.env?.FRONTBASE_URL || '';
  const language = requestedLanguage || getLanguage(requestedLanguage);

  const getBaseRoute = base => {
    let rootPage = '';

    if (base === BASE_PAGE_ROUTES.PRO) {
      return `${base}/[type]`;
    }

    if (
      entity &&
      entityType &&
      ![ENTITY_TYPE.ORGANIZATION, ENTITY_TYPE.ARTIST].includes(entityType) &&
      !pro &&
      baseRoute !== BASE_PAGE_ROUTES.CASTING
    ) {
      rootPage = ifV4Page ? '[entitySlug]' : '[id]';
    }

    if (!ifV4Page && [ENTITY_TYPE.VENUE].includes(entityType)) {
      if (country || entity) {
        return `${base}/[country]${rootPage ? `/${rootPage}` : ''}`;
      }

      return base;
    }

    return `${base}${rootPage ? `/${rootPage}` : ''}`;
  };

  let mainRoute = BASE_PAGE_ROUTES.HOME;

  if (baseRoute) {
    mainRoute = baseRoute;
  } else if (ifV4Page && props?.entity) {
    mainRoute = BASE_PAGE_ROUTES.V4;
  } else if (entityType === ENTITY_TYPE.ARTIST && !entity) {
    mainRoute = BASE_PAGE_ROUTES.ARTISTS;
  } else if (entityType && ENTITY_TYPE_ROUTE_MAP[entityType] && ((ifV4Page && !entity) || !ifV4Page)) {
    mainRoute = ENTITY_TYPE_ROUTE_MAP[entityType];
  }

  let baseHref = getBaseRoute(mainRoute);
  let asPath = mainRoute;

  if (entityType && (entity || country)) {
    const shouldAddPrefix =
      ifV4Page ||
      pro ||
      baseRoute === BASE_PAGE_ROUTES.CASTING ||
      ([ENTITY_TYPE.ORGANIZATION, ENTITY_TYPE.ARTIST].includes(entityType) && entity);
    const prefix = shouldAddPrefix ? URL_SLUG_PREFIXES.find(item => item.entityType === entityType)?.prefix : '';
    let countryPart = '';

    if (
      !ifV4Page &&
      [ENTITY_TYPE.COMPANY, ENTITY_TYPE.FESTIVAL, ENTITY_TYPE.VENUE].includes(entityType) &&
      (entity?.country || country)
    ) {
      countryPart = `/${entity?.country?.slug || country?.slug || ''}`;
    }

    asPath = `${mainRoute === '/' ? '' : mainRoute}${countryPart}${entity?.id ? '/' : ''}${
      entity?.slug ? `${entity?.slug}-` : ''
    }${prefix}${entity?.id || ''}`;
  }

  if (edit) {
    if ([ENTITY_TYPE.ORGANIZATION, ENTITY_TYPE.ARTIST].includes(entityType)) {
      asPath = `${asPath}/edit`;
    } else if ([ENTITY_TYPE.COMPANY, ENTITY_TYPE.FESTIVAL].includes(entityType)) {
      asPath = `${asPath}/edit`;
      baseHref = `${baseHref}/edit`;
    }
  }

  const appendSuffix = ({ as: asValue, href: hrefValue, suffix: path = '', isSubPath = false }) => {
    const loweredPath = path ? path?.toLowerCase() : '';
    const newAs = `${asValue}${loweredPath ? `${asValue === '/' ? '' : '/'}${loweredPath}` : ''}`;
    let newHref = hrefValue;

    const shouldSkipAppendSuffix =
      (/.+\[\[\.\.\.\w+\]\]/g.test(newHref) || /.+\[\w+\]/g.test(newHref)) &&
      ![ENTITY_TYPE.WORK, ENTITY_TYPE.VENUE, ENTITY_TYPE.PRODUCTION].includes(entityType);

    if (!shouldSkipAppendSuffix) {
      if (edit) {
        if ([ENTITY_TYPE.ORGANIZATION, ENTITY_TYPE.ARTIST].includes(entityType)) {
          newHref = `${newHref}${newHref.endsWith('/') ? '' : '/'}[[...action]]`;
        } else if ([ENTITY_TYPE.COMPANY, ENTITY_TYPE.FESTIVAL].includes(entityType)) {
          const page = ORG_SUFFIX_MAP[loweredPath] || loweredPath;
          newHref = `${newHref}${page ? `/${page}` : ''}`;
        } else if (entityType === ENTITY_TYPE.ARTIST && loweredPath) {
          newHref = `${newHref}${newHref === '/' ? '' : '/'}${loweredPath}`;
        }
      } else if (entityType && entity) {
        if (!ifV4Page && entityType === ENTITY_TYPE.FESTIVAL) {
          const page = ORG_SUFFIX_MAP[loweredPath] || loweredPath;
          newHref = `${newHref}${page ? `/${page}` : ''}`;
        } else if (!isSubPath) {
          newHref = `${newHref}${newHref.endsWith('/') ? '' : '/'}[[...action]]`;
        }
      } else if (
        [
          BASE_PAGE_ROUTES.ARTISTS,
          BASE_PAGE_ROUTES.CASTING,
          BASE_PAGE_ROUTES.SEASONS,
          BASE_PAGE_ROUTES.COMPANIES,
          BASE_PAGE_ROUTES.FESTIVALS,
        ].includes(newHref) ||
        BASE_PAGE_ROUTES.HOME === newHref
      ) {
        newHref = `${newHref}${newHref.endsWith('/') ? '' : '/'}[[...action]]`;
      } else if (loweredPath) {
        newHref = `${newHref}${newHref.endsWith('/') ? '' : '/'}${loweredPath}`;
      }
    }

    return {
      as: newAs,
      href: newHref,
    };
  };

  const { as, href } = appendSuffix({ as: asPath, href: baseHref, suffix });

  const getSubPath = ({
    title: argTitle,
    path: argPath,
    queryParams: argQueryParams,
    filters: argFilters,
    onlyLinkProps: onlySubPathLinkProps,
    mergeQueryParams = false,
    scrollTo: scrollToValue,
  }) => {
    const { path, queryParams: params } = getFilterURLParams({
      filters: argFilters,
      queryParams: {
        ...(mergeQueryParams && (initialQueryParams || {})),
        ...(argQueryParams || {}),
        ...(scrollToValue && { [VALID_QUERY_PARAMS.SCROLL_TO]: scrollToValue }),
      },
      path: argPath || '',
    });

    let { as: asSubPath, href: hrefSubPath } = appendSuffix({ as, href, suffix: path, isSubPath: true });

    let url = asSubPath;

    if (language) {
      url = getURLWithLanguage(`${baseURL}${asSubPath}`, language);
    }

    const searchParams = new URLSearchParams(params || {});

    if (params) {
      searchParams.sort();
      const queryString = decodeURIComponent(searchParams.toString());

      if (queryString) {
        hrefSubPath = `${hrefSubPath}?${queryString}`;
        asSubPath = `${asSubPath}?${queryString}`;
        url = `${url}?${queryString}`;
      }
    }

    return {
      as: asSubPath,
      href: hrefSubPath,
      shallow,
      rel,
      title: argTitle || title,
      ...(!onlySubPathLinkProps && {
        searchParams,
        url,
        getSubPath,
      }),
    };
  };

  return getSubPath({ queryParams: initialQueryParams, filters, onlyLinkProps, scrollTo });
};

export default getLinkProps;
