import { all, call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import { MEDIA_TAGS_TYPES_IDS, MEDIA_TYPES_IDS, PRIVACY_ATTRIBUTE_IDS } from 'constants/deprecate';
import { uploadVenueImagesAction } from './actions';
// eslint-disable-next-line import/named
import { create } from '../../utils/API';
import { enqueueSnackbarAction } from '../App/actions';
import endpointConstants from '../../constants/endpointConstants';

export function* uploadVenueImagesSaga({ payload, serverCookies }) {
  const { images, venueId, callback } = payload;
  try {
    const createImageData = image => ({
      file: { data: `@${image?.filename}` },
      mediaType: {
        id: MEDIA_TYPES_IDS.IMAGE,
      },
      privacyAttribute: {
        id: PRIVACY_ATTRIBUTE_IDS.PUBLIC,
      },
      tags: [
        {
          tagType: { id: MEDIA_TAGS_TYPES_IDS.VENUE },
          tagId: venueId,
        },
      ],
    });
    const responses = yield all(
      images.map(image => {
        const imageData = createImageData(image);
        return call(create, endpointConstants.MEDIA, imageData, { [image?.filename]: image?.file }, serverCookies);
      }),
    );
    const data = responses.map(response => get(response, 'data'));
    yield put(uploadVenueImagesAction.success(data));
    if (typeof callback === 'function') {
      callback(data);
    }
  } catch (e) {
    yield put(
      enqueueSnackbarAction({
        message: e.message,
        options: {
          variant: 'error',
        },
      }),
    );
    yield put(uploadVenueImagesAction.failure(e.message));
  }
}

export default function* venueModalSaga() {
  yield takeLatest(uploadVenueImagesAction.TRIGGER, uploadVenueImagesSaga);
}
