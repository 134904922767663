import { TP } from 'constants/index';
import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import get from 'lodash/get';
import { getRentals as rentalsList, getRentalsAggregations as rentalsAggregationsList } from '../../utils/API';
import { getRentals, getRentalsAggregations } from './actions';
import { formatResponseToList } from '../../utils/crudUtils';
import { enqueueSnackbarAction } from '../App/actions';
import { i18n } from '../../src/i18n';
import { PRODUCTION_RENTALS_AGGREGATION_TYPES } from '../../constants/consts';

export function* getRentalsRequest({ payload, serverCookies }) {
  try {
    const response = yield call(rentalsList, { ...payload }, serverCookies);
    const list = formatResponseToList(response);
    yield put(getRentals.success(list));
  } catch (err) {
    yield put(getRentals.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_DATA`, { error: err.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getRentals.fulfill());
  }
}

export function* getRentalsAggregationsRequest({ payload, serverCookies }) {
  const updatePayload = {
    ...payload,
    has_active_rental: true,
    ...(payload?.type === PRODUCTION_RENTALS_AGGREGATION_TYPES.PRODUCTION_TEAM && { contribution_type: 'crew' }),
  };
  try {
    const response = yield call(rentalsAggregationsList, updatePayload, serverCookies);
    if (payload?.callback) {
      payload.callback(get(response, 'data.data'));
    }
    yield put(getRentalsAggregations.success(get(response, 'data')));
  } catch (err) {
    yield put(getRentalsAggregations.failure({ type: payload.type, message: err.message }));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_DATA`, { endpoint: payload.type, error: err.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getRentalsAggregations.fulfill());
  }
}

export default function* rentalsSaga() {
  yield takeLatest(getRentals.TRIGGER, getRentalsRequest);
  yield takeEvery(getRentalsAggregations.TRIGGER, getRentalsAggregationsRequest);
}
