import { createRoutine } from 'redux-saga-routines';
import {
  GET_ORG_SEASONS_VALUES,
  GET_COMPANY_UNPUBLISHED_SEASON,
  ADD_AND_UPDATE_CONTACTS,
  UPDATE_ORG,
  SET_COMPANY,
  GET_MUSICAL_WORKS_LIST,
  GET_COMPANY_EDIT_SEASON,
  GET_COMPANY_IMAGES,
  GET_COMPANY_LS_VOD,
  GET_COMPANY_VIDEOS,
  SET_SEASONS,
  CLEAR_EDIT_SEASONS,
  GET_COMPANY_STAFF,
  ADD_COMPANY_VIDEO,
  GET_COMPANY_PRODUCTIONS,
  GET_COMPANY_EMPLOYEES,
  GET_COMPANY_BANNER_IMAGES,
  GET_COMPANY_PRODUCTION_VIDEOS,
  GET_COMPANY_PRODCUTION_IMAGES,
  GET_COMPANY_SEARCH_PRODUCTIONS_LIST,
  GET_ORG_TRANSLATIONS,
  UPDATE_ORG_TRANSLATIONS,
  DELETE_ORG_TRANSLATIONS,
  SET_ORG_PRODUCTION_RENTAL_ACTION,
  UPDATE_RENTAL_INCLUDES_ACTION,
  GET_COMPANY_REVIEWS,
  GET_COMPANY_PRODUCTION_REVIEWS,
  GET_ORG_DESCRIPTIONS,
  CREATE_ORG_DESCRIPTION,
  UPDATE_ORG_DESCRIPTION,
  DELETE_ORG_DESCRIPTION,
  UPDATE_ORG_INFO,
  UPDATE_ORG_VENUE,
  UPDATE_ORG_VIDEO,
  GET_ORG_VENUES,
  GET_ORG_RELATIONS,
  DELETE_ORG_RELATIONS,
  CREATE_ORG_RELATIONS,
  GET_ORG_CONTACT,
  GET_ABOUT_ORG_VIDEO,
  GET_ORG_ADDRESSES,
  GET_ORG_DATA,
  DELETE_ORG_VIDEO,
} from './constants';

export const getCompanyEditSeasonAction = createRoutine(GET_COMPANY_EDIT_SEASON);
export const getCompanyUnpublishedSeasonAction = createRoutine(GET_COMPANY_UNPUBLISHED_SEASON);
export const addAndUpdateContactsAction = createRoutine(ADD_AND_UPDATE_CONTACTS);
export const getMusicalWorksListAction = createRoutine(GET_MUSICAL_WORKS_LIST);
export const getCompanyImagesAction = createRoutine(GET_COMPANY_IMAGES);
export const getCompanyProductionImagesAction = createRoutine(GET_COMPANY_PRODCUTION_IMAGES);
export const getCompanyBannerImagesAction = createRoutine(GET_COMPANY_BANNER_IMAGES);
export const getCompanyVideosAction = createRoutine(GET_COMPANY_VIDEOS);
export const getCompanyProductionVideosAction = createRoutine(GET_COMPANY_PRODUCTION_VIDEOS);
export const getCompanyLsVodAction = createRoutine(GET_COMPANY_LS_VOD);
export const addCompanyVideoAction = createRoutine(ADD_COMPANY_VIDEO);
export const getCompanyStaffAction = createRoutine(GET_COMPANY_STAFF);
export const getCompanyProductionsAction = createRoutine(GET_COMPANY_PRODUCTIONS);
export const getCompanyEmployeesAction = createRoutine(GET_COMPANY_EMPLOYEES);
export const getCompanySearchProductionsAction = createRoutine(GET_COMPANY_SEARCH_PRODUCTIONS_LIST);
export const updateRentalIncludesAction = createRoutine(UPDATE_RENTAL_INCLUDES_ACTION);
export const getCompanyReviewsAction = createRoutine(GET_COMPANY_REVIEWS);
export const getCompanyProductionReviewsAction = createRoutine(GET_COMPANY_PRODUCTION_REVIEWS);

export const updateOrgAction = createRoutine(UPDATE_ORG);
export const getOrgTranslationsAction = createRoutine(GET_ORG_TRANSLATIONS);
export const updateOrgTranslationsAction = createRoutine(UPDATE_ORG_TRANSLATIONS);
export const deleteOrgTranslationsAction = createRoutine(DELETE_ORG_TRANSLATIONS);
export const getOrgDescriptionsAction = createRoutine(GET_ORG_DESCRIPTIONS);
export const createOrgDescriptionAction = createRoutine(CREATE_ORG_DESCRIPTION);
export const updateOrgDescriptionAction = createRoutine(UPDATE_ORG_DESCRIPTION);
export const deleteOrgDescriptionAction = createRoutine(DELETE_ORG_DESCRIPTION);
export const updateOrgInfoAction = createRoutine(UPDATE_ORG_INFO);
export const updateOrgVenueAction = createRoutine(UPDATE_ORG_VENUE);
export const updateOrgVideoAction = createRoutine(UPDATE_ORG_VIDEO);
export const getOrgVenuesAction = createRoutine(GET_ORG_VENUES);
export const getOrgRelationAction = createRoutine(GET_ORG_RELATIONS);
export const deleteOrgRelationAction = createRoutine(DELETE_ORG_RELATIONS);
export const createOrgRelationAction = createRoutine(CREATE_ORG_RELATIONS);
export const getOrgContactAction = createRoutine(GET_ORG_CONTACT);
export const getAboutOrgVideoAction = createRoutine(GET_ABOUT_ORG_VIDEO);
export const getOrgAddressesAction = createRoutine(GET_ORG_ADDRESSES);
export const getOrgSeasonsValueAction = createRoutine(GET_ORG_SEASONS_VALUES);
export const setOrgProductionRentalAction = createRoutine(SET_ORG_PRODUCTION_RENTAL_ACTION);

export const getOrgDataAction = createRoutine(GET_ORG_DATA);

export const setCompany = company => ({
  type: SET_COMPANY,
  payload: company,
});

export function setCompanySeasons(seasons) {
  return {
    type: SET_SEASONS,
    payload: { seasons },
  };
}

export function clearOrgEditSeason() {
  return {
    type: CLEAR_EDIT_SEASONS,
    payload: [],
  };
}

export function deleteOrgVideoAction(id) {
  return {
    type: DELETE_ORG_VIDEO,
    payload: { id },
  };
}
