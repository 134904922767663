import React, { useMemo, createContext, useContext, useState, useEffect } from 'react';

import { i18n, defaultLanguage } from 'src/i18n';

const AppContext = createContext({});

export const AppContextProvider = ({ children, sessionUser, locale, isTrustedBot }) => {
  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const [storeUserId, setLoggedInUserId] = useState(sessionUser?.id);
  const [paywallType, setPaywallType] = useState('');
  const language = useMemo(() => {
    const serverLanguage = locale || defaultLanguage;

    if (typeof window === 'undefined') {
      return serverLanguage?.toLowerCase();
    }

    return (i18n?.language || serverLanguage)?.toLowerCase();
  }, [locale]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScrollBarWidth(window.innerWidth - document.body.clientWidth);
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        isTrustedBot,
        userId: storeUserId,
        isLoggedIn: !!storeUserId,
        setLoggedInUserId,
        language,
        paywallType,
        setPaywallType,
        scrollBarWidth,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export default useAppContext;
