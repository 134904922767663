import { captureException as SentryCaptureException, captureMessage as SentryCaptureMessage } from '@sentry/nextjs';
import { ENTITY_TYPE } from 'constants/index';

const TRANSACTION_PAGE_MAP = {
  [ENTITY_TYPE.ARTIST]: 'Artist',
  [ENTITY_TYPE.ORGANIZATION]: 'Organization',
  [ENTITY_TYPE.PRODUCTION]: 'Production',
  ERROR: '404 Error',
  HOME: 'Homepage',
};

// NOTE: Generate custom sentry transaction name
export const getSentryTransactionName = ({ entityType, entityId, mainPath, subPath, edit, paths, queryParams }) => {
  const transactionParts = [];

  if (entityType) {
    transactionParts.push(TRANSACTION_PAGE_MAP[entityType]);

    if (entityId) {
      transactionParts.push('Stub Page');
    } else {
      transactionParts.push('Index Page');

      if (entityType === ENTITY_TYPE.ARTIST && paths?.length === 1) {
        transactionParts.push(`(${paths?.[0]})`);
      } else if (queryParams?.query || queryParams?.letter) {
        transactionParts.push('(SEARCH)');
      }
    }

    if (edit) {
      transactionParts.push('(EDIT)');
    }

    const tabs = [];

    if (mainPath) {
      tabs.push(mainPath);
    }

    if (subPath) {
      tabs.push(subPath);
    }

    if (mainPath || subPath) {
      transactionParts.push(`(${tabs.join('/')})`);
    }
  } else if (paths?.length > 0) {
    transactionParts.push(TRANSACTION_PAGE_MAP.ERROR);
  } else {
    transactionParts.push(TRANSACTION_PAGE_MAP.HOME);
  }

  return transactionParts.join(' ');
};

const transformSentryContext = context => ({
  ...(context || {}),
  extra: {
    ...(context?.extra || {}),
    buildNumber: process.env.BUILD_NUMBER,
    ssr: typeof window === 'undefined',
  },
});

export const sentryCaptureException = (error, context) => {
  const transformedContext = transformSentryContext(context);

  SentryCaptureException(error, transformedContext);
};

export const sentryCaptureMessage = (message, context = {}) => {
  const transformedContext = transformSentryContext(context);

  SentryCaptureMessage(message, transformedContext);
};
