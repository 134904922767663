import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { getRental } from './actions';
import { getRental as getRentalRequest } from '../../utils/API';

export function* fetchRentalData({ payload, serverCookies }) {
  try {
    const response = yield call(getRentalRequest, payload.rentalId, {}, serverCookies);
    yield put(getRental.success(get(response, 'data')));
  } catch (err) {
    yield put(getRental.failure(err.message));
  } finally {
    yield put(getRental.fulfill());
  }
}

export default function* rentalSaga() {
  yield takeLatest(getRental.TRIGGER, fetchRentalData);
}
