import { useMutation } from 'utils/react-query';
import { getRequest, create, getList, getHomeNumbers, getOne } from 'utils/API';
import { getLanguage } from 'utils/queriesUtil';
import {
  BIO_SUMMARY,
  MEDIA_SUGGESTIONS,
  PROFILES_ENDPOINT,
  PERFORMANCES,
  MUSICAL_WORK_ENDPOINT,
  COMPANIES_AGGREGATIONS,
  VENUES_AGGREGATIONS,
  PRODUCTIONS_AGGREGATIONS,
  COMPANIES,
  VENUES,
  FESTIVALS,
  PRODUCTIONS,
  ENTITY_RECOMMENDATIONS,
  COMPETITIONS_AGGREGATIONS,
  COUNTRIES_ENDPOINT,
  CITY_ENDPOINT,
  FILTER_AGGREGATION_SEARCH,
  AGENCIES,
  PAGERULES_ENDPOINT,
  PROFILES_VIEWED,
  PROFESSIONS,
} from 'constants/endpointConstants';
import {
  ENTITY_TYPE,
  ENTITY_API_PATH,
  AGGREGATION_TYPES,
  LOCATION_TYPE,
  PAGERULE_ENTITY_TYPE_MAP,
  MEDIA_TAGS_TYPES_IDS,
  PRODUCTION_VALIDITY_IDENTIFIERS,
} from 'constants/index';

import { FOOTPRINT_TRACKING_ENDPOINT } from 'constants/footPrintTracking';
import { getOption } from 'utils/globals/app';
import { getQuery, postQuery, transformQueryFn } from 'utils/globals/queries';
import { PARENT_PROFESSION_SLUG, PROFESSIONS_AVAILABILITY } from 'constants/consts';

const ENDPOINTS = {
  STATIC: {
    SCRAPE_REQUESTS: 'scrape_requests',
    TESTIMONIALS: 'users/testimonials',
    APP_VERSION_STATUS: 'app-version/status',
  },
  DYNAMIC: {},
};

export const useFootPrintTrackingMutation = (options = {}) =>
  useMutation(
    payload => create(FOOTPRINT_TRACKING_ENDPOINT.postFingerprint, payload),
    {
      onSuccess: () => {
        if (typeof options?.onSettled === 'function') {
          const { onSettled } = options;
          onSettled();
        }
      },
    },
    options,
  );

const defaultConfig = {
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 10 * 60 * 1000,
};

const fetchBioSummary = ({ entityType, entityId }, cookies) => {
  const entityValue = ENTITY_API_PATH[entityType];
  if (entityValue) {
    return getList(BIO_SUMMARY(entityValue, entityId), {}, cookies).then(response => {
      if (response?.total) {
        const modifiedData = response?.data?.data?.reduce((acc, item) => {
          const description = item?.content || item?.description;
          acc.push({
            ...item,
            excerpt: description.substring(0, 800),
          });
          return acc;
        }, []);

        return {
          ...response?.data,
          data: modifiedData,
        };
      }
      return response?.data;
    });
  }
  return null;
};

const fetchMediaSuggestions = ({ entityType, entityId, isMobileDevice }, cookies) => {
  const extraParams = {
    [ENTITY_TYPE.ARTIST]: {
      entity_type: MEDIA_TAGS_TYPES_IDS.PROFILE,
    },
    [ENTITY_TYPE.ORGANIZATION]: {
      entity_type: MEDIA_TAGS_TYPES_IDS.ORGANIZATION,
    },
    [ENTITY_TYPE.PRODUCTION]: {
      entity_type: MEDIA_TAGS_TYPES_IDS.PRODUCTION,
    },
    [ENTITY_TYPE.VENUE]: {
      entity_type: MEDIA_TAGS_TYPES_IDS.VENUE,
    },
  };

  return getList(
    MEDIA_SUGGESTIONS,
    {
      queryParams: {
        entity_id: entityId,
        ...(extraParams[entityType] || {}),
      },
      pagination: { limit: 5 },
    },
    cookies,
  ).then(response => {
    const data = response?.data;

    if (isMobileDevice) {
      const videos = data?.data?.filter(({ mediaType }) => mediaType === 'video');

      return {
        ...data,
        data: videos?.length > 0 ? videos : data?.data,
      };
    }

    return data;
  });
};

const fetchEntityListing = ({ entityType, queryFilters }, cookies) => {
  const filters = queryFilters || { tier: 'A', sort: 'random' };
  let endpoint = '';
  let queryParams = {};
  switch (entityType) {
    case ENTITY_TYPE.ARTIST:
    case ENTITY_TYPE.COMPOSER:
      endpoint = PROFILES_ENDPOINT;
      queryParams = {
        ...filters,
      };
      break;
    case ENTITY_TYPE.PERFORMANCE:
      endpoint = PERFORMANCES;
      queryParams = {
        ...filters,
      };
      break;
    case ENTITY_TYPE.PRODUCTION:
      endpoint = PRODUCTIONS;
      queryParams = {
        ...filters,
      };
      break;
    case ENTITY_TYPE.WORK:
      endpoint = MUSICAL_WORK_ENDPOINT;
      break;
    case ENTITY_TYPE.COMPANY:
      endpoint = COMPANIES;
      queryParams = {
        ...{ sort: 'city', has_productions: 'yes' },
        ...filters,
      };
      break;
    case ENTITY_TYPE.FESTIVAL:
      endpoint = FESTIVALS;
      queryParams = {
        ...{ sort: 'city', has_productions: 'yes', approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG },
        ...filters,
      };
      break;
    case ENTITY_TYPE.VENUE:
      endpoint = VENUES;
      queryParams = {
        ...{ sort: 'city', has_productions: 'yes' },
        ...filters,
      };
      break;
    default:
  }
  return getList(endpoint, { queryParams, pagination: { limit: 100 } }, cookies).then(response => response.data);
};

const fetchEntityLocation = ({ type, filters }, cookies) => {
  const endpoint = type === LOCATION_TYPE.COUNTRY ? COUNTRIES_ENDPOINT : CITY_ENDPOINT;

  return getList(endpoint, { queryParams: filters, pagination: { limit: 1 } }, cookies).then(
    response => response.data?.data?.[0],
  );
};

const fetchAggregatedLocationListing = ({ entityType, aggregationType, queryFilters = {}, limit = 10 }, cookies) => {
  let endpoint = '';
  let queryParams = {
    aggregation_type: aggregationType,
    approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
    sort: 'name',
    has_productions: 'yes',
    ...(queryFilters?.country_id && { country_id: queryFilters?.country_id }),
    ...(queryFilters?.aggregation_query && { aggregation_query: queryFilters?.aggregation_query }),
    ...(aggregationType === AGGREGATION_TYPES.CITY && {
      city_id: queryFilters?.city_id,
    }),
  };

  switch (entityType) {
    case ENTITY_TYPE.COMPANY:
      endpoint = COMPANIES_AGGREGATIONS;
      break;
    case ENTITY_TYPE.FESTIVAL:
      endpoint = PRODUCTIONS_AGGREGATIONS;
      queryParams = {
        ...queryParams,
        has_festival: 'true',
      };
      break;
    case ENTITY_TYPE.VENUE:
      endpoint = VENUES_AGGREGATIONS;
      break;
    case ENTITY_TYPE.PRODUCTION:
      endpoint = PRODUCTIONS_AGGREGATIONS;
      break;
    case ENTITY_TYPE.COMPETITION:
      endpoint = COMPETITIONS_AGGREGATIONS;
      break;
    case ENTITY_TYPE.MANAGER:
      endpoint = FILTER_AGGREGATION_SEARCH(AGENCIES);
      break;
    default:
  }
  return getList(endpoint, { queryParams, pagination: { limit } }, cookies).then(response => response.data);
};

const fetchEntityRecommendations = ({ entityType, entityId, limit = 10 }, cookies) => {
  const entityValue = ENTITY_API_PATH[entityType];

  return getList(ENTITY_RECOMMENDATIONS(entityValue, entityId), { pagination: { limit } }, cookies).then(
    response => response.data,
  );
};

const fetchProfilesViewed = ({ limit = 10 }, cookies) =>
  getList(PROFILES_VIEWED, { pagination: { limit } }, cookies).then(response => response.data);

const fetchScrapeRequestStatus = ({ id }, cookies) =>
  getOne(ENDPOINTS.STATIC.SCRAPE_REQUESTS, id, null, cookies).then(response => response.data);

export const getRedirectPageRoute = ({ entityId, entityType, url, language }, cookies) => {
  const targetEntityType = PAGERULE_ENTITY_TYPE_MAP[entityType];
  const targetEntityId = entityId;
  const enabled = (targetEntityType && targetEntityId) || url;

  const queryParams =
    targetEntityType && targetEntityId
      ? { target_entity_type: targetEntityType, target_entity_id: targetEntityId }
      : { url };

  if (enabled) {
    return getList(PAGERULES_ENDPOINT, { queryParams }, cookies).then(response => {
      const newTarget = response?.data?.data?.[0]?.target;

      if (newTarget) {
        let lng = '';
        if (!/.+\/[a-zA-Z]{2,3}$/.test(newTarget)) {
          lng = `/${language || 'en'}`;
        }

        return `${newTarget}${lng}`;
      }

      return null;
    });
  }

  return null;
};

const getUserByFingerprint = ({ id }, cookies) => {
  const queryParams = {
    visitor_id: id,
  };
  return getList(FOOTPRINT_TRACKING_ENDPOINT.getUserByFingerprint, { queryParams }, cookies).then(
    response => response.data,
  );
};

const fetchTestimonials = ({ limit = 20 }, cookies) => {
  const queryParams = {
    limit,
    is_active: true,
    tag_name: 'Casting tool AOS',
  };

  return getList(ENDPOINTS.STATIC.TESTIMONIALS, { queryParams }, cookies).then(response => response.data);
};

const fetchAppVersionStatus = async (_props, cookies) =>
  getRequest(ENDPOINTS.STATIC.APP_VERSION_STATUS, null, cookies).then(response => response?.data?.data);

const fetchInstrumentsProfessionList = ({ filters, limit = 10 }, cookies) => {
  const query = {
    queryParams: {
      ...filters,
      parent_profession: PARENT_PROFESSION_SLUG.INSTRUMENTALIST,
      availability: PROFESSIONS_AVAILABILITY.UPDATE_PROFILE,
    },
    pagination: { limit },
  };

  return getList(PROFESSIONS, query, cookies).then(
    response => response?.data?.data?.map(record => ({ ...record, ...getOption(record) })) || [],
  );
};

const fetchSingerProfessionList = ({ filters, limit = 10 }, cookies) => {
  const query = {
    queryParams: {
      ...filters,
      parent_profession: PARENT_PROFESSION_SLUG.SINGER,
      availability: PROFESSIONS_AVAILABILITY.UPDATE_PROFILE,
    },
    pagination: { limit },
  };

  return getList(PROFESSIONS, query, cookies).then(
    response => response?.data?.data?.map(record => ({ ...record, ...getOption(record) })) || [],
  );
};

const fetchProfessionList = ({ filters, limit = 10 }, cookies) => {
  const query = {
    queryParams: {
      ...filters,
      availability: [PROFESSIONS_AVAILABILITY.UPDATE_PROFILE, PROFESSIONS_AVAILABILITY.CREATE_CREW_PROFILE],
    },
    pagination: { limit },
  };

  return getList(PROFESSIONS, query, cookies).then(
    response => response?.data?.data?.map(record => ({ ...record, ...getOption(record) })) || [],
  );
};

const queries = {
  getEntityBioDetails: getQuery('GET_ENTITY_BIO', fetchBioSummary, props => ({
    ...(props || {}),
    queryConfig: {
      ...(props.queryConfig || {}),
      enabled: props.queryConfig?.enabled && props?.entityType === ENTITY_TYPE.ARTIST,
    },
  })),
  getMediaSuggestions: ({ entityType, entityId, isMobileDevice = false, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_MEDIA_SUGGESTIONS', `${entityType}`, `${entityId}`],
    queryFn: async () => fetchMediaSuggestions({ entityType, entityId, isMobileDevice }, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
  getEntityListing: ({ entityType, queryFilters, countryId = '', queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_ENTITY_LISTING', `${entityType}`, `${countryId}`],
    queryFn: async () => fetchEntityListing({ entityType, queryFilters }, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
  getLocation: getQuery('GET_LOCATION', fetchEntityLocation, props => ({
    ...props,
    select: response =>
      getOption(response, 'id', 'name', { id: response?.id, slug: response?.slug, name: response?.name }),
  })),
  getAggregatedLocationListing: (
    { entityType, aggregationType = AGGREGATION_TYPES.COUNTRY, queryFilters = {}, queryConfig = {}, limit },
    cookies,
    language,
  ) => ({
    queryKey: [
      getLanguage(language),
      'GET_AGGREGATED_LOCATION_LISTING',
      `${entityType}`,
      `${aggregationType}`,
      `${limit}`,
      JSON.stringify(queryFilters),
    ],
    queryFn: async () => fetchAggregatedLocationListing({ entityType, aggregationType, queryFilters, limit }, cookies),
    select: response => ({
      ...response,
      data: response?.data?.map(option =>
        getOption(option, 'id', 'name', { id: option?.id, slug: option?.slug, name: option?.name }, true),
      ),
    }),
    ...defaultConfig,
    ...queryConfig,
  }),
  getEntityRecommendations: getQuery('GET_ENTITY_RECOMMENDATIONS', fetchEntityRecommendations),
  getProfilesViewed: getQuery('GET_PROFILES_VIEWED', fetchProfilesViewed),
  getHomeNumbers: getQuery('GET_HOME_NUMBERS', getHomeNumbers),
  createScrapeRequest: postQuery(ENDPOINTS.STATIC.SCRAPE_REQUESTS),
  getScrapeRequestStatus: getQuery('GET_SCRAPE_REQUEST_STATUS', fetchScrapeRequestStatus),
  getUserByVisitorId: getQuery('GET_USER_BY_FINGERPRINT', getUserByFingerprint),
  getTestimonials: getQuery(
    'GET_TESTIMONIALS',
    transformQueryFn(fetchTestimonials, response => ({
      ...response,
      data: [...(response?.data || [])]?.sort(() => 0.5 - Math.random()),
    })),
  ),
  checkAppVersionStatus: getQuery('GET_APP_VERSION_STATUS', fetchAppVersionStatus),
  getInstrumentsProfessionList: getQuery('GET_INSTRUMENTS_PROFESSIONS_LIST', fetchInstrumentsProfessionList),
  getSingerProfessionList: getQuery('GET_SINGER_PROFESSIONS_LIST', fetchSingerProfessionList),
  getProfessionList: getQuery('GET_PROFESSIONS_LIST', fetchProfessionList),
};

export default queries;
