export const GET_PRODUCTIONS = 'app/CastTool/GET_PRODUCTIONS';
export const CLEAR_PRODUCTIONS = 'app/CastTool/CLEAR_PRODUCTIONS';
export const GET_WORKS_LIST = 'app/CastTool/GET_WORKS_LIST';
export const GET_WORKS_ROLES_LIST = 'app/CastTool/GET_WORKS_ROLES_LIST';
export const CLEAN_WORKS_LIST = 'app/CastTool/CLEAN_WORKS_LIST';
export const CLEAN_WORKS_ROLES_LIST = 'app/CastTool/CLEAN_WORKS_ROLES_LIST';
export const GET_PRODUCTIONS_COUNTRIES = 'app/CastTool/GET_PRODUCTIONS_COUNTRIES';
export const SET_CAST_WORKS_AGGREGATIONS = 'app/CastTool/SET_CAST_WORKS_AGGREGATIONS';
export const GET_ROLES = 'app/CastTool/GET_ROLES';
export const GET_PROFESSION_ROLES = 'app/CastTool/GET_PROFESSION_ROLES';
export const GET_PROFESSION_ROLE_LIST = 'app/CastTool/GET_PROFESSION_ROLE_LIST';
