import { createSelector } from 'reselect';
import get from 'lodash/get';
import { getProfileName } from 'utils/artist';
import { createSelectFieldOptions, getCityWithAreasLabel } from 'utils/globals/app';
import { PARENT_PROFESSION_SLUG } from 'constants/consts';
import { ENTITY_TYPE } from 'constants/index';
import { initialState } from './reducer';
import { getProfileProfession } from '../Artist/utils';

const selectAppDomain = state => state.App || initialState;

const selectUserData = () => createSelector(selectAppDomain, subState => get(subState, 'userData'));

const selectUserProfiles = () => createSelector(selectAppDomain, subState => get(subState, 'profiles'));

const selectProSubscription = profileTypeId =>
  createSelector(selectAppDomain, subState => {
    const subscriptions = get(subState, 'subscriptions.data');
    return subscriptions.find(
      subscription => subscription.profileType?.id === Number(profileTypeId) && subscription.name !== 'free',
    );
  });

const selectArtistProfessionsOptions = () =>
  createSelector(selectAppDomain, subState => {
    const professions = [...get(subState, 'artistProfessions', [])];
    return createSelectFieldOptions(professions, 'id', 'name', { type: 'profession' }, true);
  });

const selectAllArtistProfessionsOptions = () =>
  createSelector(selectAppDomain, subState => {
    const professions = get(subState, 'artistProfessions', []);
    return createSelectFieldOptions(professions, 'id', 'name', { type: 'profession' });
  });

const selectAtistProfessions = () =>
  createSelector(selectAppDomain, subState => get(subState, 'artistProfessions', []));

const selectAggregationsValues = key =>
  createSelector(selectAppDomain, subState => get(subState, `aggregations.${key}`, []));

const selectAggregationsOptions = (key, valueName = 'key', labelName = 'name', additionalProps = true) =>
  createSelector(selectAppDomain, subState => {
    const aggregationsList = get(subState, `aggregations.${key}`, []);
    return createSelectFieldOptions(aggregationsList, valueName, labelName, { type: key }, additionalProps);
  });

const selectAggregationsLoading = () =>
  createSelector(selectAppDomain, subState => get(subState, 'aggregationTypeLoading'));

const selectAggregationsError = () => createSelector(selectAppDomain, subState => get(subState, 'aggregations.error'));

const selectStartAggregation = () => createSelector(selectAppDomain, subState => get(subState, 'startAggregations'));

const selectAgenciesListOptions = (withAllData = false) =>
  createSelector(selectAppDomain, subState => {
    const agencies = get(subState, 'agencies', []);
    return createSelectFieldOptions(agencies, 'id', 'name', {}, withAllData);
  });

const selectAgenciesListOptionsLoading = () =>
  createSelector(selectAppDomain, subState => get(subState, 'agenciesLoading'));

const selectCompaniesListOptions = (withAllData = false) =>
  createSelector(selectAppDomain, subState => {
    const companies = get(subState, 'companies', []);
    return createSelectFieldOptions(companies, 'id', 'name', {}, withAllData);
  });

const selectCompaniesListOptionsLoading = () =>
  createSelector(selectAppDomain, subState => get(subState, 'companiesLoading'));

const selectProfilesListOptions = () =>
  createSelector(selectAppDomain, subState => {
    const profiles = get(subState, 'profilesList', []);
    return profiles?.map(profile => {
      const profession = getProfileProfession(profile);

      return {
        ...profile,
        value: get(profile, 'id'),
        url: profile?.image?.small,
        label: `${getProfileName(profile)} ${profession ? `(${profession})` : ''}`,
      };
    });
  });

const selectProfilesListLoading = () =>
  createSelector(selectAppDomain, subState => get(subState, 'profilesListLoading'));

const selectResetPasswordMessage = () =>
  createSelector(selectAppDomain, subState => get(subState, 'resetPasswordInfo'));

const selectPasswordKeyStatus = () =>
  createSelector(selectAppDomain, subState => get(subState, 'isResetPasswordKeyActive'));

const selectPasswordUpdateStatus = () =>
  createSelector(selectAppDomain, subState => get(subState, 'isPasswordUpdated'));

const selectNotifications = () => createSelector(selectAppDomain, subState => get(subState, 'notifications', []));

const selectVoiceTypesOptions = () =>
  createSelector(selectAppDomain, subState =>
    createSelectFieldOptions(
      get(subState, 'voiceTypes.data', [])?.filter(
        voiceType => voiceType?.parentProfession?.slug === PARENT_PROFESSION_SLUG.SINGER,
      ),
      'id',
      'name',
      {},
      true,
    ),
  );

const selectInstrumentalistTypesOptions = () =>
  createSelector(selectAppDomain, subState =>
    createSelectFieldOptions(
      get(subState, 'voiceTypes.data', [])?.filter(
        voiceType => voiceType?.parentProfession?.slug === PARENT_PROFESSION_SLUG.INSTRUMENTALIST,
      ),
      'id',
      'name',
      {},
      true,
    ),
  );

const selectCitiesOptions = () =>
  createSelector(selectAppDomain, subState => ({
    options: createSelectFieldOptions(get(subState, 'cities.data', []), 'id', getCityWithAreasLabel),
    loading: get(subState, 'cities.loading', false),
  }));

const selectVenuesOptions = () =>
  createSelector(selectAppDomain, subState => ({
    loading: get(subState, 'venues.loading', false),
    options: createSelectFieldOptions(get(subState, 'venues.data', []), 'id', 'name', {}, true),
  }));

const selectVenuesSearchOptions = (withAllData = false) =>
  createSelector(selectAppDomain, subState => {
    const venueSearch = get(subState, 'venueSearch', []);
    return createSelectFieldOptions(venueSearch, 'id', 'name', { entityType: ENTITY_TYPE.VENUE }, withAllData);
  });

const selectStages = () =>
  createSelector(selectAppDomain, subState => ({
    loading: get(subState, 'stages.loading', false),
    data: get(subState, 'stages.data', []),
  }));

const selectBufferValueData = () =>
  createSelector(selectAppDomain, subState => ({
    loading: get(subState, 'bufferValue.loading', false),
    entity: get(subState, 'bufferValue.entity', []),
  }));

const selectWorkTypesLoading = () => createSelector(selectAppDomain, subState => get(subState, 'workTypes.loading'));

const selectWorkTypesOptions = () =>
  createSelector(selectAppDomain, subState =>
    createSelectFieldOptions(get(subState, 'workTypes.data', []), 'id', 'name'),
  );

const selectMultipleWorkTypesLoading = () =>
  createSelector(selectAppDomain, subState => get(subState, 'multipleWorkTypes.loading'));

const assignObjectPaths = (obj, stack) => {
  Object.keys(obj).forEach(k => {
    const node = obj[k];

    if (node?.name) {
      node.label = node?.name;
    }

    if (typeof node === 'object' && node !== null) {
      node.path = stack ? `${stack}.${k}` : k;
      assignObjectPaths(node, node.path);
    }
  });
};

const selectMultipleWorkTypesOptions = () =>
  createSelector(selectAppDomain, subState => {
    const treeData = createSelectFieldOptions(
      get(subState, 'multipleWorkTypes.data', []),
      'id',
      'name',
      undefined,
      true,
    );
    assignObjectPaths(treeData);
    return treeData;
  });

const selectWorkStylesOptions = () =>
  createSelector(selectAppDomain, subState =>
    createSelectFieldOptions(get(subState, 'workStyles.data', []), 'id', 'name'),
  );

const selectBanner = () => createSelector(selectAppDomain, subState => get(subState, 'notificationBanner'));

const selectPrivacyOptions = () =>
  createSelector(selectAppDomain, subState =>
    createSelectFieldOptions(get(subState, 'privacyOptions.data', []), 'id', 'name'),
  );

const selectPrivacyAttributes = () =>
  createSelector(selectAppDomain, subState => get(subState, 'privacyOptions.data', []));

const selectAOSProfessionsOptions = () =>
  createSelector(selectAppDomain, subState => {
    const professions = get(subState, 'aosProfessions.data', []);
    return createSelectFieldOptions(professions, 'id', 'name', {});
  });

const selectLanguages = () => createSelector(selectAppDomain, subState => get(subState, 'languages.data', false));

const selectLanguagesOptions = () =>
  createSelector(selectLanguages(), langs => createSelectFieldOptions(langs, 'id', 'name', {}, true));

const selectDynamicCountriesOptions = () =>
  createSelector(selectAppDomain, subState =>
    createSelectFieldOptions(get(subState, `${initialState.dynamicCountries.listName}.data`), 'id', 'name'),
  );

const selectMediaAttrTypesOptions = () =>
  createSelector(selectAppDomain, subState =>
    createSelectFieldOptions(get(subState, 'attributeTypes.data', []), 'id', 'name'),
  );

const selectMediaAttrTypes = () =>
  createSelector(selectAppDomain, subState => get(subState, 'attributeTypes.data', []));

const selectVideoFormatsNames = () =>
  createSelector(selectAppDomain, subState =>
    get(subState, `${initialState.videoFormats.listName}.data`, [])?.map(format => format?.slug),
  );

const selectSearchProductionsList = () =>
  createSelector(selectAppDomain, subState => get(subState, 'searchProductions'));

const selectSearchProductionsListLoading = () =>
  createSelector(selectAppDomain, subState => get(subState, 'searchProductions.loading'));

const selectProfileAccessRequests = () =>
  createSelector(selectAppDomain, subState => get(subState, 'profileAccessRequests'));
const selectRegistrations = () => createSelector(selectAppDomain, subState => get(subState, 'registrationsList.data'));

const selectOrganizationsListOptions = (withAllData = false) =>
  createSelector(selectAppDomain, subState =>
    createSelectFieldOptions(get(subState, 'organizations.data', []), 'id', 'name', {}, withAllData),
  );

const selectOrganizationsListOptionsLoading = () =>
  createSelector(selectAppDomain, subState => get(subState, 'organizationsLoading'));

export {
  selectAppDomain,
  selectUserData,
  selectUserProfiles,
  selectArtistProfessionsOptions,
  selectAllArtistProfessionsOptions,
  selectVoiceTypesOptions,
  selectInstrumentalistTypesOptions,
  selectAtistProfessions,
  selectAggregationsValues,
  selectAggregationsOptions,
  selectAgenciesListOptions,
  selectAgenciesListOptionsLoading,
  selectCompaniesListOptions,
  selectCompaniesListOptionsLoading,
  selectProfilesListOptions,
  selectResetPasswordMessage,
  selectPasswordKeyStatus,
  selectPasswordUpdateStatus,
  selectAggregationsLoading,
  selectProfilesListLoading,
  selectNotifications,
  selectStartAggregation,
  selectAggregationsError,
  selectCitiesOptions,
  selectVenuesOptions,
  selectStages,
  selectBufferValueData,
  selectWorkTypesOptions,
  selectWorkStylesOptions,
  selectBanner,
  selectPrivacyOptions,
  selectPrivacyAttributes,
  selectAOSProfessionsOptions,
  selectLanguages,
  selectLanguagesOptions,
  selectDynamicCountriesOptions,
  selectMediaAttrTypesOptions,
  selectMediaAttrTypes,
  selectVideoFormatsNames,
  selectProSubscription,
  selectSearchProductionsList,
  selectSearchProductionsListLoading,
  selectVenuesSearchOptions,
  selectProfileAccessRequests,
  selectWorkTypesLoading,
  selectRegistrations,
  selectMultipleWorkTypesOptions,
  selectMultipleWorkTypesLoading,
  selectOrganizationsListOptions,
  selectOrganizationsListOptionsLoading,
};
