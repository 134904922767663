import { getList } from 'utils/API';
import { getLanguage } from 'utils/queriesUtil';
import { transformEntity } from 'utils/globals';
import {
  AGGREGATION_TYPES,
  ENTITY_TYPE,
  PROFESSIONS_AVAILABILITY,
  WORK_ROLE_TYPE_IDS,
  WORK_ROLE_TYPES,
} from 'constants/index';

import { FILTER_AGGREGATION_SEARCH, MUSICAL_WORK_ENDPOINT, PRODUCTIONS } from 'constants/endpointConstants';
import { getOption } from 'utils/globals/app';
import { getQuery } from 'utils/globals/queries';

const defaultConfig = {
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 10 * 60 * 1000,
  getNextPageParam: lastPage => {
    const { limit, page, total } = lastPage;
    const totalFetched = limit * page;

    return totalFetched < total ? page + 1 : undefined;
  },
  getPreviousPageParam: () => false,
};

const fetchWorks = async (filters, cookies, pagination) => {
  const params = {
    queryParams: {
      ...filters,
    },
    pagination,
  };

  return getList(MUSICAL_WORK_ENDPOINT, params, cookies).then(response => response);
};

const fetchWorkProfile = ({ id }, cookies) => {
  const params = {
    queryParams: {
      id,
    },
  };

  return getList(MUSICAL_WORK_ENDPOINT, params, cookies).then(response =>
    transformEntity(response?.data?.data?.[0], ENTITY_TYPE.WORK),
  );
};

const fetchCreatorProfileWorkDetail = ({ id, limit = 25, page = 1 }, cookies) => {
  const params = {
    queryParams: {
      creator_profile_id: id,
    },
    pagination: {
      limit,
      page,
    },
  };
  return getList(MUSICAL_WORK_ENDPOINT, params, cookies).then(response => response.data);
};

const fetchCastCrew = ({ filters = {}, limit = 100, page = 1 }, cookies) => {
  const pagination = {
    limit,
    page,
  };

  const endpoint = FILTER_AGGREGATION_SEARCH(PRODUCTIONS);

  return Promise.allSettled([
    getList(
      endpoint,
      {
        pagination,
        queryParams: {
          ...filters,
          aggregation_type: AGGREGATION_TYPES.WORK_ROLE,
          work_role_type_id: WORK_ROLE_TYPE_IDS.CHARACTER,
          is_work_role_standard: true,
        },
      },
      cookies,
    ),
    getList(
      endpoint,
      {
        pagination,
        queryParams: {
          ...filters,
          aggregation_type: AGGREGATION_TYPES.PROFESSION,
          contribution_type: 'crew',
          availability: PROFESSIONS_AVAILABILITY.CASTING_TOOL,
        },
      },
      cookies,
    ),
    getList(
      endpoint,
      {
        pagination,
        queryParams: {
          ...filters,
          aggregation_type: AGGREGATION_TYPES.WORK_ROLE,
          work_role_type_id: WORK_ROLE_TYPE_IDS.INSTRUMENTALIST,
          is_work_role_standard: true,
        },
      },
      cookies,
    ),
    getList(
      endpoint,
      {
        pagination,
        queryParams: {
          ...filters,
          aggregation_type: AGGREGATION_TYPES.WORK_ROLE,
          work_role_type_id: WORK_ROLE_TYPE_IDS.CHARACTER,
          is_work_role_standard: false,
        },
      },
      cookies,
    ),
    getList(
      endpoint,
      {
        pagination,
        queryParams: {
          ...filters,
          aggregation_type: AGGREGATION_TYPES.WORK_ROLE,
          work_role_type_id: WORK_ROLE_TYPE_IDS.INSTRUMENTALIST,
          is_work_role_standard: false,
        },
      },
      cookies,
    ),
  ]).then(response => {
    const type = [
      WORK_ROLE_TYPES.CAST,
      WORK_ROLE_TYPES.CREW,
      WORK_ROLE_TYPES.INSTRUMENTALIST,
      WORK_ROLE_TYPES.OTHER,
      WORK_ROLE_TYPES.OTHER_INSTRUMENTALIST,
    ];

    return response.map((res, index) => ({
      data: res?.value?.data?.data,
      type: type[index],
    }));
  });
};

const queries = {
  getWorksList: ({ filters, queryConfig = {}, pagination }, cookies, language) => ({
    queryKey: [
      getLanguage(language),
      'GET_WORK_PROFILE_DETAILS',
      JSON.stringify(filters),
      `${pagination?.limit}`,
      `${pagination?.page}`,
    ],
    queryFn: async () => fetchWorks(filters, cookies, pagination),
    select: response => ({
      ...response?.data,
      data: response?.data?.data?.map(option =>
        getOption(
          option,
          'id',
          'original_name',
          { id: option?.id, slug: option?.slug, name: option?.original_name },
          true,
        ),
      ),
    }),
    ...defaultConfig,
    ...queryConfig,
  }),
  getWorkDetails: ({ id, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_WORK_PROFILE_DETAILS', `${id}`],
    queryFn: async () => fetchWorkProfile({ id }, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
  getCreatorWorksDetails: ({ entityId, limit, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_CREATOR_WORK_DETAILS', `${entityId}`, `${limit}`],
    queryFn: async ({ pageParam = 1 }) =>
      fetchCreatorProfileWorkDetail({ id: entityId, limit, page: pageParam }, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
  getCastCrewDetails: getQuery('GET_CAST_CREW', fetchCastCrew),
};

export default queries;
