import { getList } from 'utils/API';
import { getLanguage } from 'utils/queriesUtil';
import { transformEntity } from 'utils/globals';
import { ENTITY_TYPE } from 'constants/index';

import { VENUES } from 'constants/endpointConstants';

const defaultConfig = {
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 10 * 60 * 1000,
};

const fetchVenue = (id, cookies) => {
  const params = {
    queryParams: {
      id,
    },
  };

  return getList(VENUES, params, cookies).then(response =>
    transformEntity(response?.data?.data?.[0], ENTITY_TYPE.VENUE),
  );
};

const queries = {
  getVenueDetails: ({ id, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_VENUE_DETAILS', `${id}`],
    queryFn: async () => fetchVenue(id, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
};

export default queries;
