import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
// eslint-disable-next-line import/named
import { getCompetitionInfo } from '../../utils/API';

import { getCompetitionDataAction } from './actions';

export function* getCompetitionDataRequest({ payload, serverCookies }) {
  try {
    const response = yield call(
      getCompetitionInfo,
      get(payload, 'competitionId'),
      get(payload, 'query'),
      serverCookies,
    );
    yield put(getCompetitionDataAction.success(get(response, 'data')));
  } catch (err) {
    yield put(getCompetitionDataAction.failure(err));
  } finally {
    yield put(getCompetitionDataAction.fulfill());
  }
}

export default function* productionSaga() {
  yield takeLatest(getCompetitionDataAction.TRIGGER, getCompetitionDataRequest);
}
