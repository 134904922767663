import { call, takeLatest } from 'redux-saga/effects';
import { getVenuesWithProductionsAction } from './actions';
import { getListSaga } from '../App/saga';

export function* getVenuesWithProductionsSaga({ payload, serverCookies }) {
  yield call(getListSaga, { payload, serverCookies });
}

export default function* venuesSaga() {
  yield takeLatest(getVenuesWithProductionsAction.TRIGGER, getVenuesWithProductionsSaga);
}
