export const GET_LIST = 'app/DataProvider/GET_LIST';
export const GET_ONE = 'app/DataProvider/GET_ONE';
export const GET = 'app/DataProvider/GET';
export const GET_MANY = 'app/DataProvider/GET_MANY';
export const GET_MANY_REFERENCE = 'app/DataProvider/GET_MANY_REFERENCE';
export const CREATE = 'app/DataProvider/CREATE';
export const UPDATE = 'app/DataProvider/UPDATE';
export const UPDATE_MANY = 'app/DataProvider/UPDATE_MANY';
export const DELETE = 'app/DataProvider/DELETE';
export const DELETE_MANY = 'app/DataProvider/DELETE_MANY';

export const CRUD_GET_LIST = 'app/CRUD/CRUD_GET_LIST';
export const CRUD_GET_WHOLE_LIST = 'app/CRUD/CRUD_GET_WHOLE_LIST';
export const CRUD_GET_ONE = 'app/CRUD/CRUD_GET_ONE';
export const CRUD_UPDATE_ONE = 'app/CRUD/CRUD_UPDATE_ONE';
export const CRUD_CREATE = 'app/CRUD/CRUD_CREATE';
export const CRUD_DELETE = 'app/CRUD/CRUD_DELETE';

export const LOGOUT = 'app/App/LOGOUT';
export const SET_PROFILE_IDS_WITH_ACCESS = 'app/App/SET_PROFILE_IDS_WITH_ACCESS';
export const ADD_PROFILE_IDS_WITH_ACCESS = 'app/App/ADD_PROFILE_IDS_WITH_ACCESS';
export const GET_ACTIVE_PROFILE_SUBSCRIPTIONS = 'app/App/GET_ACTIVE_PROFILE_SUBSCRIPTIONS';
export const GET_PROFILE_SUBSCRIPTIONS = 'app/App/GET_PROFILE_SUBSCRIPTIONS';
export const RESET_PASSWORD = 'app/App/RESET_PASSWORD';
export const RESET_RESET_PASSWORD = 'app/App/RESET_RESET_PASSWORD';
export const UPDATE_PASSWORD = 'app/App/UPDATE_PASSWORD';
export const CHECK_UPDATE_PASSWORD_KEY = 'app/App/CHECK_UPDATE_PASSWORD_KEY';
export const GET_SUBSCRIPTIONS = 'app/Registration/GET_SUBSCRIPTIONS';

export const SET_START_AGGREGATIONS = 'app/App/SET_START_AGGREGATIONS';
export const GET_AGGREGATIONS = 'app/App/GET_AGGREGATIONS';
export const CLEAN_AGGREGATIONS = 'app/App/CLEAN_AGGREGATIONS';
export const GET_ARTIST_PROFESSIONS = 'app/App/GET_ARTIST_PROFESSIONS';
export const GET_ARTIST_VOICE_TYPES = 'app/App/GET_ARTIST_VOICE_TYPES';
export const GET_AGENCIES = 'app/App/GET_AGENCIES';
export const CLEAR_AGENCIES = 'app/App/CLEAR_AGENCIES';
export const GET_COMPANIES = 'app/App/GET_COMPANIES';
export const CLEAR_COMPANIES = 'app/App/CLEAR_COMPANIES';
export const GET_PROFILES_LIST = 'app/App/GET_PROFILES_LIST';
export const CLEAR_PROFILES_LIST = 'app/App/CLEAR_PROFILES_LIST';

export const ENQUEUE_SNACKBAR = 'app/App/ENQUEUE_SNACKBAR';
export const REMOVE_SNACKBAR = 'app/App/REMOVE_SNACKBAR';

export const GET_CITIES = 'app/App/GET_CITIES';
export const RESET_CITIES = 'app/App/RESET_CITIES';
export const GET_VENUES = 'app/App/GET_VENUES';
export const GET_VENUES_SEARCH = 'app/App/GET_VENUES_SEARCH';
export const RESET_VENUES = 'app/App/RESET_VENUES';
export const RESET_VENUES_SEARCH = 'app/App/RESET_VENUES_SEARCH';
export const GET_STAGES = 'app/App/GET_STAGES';

export const USE_BUFFER_VALUE = 'app/App/USE_BUFFER_VALUE';
export const GET_WORK_TYPES = 'app/App/GET_WORK_TYPES';
export const GET_MULTIPLE_WORK_TYPES = 'app/App/GET_MULTIPLE_WORK_TYPES';
export const GET_WORK_STYLES = 'app/App/GET_WORK_STYLES';
export const GET_PRIVACY_OPTIONS = 'app/App/GET_PRIVACY_OPTIONS';
export const GET_AOS_PROFESSIONS = 'app/App/GET_AOS_PROFESSIONS';

export const GET_LANGUAGES = 'app/App/GET_LANGUAGES';
export const GET_DYNAMIC_COUNTRIES = 'app/App/GET_DYNAMIC_COUNTRIES';
export const GET_MEDIA_ATTRS_TYPES = 'app/App/GET_MEDIA_ATTRS_TYPES';
export const GET_VIDEO_FORMAT_TYPES = 'app/App/GET_VIDEO_FORMAT_TYPES';

export const GET_SEARCH_PRODUCTIONS_LIST = 'app/App/GET_SEARCH_PRODUCTIONS_LIST';
export const CLEAN_SEARCH_PRODUCTIONS_LIST = 'app/App/CLEAN_SEARCH_PRODUCTIONS_LIST';

export const GET_PROFILE_ACCESS_REQUESTS = 'app/App/GET_PROFILE_ACCESS_REQUESTS';
export const UPDATE_ACTIVE_PROFILE_ACTION = 'app/App/UPDATE_ACTIVE_PROFILE_ACTION';

export const GET_ORGANIZATIONS = 'app/App/GET_ORGANIZATIONS';
