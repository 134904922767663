import groupBy from 'lodash/groupBy';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import invert from 'lodash/invert';
import pick from 'lodash/pick';

import { getContextualFilters, getFiltersFromRouteContext, getFilterDetails } from 'utils/globals/filters';
import { createDate } from 'utils/date';
import { getProductionTitle, filterDigitalVODDates } from 'utils/productions';
import { getProfileName, getProfileProfession } from 'utils/artists';
import { getWorkTitle } from 'utils/works';
import getLinkProps from 'utils/globals/getLinkProps';

import {
  ENTITY_SLUG_PATTERN,
  DATE_FORMATS,
  ENTITY_TYPE,
  ENTITY_VALID_TABS,
  ENTITY_TABS_TITLE,
  ENTITY_MAIN_TABS,
  ENTITY_DETAILS_TAB,
  URL_SLUG_PREFIXES,
  URL_SLUG_TYPES,
  SOCIAL_NETWORK,
  BASE_PAGE_ROUTES,
  RESPONSE_STATUS_CODES,
  TP,
  URL_STATIC_PATHS,
  PROFILE_TYPES,
  ARTIST_CUSTOMIZE,
  AGGREGATION_TYPES,
} from 'constants/index';

import { FILTER_SLUG_TYPE, FILTER_TYPES } from 'constants/filters';

import { getLanguage } from 'utils/queriesUtil';

const paramsToQuery = searchParams =>
  Array.from(searchParams.keys()).reduce((query, key) => {
    const value = searchParams.get(key);
    return {
      ...query,
      [key]: value,
    };
  }, {});

const getIdPrefixSlugType = idWithPrefix => {
  if (/^[a-z]\d.*/.test(idWithPrefix)) {
    const prefix = idWithPrefix?.[0];
    const matchedSlugPrefix = prefix && URL_SLUG_PREFIXES.find(item => item.prefix === prefix);
    const slugType = matchedSlugPrefix?.type;
    const entityType = matchedSlugPrefix?.entityType || null;
    const idString = idWithPrefix?.replace(/\D/g, '');
    const id = idString ? parseInt(idString, 10) : null;

    return {
      id,
      prefix,
      slugType,
      entityType,
    };
  }

  return {
    id: idWithPrefix,
    prefix: null,
    slugType: null,
    entityType: null,
  };
};

export const updateQueryParams = ({ router, query = {}, appendQuery = true, shouldReplace = true }) => {
  const [pathname, queryPart] = router?.asPath?.split('?') || [];

  const currentQuery = appendQuery && queryPart?.length > 0 ? paramsToQuery(new URLSearchParams(queryPart)) : {};
  const updatedQuery = { ...currentQuery, ...query };

  let asPath = pathname;

  if (Object.keys(updatedQuery)?.length) {
    const searchParams = new URLSearchParams(updatedQuery);
    searchParams.sort();

    asPath = `${pathname}?${searchParams.toString()}`;
  }

  const action = shouldReplace ? 'replace' : 'push';

  if (pathname?.length > 0 && asPath?.length > 0) {
    router[action]({ pathname, query: updatedQuery }, asPath, { shallow: true });
  }
};

export const getURLSlugComponents = (slugPath = '') => {
  const entityType = null;
  if (slugPath === ENTITY_DETAILS_TAB.CALENDAR) {
    return {
      id: null,
      slug: slugPath,
      prefix: null,
      slugType: URL_SLUG_TYPES.VIEW_MODE,
      entityType,
    };
  }

  if (ENTITY_SLUG_PATTERN.test(slugPath)) {
    const slugParts = slugPath.split('-');
    const idWithPrefix = last(slugParts);
    const slug = slugParts?.length > 1 ? slugPath.replace(`-${idWithPrefix}`, '') : '';

    return {
      slug,
      ...getIdPrefixSlugType(idWithPrefix),
    };
  }

  const dateRegex = /^(?:\d{2}-)?(?:(january|february|march|april|may|june|july|august|september|october|november|december)-)?\d{4}$/g;

  if (dateRegex.test(slugPath)) {
    const date = createDate(slugPath).locale('en');

    if (date.isValid()) {
      if (date.locale('en').format('YYYY') === slugPath) {
        return {
          id: date.format('YYYY'),
          slug: slugPath,
          prefix: null,
          slugType: URL_SLUG_TYPES.YEAR,
          entityType,
        };
      }

      if (
        date
          .locale('en')
          .format('MMMM-YYYY')
          .toLowerCase() === slugPath
      ) {
        return {
          id: date.format('YYYY-MM'),
          slug: slugPath,
          prefix: null,
          slugType: URL_SLUG_TYPES.MONTH_YEAR,
          entityType,
        };
      }

      if (
        date
          .locale('en')
          .format('DD-MMMM-YYYY')
          .toLowerCase() === slugPath
      ) {
        return {
          id: date.format('YYYY-MM-DD'),
          slug: slugPath,
          prefix: null,
          slugType: URL_SLUG_TYPES.DATE,
          entityType,
        };
      }
    }
  }

  const slugParts = slugPath.split('-');
  let id = slugPath;
  let slug = null;

  if (slugParts?.length > 1) {
    const idString = last(slugParts);
    id = idString ? parseInt(idString, 10) : null;
    slug = slugPath.replace(`-${id}`, '');
  }

  if (!Number.isNaN(Number(id))) {
    id = parseInt(id, 10);
  }

  return {
    id,
    slug,
    prefix: null,
    slugType: null,
    entityType: null,
    ...getIdPrefixSlugType(id),
  };
};

export const getLocation = item => {
  const locationParts = new Set();

  if (item?.city?.name) {
    locationParts.add(item?.city?.name);
  }

  if (item?.city?.areas?.length > 0) {
    const sortAreas = sortBy(item?.city?.areas, 'order');
    sortAreas.forEach(area => {
      locationParts.add(area?.name);
    });
  }

  if (item?.country?.name) {
    locationParts.add(item?.country?.name);
  }

  return [...locationParts].join(', ');
};

export const getPageTitle = ({ entity, entityType }) => {
  if (!entity) {
    return '';
  }

  switch (entityType) {
    case ENTITY_TYPE.ARTIST:
      return (
        entity?.fullName ||
        (entity?.firstName && entity?.lastName && `${entity?.firstName} ${entity?.lastName}`) ||
        entity?.name
      );
    case ENTITY_TYPE.COMPANY:
    case ENTITY_TYPE.FESTIVAL:
    case ENTITY_TYPE.VENUE:
      return getWorkTitle(entity);
    case ENTITY_TYPE.WORK:
      return entity?.original_name;
    case ENTITY_TYPE.PRODUCTION: {
      if (entity?.name) {
        return entity?.name;
      }
      const workTranslate =
        entity?.works?.[0]?.original_name !== entity?.works?.[0]?.name ? ` - ${entity?.works?.[0]?.name}` : '';

      return `${entity?.works?.[0]?.original_name}${workTranslate}`;
    }
    default:
      return '';
  }
};

export const getEntitySlug = ({ entity, slugType }) => {
  const { slug, id } = entity || {};
  const slugPrefix = URL_SLUG_PREFIXES.find(item => item.type === slugType)?.prefix || '';

  let pathSlug = '';

  if (slug && slugType !== URL_SLUG_TYPES.SEASON) {
    pathSlug += slug;
  }

  if (id) {
    if (pathSlug?.length > 0) {
      pathSlug += '-';
    }

    if (slugPrefix) {
      pathSlug += slugPrefix;
    }

    pathSlug += id;
  }

  return pathSlug;
};

export const getRouteValidityStatus = ({
  entityType,
  entity,
  basePath,
  mainPath,
  subPath,
  filters,
  pro,
  pathname,
  edit,
  paths,
  language: requestedLanguage,
}) => {
  const i18nLanguage = getLanguage(requestedLanguage);
  const endLangRegex = new RegExp(`/${i18nLanguage}$`, 'g');
  const requestURL = pathname?.split('?')?.[0]?.replace(endLangRegex, '');

  let suffix = '';

  if (
    mainPath &&
    (Object.values(ENTITY_MAIN_TABS).includes(mainPath) || Object.values(URL_STATIC_PATHS).includes(mainPath)) &&
    mainPath !== ENTITY_MAIN_TABS.HOME
  ) {
    suffix = mainPath;
  }

  if (
    subPath &&
    Object.values(ENTITY_DETAILS_TAB).includes(subPath) &&
    (mainPath === ENTITY_MAIN_TABS.REVIEWS || subPath !== ENTITY_DETAILS_TAB.PRODUCTIONS)
  ) {
    suffix += `/${subPath}`;
  }

  const shouldSetSuffix =
    paths?.length > 0 && !entity && !edit && !mainPath && !subPath && !Object.keys(filters).length;

  if (shouldSetSuffix) {
    suffix = paths?.join('/');
  }

  const validURL = getLinkProps({
    baseRoute: basePath,
    entityType,
    entity,
    filters,
    path: suffix,
    language: i18nLanguage,
    edit,
    pro,
  });

  const validURLPath = validURL?.as?.split('?')?.[0]?.replace(endLangRegex, '');

  const shouldRedirect = requestURL && decodeURIComponent(requestURL) !== decodeURIComponent(validURLPath);

  return {
    shouldRedirect,
    validURL: validURL?.url,
    responseStatusCode: shouldRedirect ? RESPONSE_STATUS_CODES.PERMANENT_REDIRECT : RESPONSE_STATUS_CODES.SUCCESS,
  };
};

export const getStubPageRouteContext = (query, language = getLanguage()) => {
  const { id, entitySlug, country, action = [], entityType, ...queryParams } = query || {};

  const paths = [...action];
  const index = paths.lastIndexOf(language);

  if (index > -1) {
    paths.splice(index, 1);
  }

  const [rawMainPath, ...rest] = paths;
  let mainPath = rawMainPath;
  let urlSlugs = [];

  if (createDate(rawMainPath).isValid()) {
    mainPath = undefined;
    urlSlugs = paths?.map(item => getURLSlugComponents(item));
  } else {
    urlSlugs = rest?.map(item => getURLSlugComponents(item));
  }

  const { _ignore, ...context } = groupBy(urlSlugs, item => item?.slugType || 'ignore');

  const productionId = context[URL_SLUG_TYPES.PRODUCTION]?.[0]?.id;

  let subPath = null;

  if ([ENTITY_DETAILS_TAB.DIGITAL, ENTITY_DETAILS_TAB.GENERAL, ENTITY_DETAILS_TAB.INTRO_SHOWREEL].includes(rest?.[0])) {
    subPath = rest?.[0];
  } else if (
    rest?.[0] === ENTITY_DETAILS_TAB.PRODUCTIONS ||
    (rest?.length === 1 && context?.[URL_SLUG_TYPES.PRODUCTION]?.length === 1)
  ) {
    subPath = ENTITY_DETAILS_TAB.PRODUCTIONS;
  }

  Object.keys(queryParams).forEach(key => {
    if (URL_SLUG_TYPES.PRODUCTION_PERIOD === key) {
      context[key] = queryParams[key];
    } else if (URL_SLUG_TYPES.VIEW_MODE !== key) {
      const values = queryParams[key]?.split(',');

      if (Array.isArray(values) && values?.length > 0) {
        context[key] = values?.map(item => {
          const idString = item?.replace(/\D/g, '');

          return { id: idString ? parseInt(idString, 10) : null };
        });
      }
    }
  });

  const entitySlugComponents = getURLSlugComponents(entitySlug || id);
  const pageEntityType = entitySlugComponents?.entityType || entityType;
  const validFilters = getContextualFilters({ entityType: pageEntityType, tabKey: mainPath });
  const filters = getFiltersFromRouteContext({ validFilters, context, queryParams });

  return {
    entityId: entitySlugComponents?.id,
    entitySlug: entitySlugComponents?.slug,
    entityType: pageEntityType,
    countrySlug: country,
    productionId,
    mainPath,
    subPath,
    paths: rest,
    context,
    queryParams,
    filters,
    language,
  };
};

export const getBlocksLink = links => {
  const currentLanguage = getLanguage();
  return links[currentLanguage] ? links[currentLanguage] : links.en;
};

const shouldShowTab = ({ tab, stats = {}, entityType, isEditMode }) => {
  if (isEditMode && tab !== ENTITY_MAIN_TABS.WORKS) {
    return true;
  }

  switch (tab) {
    case ENTITY_MAIN_TABS.HOME: {
      return true;
    }
    case ENTITY_MAIN_TABS.CALENDAR: {
      return stats?.performances?.future?.exists;
    }
    case ENTITY_MAIN_TABS.PERFORMANCES: {
      switch (entityType) {
        case ENTITY_TYPE.PRODUCTION:
          return true;
        default:
          return stats?.productions?.exists || stats?.performances?.future?.exists;
      }
    }
    case ENTITY_MAIN_TABS.VIDEOS: {
      return stats?.media?.videos?.exists || stats?.showreels?.intro?.exists;
    }
    case ENTITY_MAIN_TABS.IMAGES: {
      return stats?.media?.images?.exists;
    }
    case ENTITY_MAIN_TABS.REVIEWS: {
      return stats?.reviews?.exists;
    }
    case ENTITY_MAIN_TABS.REPERTOIRE: {
      return stats?.repertoire?.exists;
    }
    case ENTITY_MAIN_TABS.ORG_GRAPH:
    case ENTITY_MAIN_TABS.ROLE_GRAPH: {
      return stats?.repertoire?.exists;
    }
    case ENTITY_MAIN_TABS.BIO:
    case ENTITY_MAIN_TABS.ABOUT: {
      if (entityType === ENTITY_TYPE.PRODUCTION) {
        return true;
      }

      if (entityType === ENTITY_TYPE.ORGANIZATION) {
        return stats?.about?.exists;
      }

      return stats?.about?.exists || stats?.personalInfo?.exists;
    }
    case ENTITY_MAIN_TABS.CONTACT: {
      return stats?.contacts?.exists || stats?.agencies?.exists;
    }
    case ENTITY_MAIN_TABS.WORKS: {
      return stats?.creations?.exists;
    }
    case ENTITY_MAIN_TABS.CAST_CREW: {
      return stats?.contributions?.exists;
    }
    case ENTITY_MAIN_TABS.VENUES: {
      return stats?.venues?.exists;
    }
    case ENTITY_MAIN_TABS.RENTALS: {
      return stats?.rentals?.exists;
    }
    case ENTITY_MAIN_TABS.INSIGHTS: {
      return true; // TODO: Check this condition
    }
    default: {
      return false;
    }
  }
};

export const useEntitySectionTabs = ({ entityType, entity, baseRoute, isEditMode }) => {
  const isCastingProfile = entityType === ENTITY_TYPE.ARTIST && baseRoute === BASE_PAGE_ROUTES.CASTING;
  let validTabs = ENTITY_VALID_TABS[entityType] || [];

  if (isCastingProfile) {
    validTabs = [
      ENTITY_MAIN_TABS.PERFORMANCES,
      ENTITY_MAIN_TABS.CALENDAR,
      ENTITY_MAIN_TABS.REPERTOIRE,
      ENTITY_MAIN_TABS.ORG_GRAPH,
      ENTITY_MAIN_TABS.ROLE_GRAPH,
      ENTITY_MAIN_TABS.BIO,
    ];
  }

  const { as, href, getSubPath } = getLinkProps({ entity, entityType, baseRoute });

  const getTabTitle = tab => {
    if (isCastingProfile) {
      if (tab === ENTITY_MAIN_TABS.PERFORMANCES) {
        return `${TP}.m_PERF`;
      }

      if (tab === ENTITY_MAIN_TABS.CALENDAR) {
        return `${TP}.FN_CALENDAR`;
      }
    }

    return ENTITY_TABS_TITLE[entityType][tab] || tab;
  };

  const getTabLinkProps = tab => {
    if (tab === ENTITY_MAIN_TABS.HOME || (isCastingProfile && tab === ENTITY_MAIN_TABS.PERFORMANCES)) {
      return { as, href, shallow: true };
    }

    if (tab === ENTITY_MAIN_TABS.PERFORMANCES && !entity?.stats?.productions?.future?.exists) {
      return getSubPath({ path: `${tab}/${ENTITY_DETAILS_TAB.PAST}` });
    }

    return getSubPath({ path: tab });
  };

  return validTabs
    .map(tab => ({
      title: getTabTitle(tab),
      key: tab,
      linkProps: getTabLinkProps(tab),
      showTab: shouldShowTab({ tab, stats: entity?.stats, entityType, isCastingProfile, isEditMode }),
    }))
    .filter(tab => tab?.showTab);
};

const shouldShowAboutSection = entity => {
  if (!entity) {
    return false;
  }

  const hasBio = entity?.stats?.bioCount > 0 || entity?.stats?.hasBio || entity?.stats?.hasDescription || false;

  return hasBio;
};

const hasPersonalInfo = (entity, entityType) => {
  if (!entity || entityType !== ENTITY_TYPE.ARTIST) {
    return false;
  }

  const { personal, stageName, alias } = entity;
  const hasBirthYear = personal?.birth?.year > 0;
  const hasDeathYear = personal?.death?.year > 0;
  const hasCitizenship = personal?.citizenShip?.countries.length > 0;

  return hasBirthYear || hasDeathYear || hasCitizenship || stageName?.length > 0 || alias?.length > 0;
};

const getSocialContactsCount = entity => {
  const socialContacts = entity?.contacts?.filter(social => SOCIAL_NETWORK.ID.includes(social?.contactType?.id));

  return socialContacts?.length || 0;
};

const getNonSocialContactsCount = entity => {
  const nonSocialContacts = entity?.contacts?.filter(social => !SOCIAL_NETWORK.ID.includes(social?.contactType?.id));

  return nonSocialContacts?.length || 0;
};

const getMetaInfo = (entity, entityType) => {
  let title = null;
  let subTitle = null;
  const description = null;
  let img = null;

  switch (entityType) {
    case ENTITY_TYPE.PRODUCTION: {
      title = getProductionTitle(entity);
      img = entity?.poster?.file?.urls?.medium;
      break;
    }
    case ENTITY_TYPE.ARTIST: {
      title = getProfileName(entity);
      subTitle = getProfileProfession(entity);
      img = entity?.image?.medium;
      break;
    }
    default:
      title = entity?.name || '';
      subTitle = getCityCountry(entity);
      img = entity?.logo?.medium || null;
  }

  return {
    title,
    subTitle,
    description,
    img,
  };
};

const statsSerializer = value => {
  if (typeof value === 'boolean') {
    return {
      exists: value,
    };
  }

  const count = value || 0;

  return {
    exists: count > 0,
    total: count <= 0 ? 0 : count,
  };
};

const getMediaStats = (counts, type) => {
  const featuredMedia = counts?.[type] || 0;

  const pastProductionMedia = counts?.production?.past?.[type] || 0;
  const futureProductionMedia = counts?.production?.future?.[type] || 0;
  const totalProductionMedia = pastProductionMedia + futureProductionMedia;

  const pastDigitalMedia = counts?.production?.past?.digital || 0;
  const futureDigitalMedia = counts?.production?.future?.digital || 0;
  const digitalMedia = type === 'videos' ? pastDigitalMedia + futureDigitalMedia : 0;

  return {
    featured: statsSerializer(featuredMedia),
    production: {
      past: statsSerializer(pastProductionMedia),
      future: statsSerializer(futureProductionMedia),
      ...statsSerializer(totalProductionMedia),
    },
    ...(type === 'videos' && {
      digital: {
        past: statsSerializer(pastDigitalMedia),
        future: statsSerializer(futureDigitalMedia),
        ...statsSerializer(digitalMedia),
      },
    }),
    ...statsSerializer(featuredMedia + totalProductionMedia + digitalMedia),
  };
};

const findPermissionByKey = (permissions, keyToFind) => {
  const permission = permissions?.find(item => item?.key === keyToFind);
  return { key: permission?.key, id: permission?.id, value: permission?.value, enabled: permission?.enabled };
};

const getCustomizationStats = entity => {
  const { stats, customization: permission, subscriptionStatus } = entity;
  const isArtistProSubscriber = subscriptionStatus?.includes(PROFILE_TYPES.PRO);
  const artistWorkedWith = findPermissionByKey(permission, ARTIST_CUSTOMIZE.ARTIST);
  const agencyData = findPermissionByKey(permission, ARTIST_CUSTOMIZE.AGENCY);
  const organizationsWorkedWith = findPermissionByKey(permission, ARTIST_CUSTOMIZE.ORGANIZATION);
  const venuesWorkedWith = findPermissionByKey(permission, ARTIST_CUSTOMIZE.VENUE);
  const repertoireData = findPermissionByKey(permission, ARTIST_CUSTOMIZE.REPERTOIRE);
  const suggestedEntities = findPermissionByKey(permission, ARTIST_CUSTOMIZE.SUGGESTED);
  const artistContacts = findPermissionByKey(permission, ARTIST_CUSTOMIZE.ARTIST_CONTACT);

  const preferences = {
    artist: {
      ...statsSerializer(stats?.customizations?.artist),
      visibility: isArtistProSubscriber && artistWorkedWith?.value,
      id: artistWorkedWith?.id,
    },
    agency: {
      ...statsSerializer(stats?.customizations?.agency),
      visibility: agencyData?.value,
      id: agencyData?.id,
    },
    organization: {
      ...statsSerializer(stats?.customizations?.organization),
      visibility: isArtistProSubscriber && organizationsWorkedWith?.value,
      id: organizationsWorkedWith?.id,
    },
    venue: {
      ...statsSerializer(stats?.customizations?.venue),
      visibility: isArtistProSubscriber && venuesWorkedWith?.value,
      id: venuesWorkedWith?.id,
    },
    suggestedEntities: {
      visibility: suggestedEntities?.value,
      id: suggestedEntities?.id,
    },
    repertoire: {
      visibility: repertoireData?.value,
      id: repertoireData?.id,
    },
    artistContact: {
      visibility: artistContacts?.value,
      id: artistContacts?.id,
    },
  };

  return preferences;
};

const getReviewStats = counts => {
  const featuredReviews = counts?.general || 0;
  const pastProductionReviews = counts?.production?.past || 0;
  const futureProductionReviews = counts?.production?.future || 0;
  const totalProductionReviews = pastProductionReviews + futureProductionReviews;
  const totalReviews = featuredReviews + totalProductionReviews;

  return {
    featured: statsSerializer(featuredReviews),
    production: {
      past: statsSerializer(pastProductionReviews),
      future: statsSerializer(futureProductionReviews),
      ...statsSerializer(totalProductionReviews),
    },
    ...statsSerializer(totalReviews),
  };
};

const getProductionTicketStats = production => {
  const todayDate = createDate();

  if (!production?.maxDate) {
    return false;
  }

  const lastPerformanceDate = createDate(production?.maxDate);

  return todayDate.isBefore(lastPerformanceDate, 'day') || todayDate.isSame(lastPerformanceDate, 'day');
};

const getEntityStats = (entity, entityType) => {
  const todayDate = createDate();
  const stats = entity?.stats || {};
  const images =
    entityType === ENTITY_TYPE.PRODUCTION
      ? statsSerializer(stats?.imageCount)
      : getMediaStats(stats?.mediaCount, 'images');
  const videos =
    entityType === ENTITY_TYPE.PRODUCTION
      ? statsSerializer(stats?.videoCount)
      : getMediaStats(stats?.mediaCount, 'videos');
  const composerCreations = statsSerializer(stats?.creationCount?.composer);
  const physicalPerformances = filterDigitalVODDates(entity);

  const futurePerformancesCount =
    entity?.stats?.futurePerformanceCount ??
    physicalPerformances?.filter(date => {
      const startDate = createDate(date?.startDate);
      return todayDate.isBefore(startDate, 'day') || todayDate.isSame(startDate, 'day');
    })?.length ??
    0;

  return {
    isPro: entity?.subscriptionStatus === PROFILE_TYPES.PRO,
    media: {
      images,
      videos,
      widget: statsSerializer(stats.hasMediaWidget),
      ...statsSerializer(videos?.total + images?.total || entity?.stats?.imageCount + entity?.stats?.videoCount),
    },
    showreels: {
      intro: statsSerializer(stats?.showReels?.intro),
      audition: statsSerializer(stats?.showReels?.audition),
      ...statsSerializer(stats?.hasIntroShowReel || stats?.hasAuditionShowReel),
    },
    creations: {
      composer: composerCreations,
      ...statsSerializer(composerCreations?.total),
    },
    contacts: {
      social: statsSerializer(getSocialContactsCount(entity)),
      nonSocial: statsSerializer(getNonSocialContactsCount(entity)),
      ...statsSerializer(entity?.contacts?.length),
    },
    performances: {
      future: statsSerializer(futurePerformancesCount),
      physical: statsSerializer(physicalPerformances?.length),
      ...statsSerializer(entity?.performances?.length || 0),
    },
    productions: {
      future: statsSerializer(stats?.futureProductionCount),
      published: statsSerializer(stats?.publishedProductionCount),
      past: statsSerializer(stats?.productionCount - stats?.futureProductionCount),
      ...statsSerializer(stats?.productionCount),
    },
    reviews:
      entityType === ENTITY_TYPE.PRODUCTION ? statsSerializer(stats?.reviewCount) : getReviewStats(stats?.reviewCount),
    rentals: statsSerializer(stats?.hasRentals ?? entity?.rentals?.length),
    venues: statsSerializer(entity?.venues?.length),
    contributions: statsSerializer(entity?.contributions?.length),
    booklet: statsSerializer(stats?.hasBooklet),
    synopsis: statsSerializer(stats?.hasSynopsis),
    customizations: getCustomizationStats(entity),
    tickets:
      entityType === ENTITY_TYPE.PRODUCTION ? getProductionTicketStats(entity) : statsSerializer(stats?.hasTickets),
    about: statsSerializer(shouldShowAboutSection(entity)),
    personalInfo: statsSerializer(hasPersonalInfo(entity, entityType)),
    repertoire: {
      past: statsSerializer(stats?.repertoireCount),
      future: statsSerializer(stats?.futureRepertoireCount),
      ...statsSerializer((stats?.repertoireCount || 0) + (stats?.futureRepertoireCount || 0)),
    },
    agencies: statsSerializer(entity?.agencies?.length),
    associatedOrgs: statsSerializer(stats?.assocOrgsCount),
    meta: {
      upcomingProductionId: stats?.upcomingProduction,
    },
  };
};

export const transformEntity = (entity, entityType) => {
  if (!entity) {
    return null;
  }

  const metaInfo = getMetaInfo(entity, entityType);
  const stats = getEntityStats(entity, entityType);

  return {
    ...entity,
    metaInfo,
    stats,
  };
};

export const getCityCountry = (entity, separator = ', ', reverse = false) => {
  const location = [];

  if (entity?.city?.name) {
    location.push(entity?.city?.name);
  }

  if (entity?.country?.name) {
    location.push(entity?.country?.name);
  }

  if (reverse) {
    location.reverse();
  }

  return location.join(separator);
};

export const getQueryString = (params = {}) => {
  const searchParams = new URLSearchParams(params);
  searchParams.sort();

  return searchParams.toString();
};

export const getBreadcrumbFilterParts = ({ entityType, entity, filters, tab }) => {
  if (isEmpty(filters)) {
    return [];
  }

  const shouldGenerateLinks = entityType && entity?.id;

  const queryTypeMap = invert(FILTER_SLUG_TYPE);
  let filterPrefixes = {};

  mapValues(filters, (values, key) => {
    const filterType = queryTypeMap[key];
    const filterDetails = getFilterDetails(filterType);

    if (!filterDetails) {
      return;
    }

    const { order } = filterDetails;

    if (filterType === FILTER_TYPES.DATE) {
      const dateTo = filters?.[key]?.[`${key}_to`];
      const dateFrom = filters?.[key]?.[`${key}_from`];

      const startOfMonth = createDate(dateFrom).startOf('month');
      const endOfMonth = createDate(dateTo).endOf('month');

      let dateLabel = '';

      if (
        startOfMonth.format(DATE_FORMATS.FULL_DATE) === dateFrom &&
        endOfMonth.format(DATE_FORMATS.FULL_DATE) === dateTo
      ) {
        dateLabel = `${startOfMonth.format(DATE_FORMATS.YEAR)} ${startOfMonth.format('MMMM')}`;
      } else if (dateTo === dateFrom) {
        dateLabel = `${createDate(dateFrom).format(DATE_FORMATS.DATE_LABEL)}`;
      } else {
        dateLabel = `${createDate(dateFrom).format(DATE_FORMATS.DATE_LABEL)} - ${createDate(dateTo).format(
          DATE_FORMATS.DATE_LABEL,
        )}`;
      }

      if (dateLabel) {
        filterPrefixes = {
          ...filterPrefixes,
          [order]: {
            title: dateLabel,
            key,
          },
        };
      }
    } else if (order >= 0 && Array.isArray(values) && values?.length === 1 && values?.[0]?.slug) {
      filterPrefixes = {
        ...filterPrefixes,
        [order]: {
          title: values?.[0]?.label || values?.[0]?.slug,
          key,
        },
      };
    }
  });

  const prefixOrder = Object.keys(filterPrefixes).sort();

  let filterKeys = [];

  return prefixOrder.map(key => {
    filterKeys = [...filterKeys, filterPrefixes[key].key];

    return {
      title: filterPrefixes?.[key]?.title,
      path: shouldGenerateLinks
        ? getLinkProps({ entityType, entity, filters: pick(filters, filterKeys), path: tab?.key })
        : null,
    };
  });
};

export const determineEntityTypeFromAggregation = aggregationType => {
  const defaultMapping = {
    [AGGREGATION_TYPES.PROFILE]: ENTITY_TYPE.ARTIST,
    [AGGREGATION_TYPES.PRODUCTION]: ENTITY_TYPE.PRODUCTION,
    [AGGREGATION_TYPES.WORK]: ENTITY_TYPE.WORK,
    [AGGREGATION_TYPES.ORGANIZATION]: ENTITY_TYPE.ORGANIZATION,
    [AGGREGATION_TYPES.COMPANY]: ENTITY_TYPE.ORGANIZATION,
    [AGGREGATION_TYPES.FESTIVAL]: ENTITY_TYPE.ORGANIZATION,
    [AGGREGATION_TYPES.CREATOR]: ENTITY_TYPE.ARTIST, // NOTE: Default to ARTIST
    [AGGREGATION_TYPES.CONDUCTOR]: ENTITY_TYPE.ARTIST,
    [AGGREGATION_TYPES.DIRECTOR]: ENTITY_TYPE.ARTIST,
    [AGGREGATION_TYPES.CO_CAST]: ENTITY_TYPE.ARTIST,
    [AGGREGATION_TYPES.CHORUS]: ENTITY_TYPE.ORGANIZATION,
    [AGGREGATION_TYPES.ORCHESTRA]: ENTITY_TYPE.ORGANIZATION,
    [AGGREGATION_TYPES.VENUE]: ENTITY_TYPE.VENUE,
  };

  // NOTE: Add any special logic here. For example, if CREATOR could be an ORGANIZATION based on certain conditions:
  // if (aggregationType === AGGREGATION_TYPES.CREATOR && someCondition) {
  //   return ENTITY_TYPE.ORGANIZATION;
  // }

  return defaultMapping[aggregationType] || null; // Fallback to null or any default if no match is found
};
