import get from 'lodash/get';
import { getAgenciesByCountryAction, getAgenciesList } from './actions';
import { listReducer, defaultListParams } from '../../utils/crudUtils';

export const key = 'AgencyByCountry';

export const initialState = {
  agencies: defaultListParams,
  agenciesList: defaultListParams,
  chosenCountry: null,
  loading: false,
  error: null,
};

const agenciesByCountryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getAgenciesByCountryAction.TRIGGER:
      return { ...state, loading: true, error: null };
    case getAgenciesByCountryAction.SUCCESS:
      return {
        ...state,
        agencies: get(payload, 'data'),
        chosenCountry: get(payload, 'country'),
        loading: false,
      };
    case getAgenciesByCountryAction.FAILURE:
      return { ...state, error: payload, loading: false };

    case getAgenciesList.TRIGGER:
    case getAgenciesList.SUCCESS:
    case getAgenciesList.FAILURE:
      return listReducer(state, type, payload, 'agenciesList');

    default:
      return state;
  }
};

export default agenciesByCountryReducer;
