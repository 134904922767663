import { createRoutine } from 'redux-saga-routines';
import {
  GET_SEASON_COMPANIES,
  GET_SEASON_COMPANIES_BY_COUNTRY_SLUG,
  GET_SEASON_COUNTRIES,
  GET_SEASONS,
  GET_SEASONS_PRODUCTION,
  GET_SEASONS_WITH_PRODUCTION,
} from './constants';

export const getSeasonCompaniesAction = createRoutine(GET_SEASON_COMPANIES);
export const getSeasonCompaniesByCountrySlugAction = createRoutine(GET_SEASON_COMPANIES_BY_COUNTRY_SLUG);
export const getSeasonCountriesAction = createRoutine(GET_SEASON_COUNTRIES);
export const getSeasonsAction = createRoutine(GET_SEASONS);
export const getSeasonsProductionAction = createRoutine(GET_SEASONS_PRODUCTION);
export const getSeasonWithProductionsAction = createRoutine(GET_SEASONS_WITH_PRODUCTION);
