import { get, first } from 'lodash';

export const getProfessionName = source => get(source, 'profession.name');

export const getShortProfessionDetails = artist => {
  if (Array.isArray(artist?.professions) && artist?.professions?.length > 0) {
    const primaryProfession = artist.professions.filter(a => a.isPrimary);
    const otherProfessions = artist.professions.filter(a => !a.isPrimary);
    const primaryProfessionToDisplay =
      getProfessionName(first(primaryProfession)) || get(artist, 'profession.name') || get(artist, 'summary');

    if (primaryProfessionToDisplay) {
      if (otherProfessions.length > 0) {
        return `${primaryProfessionToDisplay} (+${otherProfessions.length}) `;
      }

      return primaryProfessionToDisplay;
    }

    if (otherProfessions.length > 0) {
      const otherProfessionsToDisplay = getProfessionName(first(otherProfessions));

      if (otherProfessions.length > 1) {
        return `${otherProfessionsToDisplay} (+${otherProfessions.length - 1}) `;
      }

      return otherProfessionsToDisplay;
    }
  }

  return get(artist, 'profession.name') || get(artist, 'summary');
};

export const getLongProfessionDetails = artist => {
  if (Array.isArray(artist?.professions) && artist?.professions?.length > 0) {
    const primaryProfession = artist?.professions?.filter(a => a.isPrimary) || [];
    const otherProfessions = artist?.professions?.filter(a => !a.isPrimary) || [];
    const professions = [...primaryProfession, ...otherProfessions].map(
      item => item?.profession.name || artist?.summary,
    );
    return professions;
  }

  if (artist?.profession) {
    return [artist?.profession.name || artist?.summary];
  }
  return [];
};
