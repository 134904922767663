import React from 'react';
import dynamic from 'next/dynamic';

import Drawer from 'components/uiLibrary/Drawer';
import { useMediaQuery } from '@mui/material';

import classes from './QuickView.module.scss';

const ProductionQuickView = dynamic(() => import('components/Productions/Display/QuickView'));

export const QUICK_VIEW_TYPES = {
  PRODUCTION: 'PRODUCTION',
};

const TYPE_TO_COMPONENT_MAP = {
  [QUICK_VIEW_TYPES.PRODUCTION]: ProductionQuickView,
};

const QuickView = ({ isOpen, hideQuickView, quickViewProps }) => {
  const isSmallScreen = useMediaQuery('(max-width: 768px)', { noSsr: true });

  if (!isOpen || !quickViewProps) return null;

  const { type, data } = quickViewProps;
  const Component = TYPE_TO_COMPONENT_MAP[type];

  return (
    <Drawer
      isOpen={isOpen}
      onClose={hideQuickView}
      placement={isSmallScreen ? 'bottom' : 'right'}
      className={isSmallScreen ? classes.mobileViewContainer : classes.desktopViewContainer}
      disableEnforceFocus
    >
      {Component && <Component onCloseQuickView={hideQuickView} {...data} />}
    </Drawer>
  );
};

export default QuickView;
