import { takeLatest, put, call, all } from 'redux-saga/effects';
import get from 'lodash/get';
import { TP, PRODUCTION_VALIDITY_IDENTIFIERS } from 'constants/index';
import { PRODUCTION_ADDED_IDENTIFIERS_IDS } from 'constants/deprecate';

import { getWithDefault } from 'utils/globals/app';
import {
  addAndUpdateContactsAction,
  getCompanyEditSeasonAction,
  getCompanyUnpublishedSeasonAction,
  updateOrgInfoAction,
  updateOrgVenueAction,
  updateOrgTranslationsAction,
  getOrgTranslationsAction,
  getCompanyReviewsAction,
  getCompanyProductionReviewsAction,
} from './actions';
// eslint-disable-next-line import/named
import { getProductions, create, updateOne, deleteOne } from '../../utils/API';
import { enqueueSnackbarAction } from '../App/actions';
import { ORGANIZATION, ORGANIZATION_ADDRESSES, ORGANIZATION_CONTACTS, VENUES } from '../../constants/endpointConstants';

import { i18n } from '../../src/i18n';
import * as API from '../../utils/API';

export function* fetchCompanyEditSeason({ payload, serverCookies }) {
  try {
    if (payload.companyId) {
      const productionsInSeason = yield call(
        getProductions,
        {
          sort: payload.sort || '-year',
          ...(payload?.query || {}),
          ...(payload.seasonId ? { season_id: payload.seasonId } : {}),
          company_id: payload.companyId,
          approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
          as_edit: true,
        },
        payload?.pagination || {},
        serverCookies,
      );
      yield put(getCompanyEditSeasonAction.success(get(productionsInSeason, 'data')));
    } else {
      yield put(getCompanyEditSeasonAction.success({ data: [] }));
    }
  } catch (err) {
    yield put(getCompanyEditSeasonAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_DATA`, { error: err?.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getCompanyEditSeasonAction.fulfill());
  }
}

export function* fetchCompanyUnpublishedProductions({ payload, serverCookies }) {
  try {
    const productionsInSeason = yield call(
      getProductions,
      {
        company_id: payload.companyId,
        published: false,
        sort: '-updated_at',
        as_edit: true,
        added_by: PRODUCTION_VALIDITY_IDENTIFIERS.COMPANY, // remove after api is ready for ownership
        for_entity: PRODUCTION_ADDED_IDENTIFIERS_IDS.COMPANY,
        for_entity_id: payload.companyId,
        ...(payload?.query || {}),
      },
      payload.pagination || {},
      serverCookies,
    );
    yield put(getCompanyUnpublishedSeasonAction.success(get(productionsInSeason, 'data')));
  } catch (err) {
    yield put(getCompanyUnpublishedSeasonAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_DATA`, { error: err?.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getCompanyUnpublishedSeasonAction.fulfill());
  }
}

export function* addAndUpdateContacts({ url, payload, serverCookies }) {
  const callback = get(payload, 'callback');
  const endpoint = url ?? ORGANIZATION_CONTACTS(payload?.orgId);

  try {
    const newContacts = getWithDefault(payload, 'newContacts', []);
    const updatingContacts = getWithDefault(payload, 'updatingContacts', []);
    const deletedContactsIds = getWithDefault(payload, 'deletedContactsIds', []);
    yield all([
      ...(newContacts?.length > 0 ? [call(create, endpoint, { data: newContacts }, null, serverCookies)] : []),
      ...(updatingContacts?.length > 0
        ? [call(updateOne, endpoint, updatingContacts[0]?.id, { data: updatingContacts }, null, serverCookies)]
        : []),
      ...deletedContactsIds.map(deletedContactId => call(deleteOne, endpoint, deletedContactId, {}, serverCookies)),
    ]);

    if (callback && typeof callback === 'function') payload.callback(true);
    yield put(addAndUpdateContactsAction.success());
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_UPDATING_CONTACTS`),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    if (callback && typeof callback === 'function') payload.callback(false);
    yield put(addAndUpdateContactsAction.failure());
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_UPDATING_CONTACTS`, {
          endpoint,
          error: err?.responseText ?? err?.message,
        }),
        options: {
          variant: 'error',
        },
      }),
    );
  }
}

export function* addAndUpdateOrgAddress({ payload, serverCookies }) {
  const callback = get(payload, 'callback');
  const endpoint = ORGANIZATION_CONTACTS(payload?.orgId);

  try {
    const newContacts = getWithDefault(payload, 'newContacts', []);
    const updatingContacts = getWithDefault(payload, 'updatingContacts', []);
    const deletedContactsIds = getWithDefault(payload, 'deletedContactsIds', []);
    yield all([
      ...(newContacts?.length > 0 ? [call(create, endpoint, { data: newContacts }, null, serverCookies)] : []),
      ...(updatingContacts?.length > 0
        ? [call(updateOne, endpoint, updatingContacts[0]?.id, { data: updatingContacts }, null, serverCookies)]
        : []),
      ...deletedContactsIds.map(deletedContactId => call(deleteOne, endpoint, deletedContactId, {}, serverCookies)),
    ]);

    if (callback && typeof callback === 'function') payload.callback(true);
    yield put(addAndUpdateContactsAction.success());
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_UPDATING_CONTACTS`),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    if (callback && typeof callback === 'function') payload.callback(false);
    yield put(addAndUpdateContactsAction.failure());
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_UPDATING_CONTACTS`, {
          endpoint,
          error: err?.responseText ?? err?.message,
        }),
        options: {
          variant: 'error',
        },
      }),
    );
  }
}

export function* updateOrgVenue({ payload, serverCookies }) {
  const callback = get(payload, 'callback');
  const venues = get(payload, 'venues', []);
  try {
    yield all(venues?.map(v => call(updateOne, VENUES, v?.id, v, null, serverCookies)));
    if (callback && typeof callback === 'function') {
      payload.callback();
    }
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_UPDATING_VENUE`),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_UPDATING_VENUE`, { error: err?.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  }
}

export function* updateOrgInfo({ payload, serverCookies }) {
  const callback = get(payload, 'callback');
  const orgId = payload?.orgId;

  try {
    yield all([
      call(updateOne, ORGANIZATION, orgId, payload?.companyInfo, null, serverCookies),
      call(addAndUpdateContacts, {
        url: ORGANIZATION_ADDRESSES(orgId),
        payload: {
          orgId,
          newContacts:
            payload?.address?.newContacts?.map(newContact => ({ ...newContact, address: newContact?.value })) || [],
          updatingContacts:
            payload?.address?.updatedContacts?.map(newContact => ({
              ...newContact,
              address: newContact?.value,
            })) || [],
          deletedContactsIds: payload?.address?.deletedContacts || [],
        },
      }),
      call(addAndUpdateContacts, {
        url: ORGANIZATION_CONTACTS(orgId),
        payload: {
          orgId,
          newContacts: payload?.newContacts || [],
          updatingContacts: payload?.updatedContacts || [],
          deletedContactsIds: payload?.deletedContacts || [],
        },
      }),
    ]);

    if (callback && typeof callback === 'function') payload.callback(true);
  } catch (err) {
    if (callback && typeof callback === 'function') payload.callback(false);
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_UPDATING_ORG_INFO`, { error: err?.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  }
}

export function* updateOrgTranslations({ payload, serverCookies }) {
  const callback = get(payload, 'callback');

  try {
    const translations = yield call(API.updateOrgTranslations, payload?.orgId, payload?.translations, serverCookies);
    yield put(getOrgTranslationsAction.success(get(translations, 'data')));

    if (callback && typeof callback === 'function') payload.callback(true);
  } catch (err) {
    if (callback && typeof callback === 'function') payload.callback(false);
    yield put(
      enqueueSnackbarAction({
        message: `Error updating organization name: ${err?.message}`,
        options: {
          variant: 'error',
        },
      }),
    );
  }
}

export function* fetchCompanyReviews({ payload, serverCookies }) {
  const { url, pagination, query } = payload;
  try {
    const response = yield call(API.getReviewsV1, url, pagination, query ?? {}, serverCookies);
    yield put(getCompanyReviewsAction.success(get(response, 'data')));
  } catch (err) {
    yield put(getCompanyReviewsAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: err?.message,
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getCompanyReviewsAction.fulfill());
  }
}

export function* fetchCompanyProductionReviews({ payload, serverCookies }) {
  const { url, pagination, query } = payload;
  try {
    const response = yield call(API.getReviewsV1, url, pagination, query ?? {}, serverCookies);
    yield put(getCompanyProductionReviewsAction.success(get(response, 'data')));
  } catch (err) {
    yield put(getCompanyProductionReviewsAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: err?.message,
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getCompanyProductionReviewsAction.fulfill());
  }
}

export default function* organizationSaga() {
  yield takeLatest(getCompanyEditSeasonAction.TRIGGER, fetchCompanyEditSeason);
  yield takeLatest(getCompanyUnpublishedSeasonAction.TRIGGER, fetchCompanyUnpublishedProductions);
  yield takeLatest(addAndUpdateContactsAction.TRIGGER, addAndUpdateContacts);
  yield takeLatest(updateOrgInfoAction.TRIGGER, updateOrgInfo);
  yield takeLatest(updateOrgVenueAction.TRIGGER, updateOrgVenue);
  yield takeLatest(updateOrgTranslationsAction.TRIGGER, updateOrgTranslations);
  yield takeLatest(getCompanyReviewsAction.TRIGGER, fetchCompanyReviews);
  yield takeLatest(getCompanyProductionReviewsAction.TRIGGER, fetchCompanyProductionReviews);
}
