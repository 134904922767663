import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import range from 'lodash/range';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';

import { getProductionProducedBy } from 'utils/productions';
import {
  CONTACT_TYPES,
  PARTNER_TYPE,
  GENERAL_MANAGEMENT_AGREEMENT_TYPE,
  SORT_OPTION_VALUES,
  CONTACT_TYPE,
} from 'constants/index';

import { getAgreementCountries } from '../agency';

export const getProfileProfession = profile => {
  let primaryProfession = profile?.professions?.find(prof => prof?.isPrimary);
  primaryProfession = [primaryProfession?.profession?.name];
  const secProfessions = profile?.professions
    ?.filter(prof => !prof?.isPrimary)
    ?.reduce((result, prof) => [...result, prof?.profession?.name], []);

  let profileProfession = [];

  if (primaryProfession?.length > 0) {
    profileProfession = primaryProfession;
  }

  if (secProfessions?.length > 0) {
    profileProfession.push(...secProfessions);
  }

  return profileProfession.join(', ');
};

export const isRepertoireValid = repertoire =>
  !repertoire?.isFuture && repertoire?.productionsTotal > 0 && repertoire?.work?.id !== -1;

// TODO: we need to change response structure or api from backend side
export const groupProductionsByRole = (records, productionsGrouped) => {
  const uniqueYearsList = new Set();
  const groupByWorks = groupBy(records, 'work.id');
  const worksStructured = {};
  let totalCount = 0;
  map(Object.keys(groupByWorks), workID => {
    const works = groupByWorks[workID];
    let productionLatestDate = null;
    const validRoles = works.filter(role => {
      if (role.productionsTotal) {
        const workRole = role;
        const groupByMinDate = {};

        map(workRole.productionIds, id => {
          const production = productionsGrouped?.[id]?.[0];
          if (production?.minDate) {
            const prod = production;
            prod.partnersCount = getProductionPartnersCount(production);
            const minDate = new Date(production?.minDate);
            if (productionLatestDate < minDate) {
              productionLatestDate = minDate;
            }
            uniqueYearsList.add(production?.years?.sort()?.[0]);
            if (!groupByMinDate[minDate]) {
              groupByMinDate[minDate] = [prod];
            } else {
              groupByMinDate[minDate].push(prod);
            }
          }
        });

        const productionDatesInSortedOrder = orderBy(Object.keys(groupByMinDate), minDate => new Date(minDate), 'desc');
        const groupByYear = {};
        const productionsList = [];
        map(productionDatesInSortedOrder, minDate => {
          groupByYear[new Date(minDate).getFullYear()] = groupByMinDate[minDate];
          productionsList.push(...groupByMinDate[minDate]);
        });
        workRole.groupByYear = groupByYear;
        workRole.productions = productionsList;
        workRole.minDate = productionDatesInSortedOrder?.[0];
        return workRole;
      }
      return false;
    });
    totalCount += validRoles?.length;
    worksStructured[productionLatestDate] = { ...worksStructured[productionLatestDate] };
    worksStructured[productionLatestDate][workID] = orderBy(validRoles, role => new Date(role?.minDate), 'desc');
  });
  // const sortedProductionGroup = sortProductionYearWise(worksStructured);
  const sortedKeys = orderBy(Object.keys(worksStructured), year => new Date(year), 'desc');
  const sortedResult = [];

  map(sortedKeys, item => {
    sortedResult.push(...Object.values(worksStructured?.[item]));
  });

  return {
    years: getProductionsTenureList(uniqueYearsList),
    totalCount,
    data: sortedResult,
  };
};

export const groupProductionsByProducers = (data, productionsGrouped) => {
  const sponsorsGroup = {};
  const uniqueYearsList = new Set();

  map(data, r => {
    const { productionIds } = r;
    map(productionIds, productionId => {
      const p = productionsGrouped?.[productionId]?.[0];
      const producerDetails = getProductionProducedBy(p);
      const coPartners = getProductionPartners(p);
      // in case if there is no producers then pick the first co producer as producer
      const key = producerDetails?.id || coPartners?.id;
      if (key) {
        if (!sponsorsGroup[key]) {
          sponsorsGroup[key] = {
            productions: [],
            ...producerDetails,
            coPartners,
          };
        }
        const obj = sponsorsGroup[key];
        obj.productions.push({ ...p });
        sponsorsGroup[key] = obj;
      }
    });
  });

  const yearProductionsGroup = {};
  map(Object.keys(sponsorsGroup), key => {
    // in case of multiple production get the oldest produced production date
    const { productions, name: producerName, city, country, coPartners } = sponsorsGroup[key];
    if (productions?.length) {
      let productionLatestDate = null;
      // group production according to year in which they were performed
      const groupByMinDate = {};
      map(productions, production => {
        if (production?.minDate) {
          const minDate = new Date(production?.minDate);
          if (productionLatestDate < minDate) {
            productionLatestDate = minDate;
          }
          uniqueYearsList.add(production?.years?.sort()?.[0]);
          if (!groupByMinDate[minDate]) {
            groupByMinDate[minDate] = [production];
          } else {
            groupByMinDate[minDate].push(production);
          }
        }
      });
      const productionDatesInSortedOrder = orderBy(Object.keys(groupByMinDate), minDate => new Date(minDate), 'desc');
      const groupByYear = {};
      const productionsList = [];
      map(productionDatesInSortedOrder, minDate => {
        groupByYear[new Date(minDate).getFullYear()] = groupByMinDate[minDate];
        productionsList.push(...groupByMinDate[minDate]);
      });

      yearProductionsGroup[productionLatestDate] = { ...yearProductionsGroup[productionLatestDate] };
      yearProductionsGroup[productionLatestDate][key] = {
        groupByYear,
        producerName,
        city,
        country,
        coPartners,
        productions: productionsList,
      };
    }
  });

  const sortedKeys = orderBy(Object.keys(yearProductionsGroup), year => new Date(year), 'desc');
  const sortedResult = [];
  map(sortedKeys, item =>
    Object.values(yearProductionsGroup?.[item])?.map(production => sortedResult.push([production])),
  );

  return {
    data: sortedResult,
    totalCount: sortedResult?.length,
    years: getProductionsTenureList(uniqueYearsList),
  };
};

const getProductionsTenureList = uniqueYearsList => {
  const sortedYearList = Array.from(uniqueYearsList).sort();
  const minYear = sortedYearList[0];
  const maxYear = sortedYearList[sortedYearList.length - 1];
  return sortedYearList?.length > 1 ? range(minYear, maxYear, 1) : sortedYearList;
};

const getProductionPartnersCount = production => {
  const coProducedByCompanies = partition(
    production?.companies,
    company => company?.partnerType === PARTNER_TYPE.CO_PRODUCER,
  );
  const coProducedByFestivals = partition(
    production?.festivals,
    festival => festival?.partnerType === PARTNER_TYPE.CO_PRODUCER,
  );
  return coProducedByCompanies?.[0]?.length + coProducedByFestivals?.[0]?.length;
};

const getProductionPartners = production => {
  const guestProducers = [];

  const coProducedByCompanies = partition(production?.companies, company => {
    if (company?.partnerType === PARTNER_TYPE.GUEST) {
      guestProducers.push(company);
    }
    return company?.partnerType === PARTNER_TYPE.CO_PRODUCER;
  });

  if (coProducedByCompanies?.[0]?.length) {
    return coProducedByCompanies?.[0];
  }

  const coProducedByFestivals = partition(production?.festivals, festival => {
    if (festival?.partnerType === PARTNER_TYPE.GUEST) {
      guestProducers.push(festival);
    }
    return festival?.partnerType === PARTNER_TYPE.CO_PRODUCER;
  });

  if (coProducedByFestivals?.[0]?.length) {
    return coProducedByFestivals?.[0];
  }

  if (guestProducers?.[0]?.length) {
    return guestProducers?.[0];
  }

  return {};
};

export const agencyRowMapping = artistAgreements => {
  const { agencies: agenciesRaw = [], agreements: agreementsRaw = [] } = artistAgreements || {};

  const agreements = agreementsRaw?.map(item => ({
    ...item,
    groupKey: `${item?.agreementType}_${item?.exclusive ? 'E' : 'NE'}`,
    ...getAgreementCountries(item),
  }));

  const sortedAgencies = sortArtistAgencies(filter(agenciesRaw, item => item?.agency));

  const agencies = [];

  sortedAgencies.map(agency => {
    const agencyConnectionProfession = agency?.agencyConnectionProfessions?.reduce(
      (acc, item) => ({ ...acc, [item.id]: item }),
      {},
    );

    const agencyAgreements = agreements.filter(a => a?.connection?.id === agency?.id);

    const professionAgreementsGrouped = groupBy(
      agencyAgreements,
      agreement => agreement?.agencyConnectionProfession?.id,
    );

    let isGM = false;
    const professionAgreements = Object.keys(agencyConnectionProfession).map(professionId => {
      const professionAgreementsList = professionAgreementsGrouped?.[professionId] || [];
      const contacts = getAgentContactsInfo(professionAgreementsList, agency);
      isGM = !!professionAgreementsList?.find(item => item?.agreementType === GENERAL_MANAGEMENT_AGREEMENT_TYPE);

      return {
        profession: agencyConnectionProfession?.[professionId],
        agreements: groupBy(professionAgreementsList, agreement => agreement?.groupKey),
        contacts,
      };
    });

    if (isGM) {
      agencies.unshift({
        agency: { ...agency, isGM, contactPerson: agreements?.[0]?.contactPersons?.[0] },
        professionAgreements,
      });
    } else {
      agencies.push({
        agency: { ...agency, isGM, contactPerson: agreements?.[0]?.contactPersons?.[0] },
        professionAgreements,
      });
    }
    return agencies;
  });

  return { data: agencies };
};

export const getArtistProfessionName = workInfo =>
  workInfo?.workRole?.name || workInfo?.workRole?.reference || workInfo?.profession?.name;

export const getProfileName = profile => {
  if (profile?.fullName) {
    return profile?.fullName;
  }

  let artistFirstAndLastName = '';

  if (profile?.firstName) {
    artistFirstAndLastName += profile?.firstName;
  }
  if (profile?.lastName) {
    if (artistFirstAndLastName?.length > 0) {
      artistFirstAndLastName += ' ';
    }

    artistFirstAndLastName += profile?.lastName;
  }

  return artistFirstAndLastName;
};

export const getAgentContactsInfo = (agreements, agency) => {
  const contactPersons = {};

  groupBy(
    agreements?.map(agreement => {
      map(agreement?.contactPersons, contact => {
        const emailContactInfo = [];
        const phoneContactInfo = [];

        if (contact?.agentEmail) {
          emailContactInfo.push({
            contactType: { id: CONTACT_TYPES.EMAIL, slug: CONTACT_TYPE.EMAIL },
            name: 'email',
            value: contact?.agentEmail,
          });
        }

        if (contact?.agentPhoneNumber) {
          phoneContactInfo.push({
            contactType: { id: CONTACT_TYPES.PHONE, slug: CONTACT_TYPE.PHONE },
            name: 'phone',
            value: contact?.agentPhoneNumber,
          });
        }
        contactPersons[contact.id] = {
          name: contact?.agentName,
          emailContactInfo,
          phoneContactInfo,
        };
      });

      return {
        ...agreement,
        groupKey: `${agreement?.agreementType}_${agreement?.exclusive ? 'E' : 'NE'}`,
      };
    }),
    agreement => agreement?.groupKey,
  );

  if (!Object.keys(contactPersons).length) {
    contactPersons[agency.agency.id] = {
      name: '',
      ...agency?.agency?.contacts?.reduce(
        (acc, contact) => {
          if (contact.contactType?.id === CONTACT_TYPES.EMAIL) {
            acc.emailContactInfo.push(contact);
          } else if (contact.contactType?.id === CONTACT_TYPES.PHONE) {
            acc.phoneContactInfo.push(contact);
          }
          return acc;
        },
        { emailContactInfo: [], phoneContactInfo: [] },
      ),
    };
  }
  return contactPersons;
};

export const sortArtistAgencies = (agencies = []) => {
  const sortedAgencies = agencies?.sort((currentAgency, nextAgency) =>
    currentAgency?.agency?.name?.toLowerCase() > nextAgency?.agency?.name?.toLowerCase() ? 1 : -1,
  );
  const { generalManagers, otherAgencies } = sortedAgencies?.reduce(
    (acc, agency) => {
      if (agency?.isGeneralManager) {
        acc.generalManagers.push(agency);
      } else {
        acc.otherAgencies.push(agency);
      }

      return acc;
    },
    {
      generalManagers: [],
      otherAgencies: [],
    },
  );

  return [...generalManagers, ...otherAgencies];
};

export const getArtistIndexQueryParams = ({
  professions,
  voiceTypes = [],
  instrumentTypes = [],
  paths,
  queryParams,
  getSelectedProfession = false,
}) => {
  let matchedProfessionId;
  let matchedQualificationId;
  const matchedQualification = [...voiceTypes, ...instrumentTypes]?.find(option => option?.slug === paths?.[0]);
  const matchedProfession = professions?.find(option => option?.slug === paths?.[0]);

  if (paths?.length === 1) {
    matchedQualificationId = matchedQualification?.id;
    if (!matchedQualificationId) {
      matchedProfessionId = matchedProfession?.id;
    }
  }

  return {
    ...(queryParams?.query && { query: queryParams?.query }),
    ...(matchedProfessionId && { primary_profession_id: matchedProfessionId }),
    ...(matchedQualificationId && { primary_profession_id: matchedQualificationId }),
    ...(queryParams?.letter && { letter: queryParams?.letter }),
    ...(queryParams?.page && { page: queryParams?.page }),
    ...(getSelectedProfession && {
      selectedProfession: matchedQualification?.id ? matchedQualification : matchedProfession,
    }),
    sort: `${SORT_OPTION_VALUES.FIRST_NAME},${SORT_OPTION_VALUES.LAST_NAME}`,
  };
};

export const randomizedProfessionsList = ({ professions = [], voiceTypes = [], instrumentTypes = [] }) => {
  const contactArtistData = [...professions, ...voiceTypes, ...instrumentTypes];

  const mapArtistData = contactArtistData?.reduce((acc, data) => {
    if (data?.pro < 8) {
      return acc;
    }

    acc.push({
      ...(data?.profession?.id ? { profession_qualification_id: data?.id } : { profession_id: data?.id }),
      ...data,
    });

    return acc;
  }, []);

  return mapArtistData?.sort(() => Math.random() - 0.5);
};
export const getSelectedProfession = ({ professions, voiceTypes, instrumentTypes, paths }) => {
  if (paths?.length !== 1) return null;

  const findOptionBySlug = (options, type) => {
    const option = options.find(item => item?.slug === paths[0]);
    return option ? { ...option, type } : null;
  };

  return (
    findOptionBySlug(voiceTypes, 'voiceType') ||
    findOptionBySlug(instrumentTypes, 'instrumentType') ||
    findOptionBySlug(professions, 'profession')
  );
};

export const getPrimaryProfession = (artist, profession) => {
  const matchingProfessionName = artist?.professions?.find(p => p?.profession?.id === profession?.id)?.profession;
  const additionalProfessionsCount = artist?.professions?.length > 1 ? `(+${artist?.professions?.length - 1})` : '';
  return `${matchingProfessionName?.name} ${additionalProfessionsCount}`;
};

export const sortAndMapProfessions = (data, filterFunc = () => true) =>
  sortBy(data?.filter(filterFunc), 'name')?.map(item => ({
    ...item,
    value: item?.name,
    label: item?.name,
  }));

export const sortProfessions = options =>
  [...(options || [])].sort(({ total: aTotal }, { total: bTotal }) => (aTotal > bTotal ? -1 : 1));
