import { takeLatest, put, call } from 'redux-saga/effects';
import find from 'lodash/find';
import get from 'lodash/get';
import merge from 'lodash/merge';
import { getSeasonWithProductionsAction } from './actions';
import { getListSaga } from '../App/saga';
import * as API from '../../utils/API';

export function* getSeasonCompaniesByCountrySlugSaga({ payload, serverCookies }) {
  const { sagaRoutine, countrySlug } = payload;
  if (sagaRoutine === null) {
    throw new Error('Specify sagaRoutine');
  }
  try {
    const countries = yield call(API.getCountries, null, { slug: countrySlug }, serverCookies);
    const selectedCountry = find(get(countries, 'data.data', []), country => get(country, 'slug') === countrySlug);
    const payloadWithCountry = merge(payload, { params: { queryParams: { country_id: get(selectedCountry, 'id') } } });
    yield call(getListSaga, { payload: payloadWithCountry, serverCookies });
  } catch (err) {
    yield put(sagaRoutine.failure(err.message));
  }
}

export function* getSeasonWithProductionsSaga({ payload, serverCookies }) {
  yield call(getListSaga, { payload, serverCookies });
}

export default function* seasonsSaga() {
  yield takeLatest(getSeasonWithProductionsAction.TRIGGER, getSeasonWithProductionsSaga);
  // yield takeLatest(getSeasonCompaniesByCountrySlugAction.TRIGGER, getSeasonCompaniesByCountrySlugSaga);
}
