import { createRoutine } from 'redux-saga-routines';
import {
  UPLOAD_VENUE_IMAGES,
  GET_COUNTRIES,
  CLEAN_STORE,
  GET_MEDIA,
  DELETE_MEDIA,
  GET_IMAGE_FORMATS,
} from './constants';

export const uploadVenueImagesAction = createRoutine(UPLOAD_VENUE_IMAGES);
export const getCountriesAction = createRoutine(GET_COUNTRIES);
export const cleanVenueModalStoreAction = createRoutine(CLEAN_STORE);
export const getMediaAction = createRoutine(GET_MEDIA);
export const deleteMediaAction = createRoutine(DELETE_MEDIA);
export const getImageFormats = createRoutine(GET_IMAGE_FORMATS);
