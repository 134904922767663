import { createRoutine } from 'redux-saga-routines';
import {
  GET_PRODUCTIONS,
  CLEAR_PRODUCTIONS,
  GET_WORKS_LIST,
  GET_WORKS_ROLES_LIST,
  CLEAN_WORKS_ROLES_LIST,
  CLEAN_WORKS_LIST,
  GET_PRODUCTIONS_COUNTRIES,
  SET_CAST_WORKS_AGGREGATIONS,
  GET_ROLES,
  GET_PROFESSION_ROLES,
  GET_PROFESSION_ROLE_LIST,
} from './constants';

export const getCastProductionsAction = createRoutine(GET_PRODUCTIONS);
export const clearCastProductionsAction = createRoutine(CLEAR_PRODUCTIONS);
export const getCastWorksListAction = createRoutine(GET_WORKS_LIST);
export const getCastWorksRolesListAction = createRoutine(GET_WORKS_ROLES_LIST);
export const cleanCastWorksRolesListAction = createRoutine(CLEAN_WORKS_ROLES_LIST);
export const cleanCastWorksListAction = createRoutine(CLEAN_WORKS_LIST);
export const getProductionCountriesAction = createRoutine(GET_PRODUCTIONS_COUNTRIES);
export const setCastWorksAggregation = createRoutine(SET_CAST_WORKS_AGGREGATIONS);
export const getRolesAction = createRoutine(GET_ROLES);
export const getProfessionRolesAction = createRoutine(GET_PROFESSION_ROLES);
export const getProfessionRoleListAction = createRoutine(GET_PROFESSION_ROLE_LIST);
