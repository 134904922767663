import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { getAgenciesByCountryAction, getAgenciesList } from './actions';
// eslint-disable-next-line import/named
import { getAgencies } from '../../utils/API';

export function* fetchAgenciesByCountry({ payload, serverCookies }) {
  try {
    const selectedCountry = get(payload, 'country');
    const response = yield call(
      getAgencies,
      {
        country_id: get(selectedCountry, 'id'),
      },
      { limit: 12, ...get(payload, 'pagination', {}) },
      serverCookies,
    );

    yield put(getAgenciesByCountryAction.success({ data: get(response, 'data'), country: selectedCountry }));
  } catch (err) {
    yield put(getAgenciesByCountryAction.failure(err.message));
  } finally {
    yield put(getAgenciesByCountryAction.fulfill());
  }
}

export function* fetchAgenciesList({ payload, serverCookies }) {
  try {
    const response = yield call(
      getAgencies,
      get(payload, 'query', {}),
      {
        limit: 12,
        ...get(payload, 'pagination', {}),
      },
      serverCookies,
    );
    yield put(getAgenciesList.success(get(response, 'data')));
  } catch (err) {
    yield put(getAgenciesList.failure(err.message));
  } finally {
    yield put(getAgenciesList.fulfill());
  }
}

export default function* dataAgencies() {
  yield takeLatest(getAgenciesByCountryAction.TRIGGER, fetchAgenciesByCountry);
  yield takeLatest(getAgenciesList.TRIGGER, fetchAgenciesList);
}
