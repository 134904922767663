import { getList, getWholeList } from 'utils/API';
import { getLanguage } from 'utils/queriesUtil';
import {
  ORGANIZATIONS,
  ORGANIZATION_ABOUT,
  ORGANIZATION_RELATIONS,
  ORGANIZATION_EMPLOYEES,
} from 'constants/endpointConstants';
import { VALIDATION_STATUS } from 'constants/consts';
import { CONTRIBUTORS, ENTITY_TYPE } from 'constants/index';
import { transformEntity } from 'utils/globals';
import { getQuery } from 'utils/globals/queries';

const defaultConfig = {
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 10 * 60 * 1000,
  cacheTime: 10000,
};

const fetchOrganizations = ({ filters, limit = 10, page }, cookies) => {
  const params = {
    queryParams: filters,
    pagination: {
      limit,
      page,
    },
  };

  return getList(ORGANIZATIONS, params, cookies).then(response => response?.data);
};

const fetchOrganization = ({ id, showApprovedHidden = false, asEdit = false }, cookies) => {
  const validationStatus = [VALIDATION_STATUS.APPROVED, VALIDATION_STATUS.PENDING];

  if (showApprovedHidden) {
    validationStatus.push(VALIDATION_STATUS.APPROVED_HIDDEN);
  }

  const params = {
    queryParams: {
      id,
      validation_status: validationStatus,
      ...(asEdit && { as_edit: true }),
    },
    pagination: {
      limit: 1,
      page: 1,
    },
  };

  return getList(ORGANIZATIONS, params, cookies).then(response =>
    transformEntity(response?.data?.data?.[0], ENTITY_TYPE.ORGANIZATION),
  );
};

const fetchProductionRentalContacts = ({ entity }, cookies) => {
  const producerId = entity?.contributions?.find(({ contributionType }) => contributionType === CONTRIBUTORS.PRODUCER)
    ?.organization?.id;

  if (producerId === -1) {
    return null;
  }

  const params = {
    queryParams: { is_rental_contact: true },
  };

  return getWholeList(`organizations/${producerId}/employees`, params, cookies).then(response => response?.data);
};

const fetchTranslations = ({ id }, cookies) =>
  getList(ORGANIZATION_ABOUT(id), {}, cookies).then(response => response?.data);

const fetchAssociatedOrganizations = ({ id }, cookies) =>
  getList(ORGANIZATION_RELATIONS(id), {}, cookies).then(response => response?.data);

const fetchManagementAndStaff = ({ id, cookies }) =>
  getList(ORGANIZATION_EMPLOYEES(id), {}, cookies).then(response => response?.data);

const queries = {
  getOrganizations: getQuery('GET_ORGANIZATIONS', fetchOrganizations),
  getOrganizationDetails: getQuery('GET_ORGANIZATION_DETAILS', fetchOrganization),
  getTranslations: getQuery('GET_ABOUT_ORGANIZATION', fetchTranslations),
  getOrgList: ({ entityType, queryParams, queryConfig = {} }, cookies, language) => {
    const key = entityType === ENTITY_TYPE.COMPANY ? 'GET_COMPANY_LIST' : 'GET_FESTIVAL_LIST';
    const pagination = { limit: 20 };
    const query = {
      queryParams,
    };
    return {
      queryKey: [getLanguage(language), key, queryParams],
      queryFn: async () => getList(ORGANIZATIONS, query, pagination, cookies),
      ...defaultConfig,
      ...queryConfig,
    };
  },
  getProductionRentalContacts: getQuery('GET_RENTAL_CONTACTS', fetchProductionRentalContacts),
  getAssociatedOrganizations: getQuery('GET_ASSOCIATED_ORGANIZATION', fetchAssociatedOrganizations),
  getManagementAndStaff: getQuery('GET_MANAGEMENT_STAFF', fetchManagementAndStaff),
};

export default queries;
