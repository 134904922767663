import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FAILURE, SUCCESS, TRIGGER } from 'redux-saga-routines/dist/routineStages';

/**
 * Contains generic methods for the CRUD operations
 */

export const defaultPagination = {
  page: 1,
  limit: 10,
  total: 0,
};

export const defaultListParams = {
  ...defaultPagination,
  criteria: {},
  filters: [],
  data: [],
  loading: false,
  loadedOnce: false,
  error: null,
  actualTotal: null,
  meta: {},
};

export const namedListParams = listName => ({
  ...defaultListParams,
  listName,
});

export const defaultEntityParams = {
  entity: {},
  loading: false,
  loadedOnce: false,
  error: null,
};

export const defaultMediaParams = {
  uploading: false,
  uploaded: false,
  error: null,
  data: null,
};

export const namedEntityParams = entityName => ({
  ...defaultEntityParams,
  entityName,
});

const listPropTypes = {
  page: PropTypes.number,
  limit: PropTypes.number,
  total: PropTypes.number,
  criteria: PropTypes.object,
  filters: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  loadedOnce: PropTypes.bool,
};

export const defaultListPropType = PropTypes.shape(listPropTypes);

export const formatResponseToList = response => ({
  page: get(response, 'data.page', defaultListParams.page),
  limit: get(response, 'data.limit', defaultListParams.limit),
  total: get(response, 'data.total', defaultListParams.total),
  criteria: get(response, 'data.criteria', defaultListParams.criteria),
  filters: get(response, 'data.filters', defaultListParams.filters),
  data: get(response, 'data.data', defaultListParams.data),
  actualTotal: get(response, 'data.actualTotal', defaultListParams.actualTotal),
  meta: get(response, 'data.meta', defaultListParams.meta),
});

export const formatResponseToEntity = response => ({
  entity: get(response, 'data', defaultEntityParams.entity),
});

export const formatListResponseToEntity = response => ({
  entity: get(response, 'data.data[0]', defaultEntityParams.entity),
});

export function useList(list) {
  return [
    get(list, 'data', []),
    {
      page: get(list, 'page', 1),
      limit: get(list, 'limit', 10),
      total: get(list, 'total', 0),
    },
    get(list, 'filters', []),
    get(list, 'loading'),
  ];
}

const generalReducer = (state, type, payload, listName) => {
  const routingStage = type.split('/').pop();
  switch (routingStage) {
    case TRIGGER:
      return { ...state, [listName]: { ...state[listName], loading: true, error: null } };
    case SUCCESS:
      return { ...state, [listName]: { ...state[listName], ...payload, loading: false, loadedOnce: true } };
    case FAILURE:
      return { ...state, [listName]: { ...state[listName], loading: false, error: payload } };
    default:
      return state;
  }
};

export const listReducer = (state, type, payload, listName) => generalReducer(state, type, payload, listName);

export const entityReducer = (state, type, payload, fieldName) => generalReducer(state, type, payload, fieldName);
