import {
  TP,
  ENTITY_TYPE,
  ENTITY_MAIN_TABS,
  URL_SLUG_TYPES,
  VIEW_MODES_VALUE,
  AGGREGATION_TYPES,
} from 'constants/index';

export const BOOLEAN_SEARCH_PARTS = {
  QUERY: 'QUERY',
  OPERATOR: 'OPERATOR',
};

export const BOOLEAN_OPERATORS = {
  AND: ';',
  OR: '|',
  NOT: '!',
  OPEN_BRACKET: '(',
  CLOSE_BRACKET: ')',
};

export const BOOLEAN_OPERATOR_LABELS = {
  [BOOLEAN_OPERATORS.AND]: `${TP}.FN_BOOLEAN_OPERATOR_AND`,
  [BOOLEAN_OPERATORS.OR]: `${TP}.FN_BOOLEAN_OPERATOR_OR`,
  [BOOLEAN_OPERATORS.NOT]: `${TP}.FN_BOOLEAN_OPERATOR_NOT`,
  [BOOLEAN_OPERATORS.OPEN_BRACKET]: '(',
  [BOOLEAN_OPERATORS.CLOSE_BRACKET]: ')',
};

export const BOOLEAN_OPERATOR_PRECEDENCE = {
  [BOOLEAN_OPERATORS.OPEN_BRACKET]: 0,
  [BOOLEAN_OPERATORS.CLOSE_BRACKET]: 0,
  [BOOLEAN_OPERATORS.OR]: 1,
  [BOOLEAN_OPERATORS.AND]: 2,
  [BOOLEAN_OPERATORS.NOT]: 3,
};

export const BOOLEAN_OPERATORS_VALUES = Object.values(BOOLEAN_OPERATORS);
export const BOOLEAN_OPERATOR_REGEX = new RegExp(`([${BOOLEAN_OPERATORS_VALUES.join('')}])`, 'g');

export const FILTER_TYPES = {
  PRODUCTION_PERIOD: 'PRODUCTION_PERIOD',
  CO_CAST: 'CO_CAST',
  PROFILE: 'PROFILE',
  DATE: 'DATE',
  CITY: 'CITY',
  CONDUCTOR: 'CONDUCTOR',
  DIRECTOR: 'DIRECTOR',
  COUNTRY: 'COUNTRY',
  VENUE: 'VENUE',
  CREATOR: 'CREATOR',
  WORK: 'WORK',
  LANGUAGE: 'LANGUAGE',
  SURTITLE: 'SURTITLE',
  VIDEO_TYPE: 'VIDEO_TYPE',
  REPERTOIRE_STATUS: 'REPERTOIRE_STATUS',
  PUBLISHER: 'PUBLISHER',
  PRODUCTION: 'PRODUCTION',
  VIEW_MODE: 'VIEW_MODE',
  BOOLEAN_SEARCH: 'BOOLEAN_SEARCH',
  YEAR: 'YEAR',
  SINCE_YEAR: 'SINCE_YEAR',
  SEASON: 'SEASON',
  ORGANIZATION: 'ORGANIZATION',
  COMPANY: 'COMPANY',
  FESTIVAL: 'FESTIVAL',
  ORCHESTRA: 'ORCHESTRA',
  CHORUS: 'CHORUS',
  CAST_CREW: 'CAST_CREW',
  GENRE: 'GENRE',
  PROFESSION: 'PROFESSION',
};

export const CASTING_TOOL_FILTERS = [
  FILTER_TYPES.CONDUCTOR,
  FILTER_TYPES.DIRECTOR,
  FILTER_TYPES.CAST_CREW,
  FILTER_TYPES.COMPANY,
  FILTER_TYPES.FESTIVAL,
  FILTER_TYPES.ORCHESTRA,
  FILTER_TYPES.CHORUS,
  FILTER_TYPES.LANGUAGE,
  FILTER_TYPES.CITY,
  FILTER_TYPES.VENUE,
];

export const DISABLED_FILTER_TYPES = {
  [ENTITY_MAIN_TABS.REPERTOIRE]: [
    FILTER_TYPES.CONDUCTOR,
    FILTER_TYPES.DIRECTOR,
    FILTER_TYPES.CAST_CREW,
    FILTER_TYPES.COMPANY,
    FILTER_TYPES.FESTIVAL,
    FILTER_TYPES.ORCHESTRA,
    FILTER_TYPES.CHORUS,
    FILTER_TYPES.LANGUAGE,
    FILTER_TYPES.CITY,
    FILTER_TYPES.VENUE,
  ],
  [ENTITY_MAIN_TABS.ORG_GRAPH]: [
    FILTER_TYPES.CONDUCTOR,
    FILTER_TYPES.DIRECTOR,
    FILTER_TYPES.CAST_CREW,
    FILTER_TYPES.ORCHESTRA,
    FILTER_TYPES.CHORUS,
  ],
  [ENTITY_MAIN_TABS.ROLE_GRAPH]: [
    FILTER_TYPES.CONDUCTOR,
    FILTER_TYPES.DIRECTOR,
    FILTER_TYPES.CAST_CREW,
    FILTER_TYPES.ORCHESTRA,
    FILTER_TYPES.CHORUS,
  ],
};

// TODO: To be lokalized
export const FILTER_LABELS = {
  [FILTER_TYPES.DATE]: 'Date',
  [FILTER_TYPES.CITY]: `${TP}.m_ASKLOCATION`,
  [FILTER_TYPES.COUNTRY]: 'Countries',
  [FILTER_TYPES.ORGANIZATION]: `${TP}.FN_ORG`,
  [FILTER_TYPES.COMPANY]: `${TP}.FN_COMPANY`,
  [FILTER_TYPES.FESTIVAL]: `${TP}.FN_FESTIVAL`,
  [FILTER_TYPES.ORCHESTRA]: `${TP}.m_FN_ORCHESTRA`,
  [FILTER_TYPES.CHORUS]: `${TP}.m_FN_CHORUS`,
  [FILTER_TYPES.VENUE]: `${TP}.m_VENUE`,
  [FILTER_TYPES.CREATOR]: `${TP}.m_COMPOSERS`,
  [FILTER_TYPES.WORK]: `${TP}.FN_MUSICAL_WORKS_SECTION_NAME`,
  [FILTER_TYPES.LANGUAGE]: `${TP}.FN_LANGUAGE_LABEL`,
  [FILTER_TYPES.SURTITLE]: `${TP}.m_SUBTITLES`,
  [FILTER_TYPES.VIDEO_TYPE]: 'Video Types',
  [FILTER_TYPES.REPERTOIRE_STATUS]: 'Future Repertoire',
  [FILTER_TYPES.PUBLISHER]: 'Publishers',
  [FILTER_TYPES.PRODUCTION]: 'Productions',
  [FILTER_TYPES.CONDUCTOR]: `${TP}.m_CONDUCTOR`,
  [FILTER_TYPES.DIRECTOR]: `${TP}.FN_DIRECTOR`,
  [FILTER_TYPES.CO_CAST]: `${TP}.FN_CO_CAST`,
  [FILTER_TYPES.SEASON]: `${TP}.FN_SEASON`,
  [FILTER_TYPES.CAST_CREW]: `${TP}.FN_CAST_CREW`,
  [FILTER_TYPES.GENRE]: `${TP}.FN_GENRE`,
  [FILTER_TYPES.PROFESSION]: `${TP}.FN_PROFESSION`,
};

export const FILTER_AGGREGATION_MAP = {
  [FILTER_TYPES.LANGUAGE]: AGGREGATION_TYPES.LANGUAGE,
  [FILTER_TYPES.CONDUCTOR]: AGGREGATION_TYPES.CONDUCTOR,
  [FILTER_TYPES.DIRECTOR]: AGGREGATION_TYPES.DIRECTOR,
  [FILTER_TYPES.CO_CAST]: AGGREGATION_TYPES.PROFILE,
  [FILTER_TYPES.VENUE]: AGGREGATION_TYPES.VENUE,
  [FILTER_TYPES.CITY]: AGGREGATION_TYPES.CITY,
  [FILTER_TYPES.CREATOR]: AGGREGATION_TYPES.CREATOR,
  [FILTER_TYPES.WORK]: AGGREGATION_TYPES.WORK,
  [FILTER_TYPES.SURTITLE]: AGGREGATION_TYPES.SURTITLE,
  [FILTER_TYPES.SEASON]: AGGREGATION_TYPES.SEASON,
  [FILTER_TYPES.ORGANIZATION]: AGGREGATION_TYPES.ORGANIZATION,
  [FILTER_TYPES.COMPANY]: AGGREGATION_TYPES.COMPANY,
  [FILTER_TYPES.FESTIVAL]: AGGREGATION_TYPES.FESTIVAL,
  [FILTER_TYPES.ORCHESTRA]: AGGREGATION_TYPES.ORCHESTRA,
  [FILTER_TYPES.CHORUS]: AGGREGATION_TYPES.CHORUS,
  [FILTER_TYPES.CAST_CREW]: AGGREGATION_TYPES.CO_CAST,
  [FILTER_TYPES.GENRE]: AGGREGATION_TYPES.GENRE,
  [FILTER_TYPES.PROFESSION]: AGGREGATION_TYPES.PROFESSION,
};

export const FILTER_DEFAULT_EXPANDED = [
  FILTER_TYPES.CITY,
  FILTER_TYPES.DATE,
  FILTER_TYPES.ORGANIZATION,
  FILTER_TYPES.VENUE,
];

export const FILTER_SLUG_TYPE = {
  [FILTER_TYPES.DATE]: URL_SLUG_TYPES.DATE,
  [FILTER_TYPES.CITY]: URL_SLUG_TYPES.CITY,
  [FILTER_TYPES.COUNTRY]: URL_SLUG_TYPES.COUNTRY,
  [FILTER_TYPES.ORGANIZATION]: URL_SLUG_TYPES.ORGANIZATION,
  [FILTER_TYPES.VENUE]: URL_SLUG_TYPES.VENUE,
  [FILTER_TYPES.CREATOR]: URL_SLUG_TYPES.CREATOR,
  [FILTER_TYPES.WORK]: URL_SLUG_TYPES.WORK,
  [FILTER_TYPES.LANGUAGE]: URL_SLUG_TYPES.LANGUAGE,
  [FILTER_TYPES.SURTITLE]: URL_SLUG_TYPES.SURTITLE,
  [FILTER_TYPES.VIDEO_TYPE]: URL_SLUG_TYPES.VIDEO_TYPE,
  [FILTER_TYPES.REPERTOIRE_STATUS]: URL_SLUG_TYPES.REPERTOIRE_STATUS,
  [FILTER_TYPES.PUBLISHER]: URL_SLUG_TYPES.PUBLISHER,
  [FILTER_TYPES.PRODUCTION]: URL_SLUG_TYPES.PRODUCTION,
  [FILTER_TYPES.PRODUCTION_PERIOD]: URL_SLUG_TYPES.PRODUCTION_PERIOD,
  [FILTER_TYPES.VIEW_MODE]: URL_SLUG_TYPES.VIEW_MODE,
  [FILTER_TYPES.BOOLEAN_SEARCH]: URL_SLUG_TYPES.BOOLEAN_SEARCH,
  [FILTER_TYPES.YEAR]: URL_SLUG_TYPES.YEAR,
  [FILTER_TYPES.SINCE_YEAR]: URL_SLUG_TYPES.SINCE_YEAR,
  [FILTER_TYPES.PROFILE]: URL_SLUG_TYPES.ARTIST,
  [FILTER_TYPES.CO_CAST]: URL_SLUG_TYPES.CO_CAST,
  [FILTER_TYPES.CONDUCTOR]: URL_SLUG_TYPES.CONDUCTOR,
  [FILTER_TYPES.DIRECTOR]: URL_SLUG_TYPES.DIRECTOR,
  [FILTER_TYPES.SEASON]: URL_SLUG_TYPES.SEASON,
  [FILTER_TYPES.COMPANY]: URL_SLUG_TYPES.COMPANY,
  [FILTER_TYPES.FESTIVAL]: URL_SLUG_TYPES.FESTIVAL,
  [FILTER_TYPES.ORCHESTRA]: URL_SLUG_TYPES.ORCHESTRA,
  [FILTER_TYPES.CHORUS]: URL_SLUG_TYPES.CHORUS,
  [FILTER_TYPES.CAST_CREW]: URL_SLUG_TYPES.CO_CAST,
  [FILTER_TYPES.GENRE]: URL_SLUG_TYPES.GENRE,
  [FILTER_TYPES.PROFESSION]: URL_SLUG_TYPES.PROFESSION,
};

export const TAB_FILTERS = {
  [ENTITY_MAIN_TABS.HOME]: [
    FILTER_TYPES.DATE,
    FILTER_TYPES.CITY,
    FILTER_TYPES.ORGANIZATION,
    FILTER_TYPES.VENUE,
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.WORK,
  ],
  [ENTITY_MAIN_TABS.PERFORMANCES]: [
    FILTER_TYPES.GENRE,
    FILTER_TYPES.PROFESSION,
    FILTER_TYPES.SEASON,
    FILTER_TYPES.CITY,
    FILTER_TYPES.WORK,
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.DIRECTOR,
    FILTER_TYPES.CONDUCTOR,
    FILTER_TYPES.CAST_CREW,
    FILTER_TYPES.COMPANY,
    FILTER_TYPES.FESTIVAL,
    FILTER_TYPES.ORCHESTRA,
    FILTER_TYPES.CHORUS,
    FILTER_TYPES.LANGUAGE,
    FILTER_TYPES.VENUE,
    FILTER_TYPES.SURTITLE,
  ],
  [ENTITY_MAIN_TABS.CALENDAR]: [
    FILTER_TYPES.GENRE,
    FILTER_TYPES.PROFESSION,
    FILTER_TYPES.SEASON,
    FILTER_TYPES.CITY,
    FILTER_TYPES.ORGANIZATION,
    FILTER_TYPES.VENUE,
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.WORK,
    FILTER_TYPES.LANGUAGE,
    FILTER_TYPES.SURTITLE,
  ],
  [ENTITY_MAIN_TABS.VIDEOS]: [],
  [ENTITY_MAIN_TABS.IMAGES]: [],
  [ENTITY_MAIN_TABS.REPERTOIRE]: [FILTER_TYPES.CREATOR, FILTER_TYPES.WORK],
  [ENTITY_MAIN_TABS.ORG_GRAPH]: [
    FILTER_TYPES.CITY,
    FILTER_TYPES.ORGANIZATION,
    FILTER_TYPES.VENUE,
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.WORK,
    FILTER_TYPES.LANGUAGE,
  ],
  [ENTITY_MAIN_TABS.ROLE_GRAPH]: [
    FILTER_TYPES.CITY,
    FILTER_TYPES.ORGANIZATION,
    FILTER_TYPES.VENUE,
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.WORK,
    FILTER_TYPES.LANGUAGE,
  ],
  [ENTITY_MAIN_TABS.REVIEWS]: [],
};

export const ENTITY_TAB_FILTERS = {
  [ENTITY_TYPE.ARTIST]: {
    ...TAB_FILTERS,
    [ENTITY_MAIN_TABS.HOME]: [],
  },
  [ENTITY_TYPE.PRODUCTION]: {
    [ENTITY_MAIN_TABS.HOME]: [FILTER_TYPES.DATE],
  },
  [ENTITY_TYPE.ORGANIZATION]: {
    [ENTITY_MAIN_TABS.HOME]: [],
    [ENTITY_MAIN_TABS.PERFORMANCES]: [
      FILTER_TYPES.GENRE,
      FILTER_TYPES.SEASON,
      FILTER_TYPES.CITY,
      FILTER_TYPES.WORK,
      FILTER_TYPES.CREATOR,
      FILTER_TYPES.DIRECTOR,
      FILTER_TYPES.CONDUCTOR,
      FILTER_TYPES.CAST_CREW,
      FILTER_TYPES.COMPANY,
      FILTER_TYPES.FESTIVAL,
      FILTER_TYPES.ORCHESTRA,
      FILTER_TYPES.CHORUS,
      FILTER_TYPES.LANGUAGE,
      FILTER_TYPES.VENUE,
      FILTER_TYPES.SURTITLE,
    ],
    [ENTITY_MAIN_TABS.VIDEOS]: [],
    [ENTITY_MAIN_TABS.IMAGES]: [],
    [ENTITY_MAIN_TABS.REVIEWS]: [],
    [ENTITY_MAIN_TABS.RENTALS]: [
      FILTER_TYPES.SEASON,
      FILTER_TYPES.CITY,
      FILTER_TYPES.VENUE,
      FILTER_TYPES.CREATOR,
      FILTER_TYPES.WORK,
      FILTER_TYPES.LANGUAGE,
      FILTER_TYPES.SURTITLE,
    ],
  },
};

export const PRO_FILTERS = [
  FILTER_TYPES.ORGANIZATION,
  FILTER_TYPES.REPERTOIRE_STATUS,
  FILTER_TYPES.CREATOR,
  FILTER_TYPES.LANGUAGE,
  FILTER_TYPES.SURTITLE,
];

export const TAB_H1_FILTER_PARTS = {
  [ENTITY_MAIN_TABS.PERFORMANCES]: [FILTER_TYPES.CITY, FILTER_TYPES.WORK, FILTER_TYPES.DATE, FILTER_TYPES.SEASON],
  [ENTITY_MAIN_TABS.VIDEOS]: [FILTER_TYPES.WORK, FILTER_TYPES.VENUE, FILTER_TYPES.DATE],
  [ENTITY_MAIN_TABS.IMAGES]: [FILTER_TYPES.WORK, FILTER_TYPES.VENUE, FILTER_TYPES.DATE],
  [ENTITY_MAIN_TABS.REPERTOIRE]: [FILTER_TYPES.CITY, FILTER_TYPES.WORK, FILTER_TYPES.DATE],
  [ENTITY_MAIN_TABS.REVIEWS]: [FILTER_TYPES.WORK],
};

export const SORT_ORDERS = {
  YEAR_ASCENDING: 'year',
  YEAR_DESCENDING: '-year',
};

export const VALID_VIEW_MODE_OPTIONS = [
  VIEW_MODES_VALUE.SUMMARY,
  VIEW_MODES_VALUE.DETAILED,
  VIEW_MODES_VALUE.CALENDAR,
  VIEW_MODES_VALUE.DAY,
  VIEW_MODES_VALUE.PRO,
  VIEW_MODES_VALUE.AOS_PRO,
];

export const ADVANCED_FILTER_TYPES = {
  [ENTITY_TYPE.ARTIST]: [
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.DIRECTOR,
    FILTER_TYPES.CONDUCTOR,
    FILTER_TYPES.CAST_CREW,
    FILTER_TYPES.COMPANY,
    FILTER_TYPES.FESTIVAL,
    FILTER_TYPES.ORCHESTRA,
    FILTER_TYPES.CHORUS,
    FILTER_TYPES.LANGUAGE,
    FILTER_TYPES.VENUE,
    FILTER_TYPES.SURTITLE,
  ],
  [ENTITY_TYPE.ORGANIZATION]: [
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.DIRECTOR,
    FILTER_TYPES.CONDUCTOR,
    FILTER_TYPES.CAST_CREW,
    FILTER_TYPES.COMPANY,
    FILTER_TYPES.FESTIVAL,
    FILTER_TYPES.ORCHESTRA,
    FILTER_TYPES.CHORUS,
    FILTER_TYPES.LANGUAGE,
    FILTER_TYPES.VENUE,
    FILTER_TYPES.SURTITLE,
  ],
};
