/* eslint-disable no-restricted-properties */
/* eslint-disable no-console */
import _pick from 'lodash/pick';
import { RESPONSE_STATUS_CODES } from 'constants/index';
import { sendEventsToBackend, checkHealth, refreshAuthToken, getAuthToken } from './service';
import { ANALYTICS_SERVICES, EVENT_TYPES, GOOGLE_ANALYTICS_COMMON_DATA_KEYS } from './constants';
import transformers from './transformers';

/**
 * Read EventQueueManager.md for documentation
 */
class EventQueueManager {
  constructor({
    batchSize = 10,
    apiCallback,
    retryLimit = 3,
    retryDelay = 2000,
    batchTimeout = 5000,
    defaultEnabledServices = [],
  }) {
    this.events = [];
    this.commonData = {};
    this.batchSize = batchSize;
    this.apiCallback = apiCallback;
    this.retryLimit = retryLimit;
    this.retryDelay = retryDelay;
    this.batchTimeout = batchTimeout;
    this.timer = null;
    this.testingWindow = null;
    this.isFetchingAccessToken = false;
    this.defaultEnabledServicesMap = defaultEnabledServices?.reduce((acc, service) => {
      acc[service] = true;

      return acc;
    }, {});
  }

  async init() {
    this.isFetchingAccessToken = true;
    await getAuthToken();
    this.isFetchingAccessToken = false;
  }

  updateCommonData(newCommonData) {
    this.commonData = { ...newCommonData };
  }

  updateGAcommonData(newGACommonData) {
    this.googleAnalyticsCommonData = { ...newGACommonData };
  }

  handleOldGoogleEvents(event) {
    const { data, ...eventWithoutData } = event;

    const googleAnalyticsData = {
      ..._pick(this.commonData, GOOGLE_ANALYTICS_COMMON_DATA_KEYS),
      ...this.googleAnalyticsCommonData,
      ...eventWithoutData,
      ...data,
    };

    this.sendToGoogleAnalytics(googleAnalyticsData);
  }

  handleGoogleEvents(event) {
    const isPageView = event.eventType === EVENT_TYPES.PAGE_VIEW;
    const disableImpressionTracking =
      process.env.APP_ENV === 'production' && event.eventType === EVENT_TYPES.IMPRESSION;

    if (isPageView || disableImpressionTracking) {
      return;
    }

    const googleEventData = {
      ..._pick(this.commonData, GOOGLE_ANALYTICS_COMMON_DATA_KEYS),
      ...this.googleAnalyticsCommonData,
      ...transformers[ANALYTICS_SERVICES.GOOGLE](event),
    };

    this.sendToGoogleAnalytics(googleEventData);
  }

  addEvent(event, options) {
    const { services, forceFlush = false } = options || {};
    const enabledServices =
      services?.reduce((acc, service) => {
        acc[service] = true;

        return acc;
      }, {}) || this.defaultEnabledServicesMap;

    if (enabledServices[ANALYTICS_SERVICES.GOOGLE_OLD]) {
      this.handleOldGoogleEvents(event);
    }

    if (enabledServices[ANALYTICS_SERVICES.GOOGLE]) {
      this.handleGoogleEvents(event);
    }

    if (enabledServices[ANALYTICS_SERVICES.ARTS_CONSOLIDATED]) {
      this.processEvent(event, forceFlush);
    }
  }

  updateTestingWindow(testingWindow) {
    this.testingWindow = testingWindow;
  }

  processEvent(event, forceFlush = false) {
    this.events.push(event);

    if (forceFlush || this.events.length >= this.batchSize) {
      this.flush();
    } else if (!this.timer) {
      this.startTimer();
    }
  }

  startTimer() {
    this.timer = setTimeout(() => {
      this.flush();
    }, this.batchTimeout);
  }

  async handleExpiredToken() {
    if (!this.isFetchingAccessToken) {
      this.isFetchingAccessToken = true;
      await refreshAuthToken();
      this.isFetchingAccessToken = false;
    }
  }

  async sendAPIRequest(payload, retryCount = 0) {
    try {
      const apiResponse = await sendEventsToBackend(payload);

      this.apiCallback(apiResponse);
    } catch (error) {
      const response = error?.response || error;
      const status = response?.status;

      if (status === RESPONSE_STATUS_CODES.FORBIDDEN) {
        await this.handleExpiredToken();
        this.sendAPIRequest(payload, retryCount + 1);
      } else if (retryCount < this.retryLimit) {
        const isServiceHealthy = await checkHealth(this.retryLimit, this.retryDelay);
        if (isServiceHealthy) {
          setTimeout(() => this.sendAPIRequest(payload, retryCount + 1), this.retryDelay * Math.pow(2, retryCount));
        } else {
          console.error('Service health check failed. No further retries will be attempted.');
          this.handleFailedTransmission(payload);
        }
      } else {
        console.error('Retry limit reached. No further retries will be attempted.');
        this.handleFailedTransmission(payload);
      }
    }
  }

  flush() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (this.isFetchingAccessToken) {
      this.startTimer();
      return;
    }

    if (!this.commonData.visitorId || this.events.length === 0) {
      return;
    }

    const batchedEvents = this.events.splice(0, this.batchSize);

    if (typeof this.testingWindow?.postMessage === 'function') {
      this.testingWindow.postMessage({
        events: batchedEvents,
        type: 'analytics',
      });
    }

    const eventsToSend = batchedEvents.map(transformers[ANALYTICS_SERVICES.ARTS_CONSOLIDATED]);
    const payload = {
      ...this.commonData,
      events: eventsToSend,
    };

    this.sendAPIRequest(payload);
  }

  sendToGoogleAnalytics(event) {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({ event: event.name, ...event });
    }
  }

  handleFailedTransmission(payload) {
    console.error('Final attempt failed. Payload will be stored for later retry:', payload);
  }
}

export default EventQueueManager;
