// TODO: Move these functions to utils/globals/sentry.js & remove reference to bugsnag
import { sentryCaptureException } from 'utils/globals/sentry';

export const bugsnagNotify = (err, context) => {
  const error = new Error(err);

  sentryCaptureException(`${error.name}: ${error.message}`, {
    extra: context,
  });
};
