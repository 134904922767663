import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import { performancesAggregationTypes } from 'constants/deprecate';
import {
  cleanCastWorksRolesListAction,
  getCastWorksListAction,
  getCastWorksRolesListAction,
  getProfessionRoleListAction,
  getProfessionRolesAction,
  getRolesAction,
} from './actions';
import * as API from '../../utils/API';
import { WORK_ROLES } from '../../constants/endpointConstants';
import { getListSaga } from '../App/saga';
import {
  VALIDATION_STATUSES,
} from '../../constants/consts';

export function* fetchCastWorksList({ payload, serverCookies }) {
  try {
    const pagination = payload.pagination || { limit: 10 };
    const response = yield call(API.getWorks, payload.params, pagination, serverCookies);
    yield put(getCastWorksListAction.success(get(response, 'data.data')));
  } catch (err) {
    yield put(getCastWorksListAction.failure(err.message));
  } finally {
    yield put(getCastWorksListAction.fulfill());
  }
}

export function* getRolesSaga({ payload, serverCookies }) {
  try {
    const { castsListParams } = payload;
    const { workRoleAgg, workAgg, countryAgg } = performancesAggregationTypes;

    yield put(cleanCastWorksRolesListAction.trigger());
    const workRoles = yield call(
      API.getAggregations,
      false,
      {
        aggregation_type: workRoleAgg,
        work_id: get(castsListParams, workAgg, []).join(','),
        country_id: get(castsListParams, countryAgg, []).join(','),
        date_from: get(castsListParams, 'date_from', ''),
        validation_status_id: { id: VALIDATION_STATUSES.APPROVED },
      },
      { limit: 100 },
      null,
      serverCookies,
    );
    const workRoleIds = get(workRoles, 'data.data', []).map(role => role?.id);
    if (workRoleIds.length > 0) {
      yield call(getListSaga, {
        payload: {
          endpoint: WORK_ROLES,
          sagaRoutine: getCastWorksRolesListAction,
          params: {
            queryParams: {
              id: workRoleIds.join(','),
            },
            pagination: { limit: 100 },
          },
        },
        serverCookies,
      });
    }
    yield put(getRolesAction.success());
  } catch (err) {
    yield put(getRolesAction.failure(err.message));
  } finally {
    yield put(getRolesAction.fulfill());
  }
}

export function* getProfessionRolesSaga({ payload, serverCookies }) {
  try {
    yield call(getListSaga, {
      payload: {
        endpoint: WORK_ROLES,
        sagaRoutine: getProfessionRoleListAction,
        params: {
          queryParams: {
            work_id: payload?.query?.workId,
          },
          pagination: { limit: 100 },
        },
      },
      serverCookies,
    });
    yield put(getProfessionRolesAction.success());
  } catch (err) {
    yield put(getProfessionRolesAction.failure(err.message));
  } finally {
    yield put(getProfessionRolesAction.fulfill());
  }
}

export default function* castSaga() {
  yield takeLatest(getCastWorksListAction.TRIGGER, fetchCastWorksList);
  yield takeLatest(getRolesAction.TRIGGER, getRolesSaga);
  yield takeLatest(getProfessionRolesAction.TRIGGER, getProfessionRolesSaga);
}
