import route from './routes';

export const routesWithTitle = {
  ACCOUNT_SETTINGS: {
    path: route.ACCOUNT_SETTINGS,
    title: 'operabase.general.m_SIDEBAR_SETTINGS',
  },
  ACCOUNT_SUBSCRIPTIONS: {
    path: route.ACCOUNT_SUBSCRIPTIONS,
    title: '',
  },
  ARTICLES: {
    path: route.ARTICLES,
    title: 'operabase.general.m_SIDEBAR_ARTICLES',
  },
  ARTISTS: {
    path: route.ARTISTS,
    as: '/artists',
    searchPath: route.ARTIST_SEARCH,
    asSearchPath: '/artists',
    title: 'operabase.general.m_ARTISTS',
  },
  CASTING_TOOL: {
    path: route.CASTING_TOOL,
    as: '/casting',
    title: 'operabase.general.FN_CASTINGTOOL',
  },
  COMPANIES: {
    path: route.COMPANIES,
    as: '/companies',
    searchPath: route.COMPANIES_SEARCH,
    asSearchPath: '/companies/search',
    title: 'operabase.general.m_OPCOS',
  },
  COMPETITIONS: {
    path: route.COMPETITIONS,
    title: 'operabase.general.m_COMPETITIONS',
  },
  COMPOSERS: {
    path: route.COMPOSERS,
    title: 'operabase.general.m_COMPOSERS',
  },
  CONTACT_OB: {
    path: route.CONTACT_OB,
    title: 'operabase.general.m_CONTACTUS',
  },
  COOKIE_POLICY: {
    path: route.COOKIE_POLICY,
    title: '',
  },
  FESTIVALS: {
    path: route.FESTIVALS,
    as: '/festivals',
    searchPath: route.FESTIVALS_SEARCH,
    asSearchPath: '/festivals/search',
    title: 'operabase.general.m_FESTIVALS',
  },
  HIGHLIGHTS: {
    path: route.HIGHLIGHTS,
    title: 'operabase.general.m_HIGHLIGHTS',
  },
  HOME: {
    path: route.HOME,
    as: '/',
    title: 'operabase.general.m_HOME',
  },
  VIDEOS: {
    path: route.VIDEOS,
    title: 'operabase.general.FN_LSVOD_TITLE',
  },
  DETAILS_AGENTS: {
    path: route.DETAILS_AGENTS,
    title: 'operabase.general.m_PROAGENT',
  },
  DETAILS_AOS: {
    path: route.DETAILS_AOS,
    title: 'operabase.general.m_PROAO',
  },
  DETAILS_ARTISTS: {
    path: route.DETAILS_ARTISTS,
    title: 'operabase.general.m_PROARTIST',
  },
  PRO_INDUSTRY: {
    path: route.DETAILS_AOS,
    title: 'operabase.general.FN_PRO_SUB',
  },
  INTRO_ARCHIVES: {
    path: route.INTRO_ARCHIVES,
    title: '',
  },
  INTRO_AUDIENCE: {
    path: route.INTRO_AUDIENCE,
    title: 'operabase.general.PROAUDIENCE',
  },
  FOR_ACADEMIA: {
    path: route.INTRO_ACADEMIA,
    title: 'operabase.general.FOR_ACADEMIA',
  },
  FOR_JOURNALISTS: {
    path: route.INTRO_JOURNALISTS,
    title: 'operabase.general.FOR_JOURNALISTS',
  },
  SEASON_PLANNER: {
    path: route.DETAILS_AOS,
    title: 'operabase.general.FN_SEASON_PLANNER_PUBLISHER',
  },
  INTRODUCTION_HISTORY: {
    path: route.INTRO_OB,
    title: 'operabase.general.FN_INTRODUCTION_HISTORY',
  },
  INTRO_OB: {
    path: route.INTRO_OB,
    title: 'operabase.general.m_INTRODUCTION',
  },
  JOURNALISTS_AND_RESEARCH: {
    path: route.JOURNALISTS_AND_RESEARCH,
    title: '',
  },
  LIBRARY: {
    path: route.LIBRARY,
    title: '',
  },
  LOGIN: {
    path: route.LOGIN,
    title: 'operabase.general.m_LOGIN',
  },
  MANAGERS: {
    path: route.MANAGERS,
    searchPath: route.MANAGERS_SEARCH,
    title: 'operabase.general.m_MANAGERS',
  },
  MAPS: {
    path: route.MAPS,
    title: 'operabase.general.m_MAPS',
  },
  NEW_RARE: {
    path: route.NEW_RARE,
    title: 'operabase.general.m_SIDEBAR_NEW_AND_RARE',
  },
  ON_THESE_DAY: {
    path: route.ON_THESE_DAY,
    title: 'operabase.general.m_ONTHISDAY',
  },
  PRIVACY: {
    path: route.PRIVACY,
    title: '',
  },
  PRODUCTIONS: {
    path: route.PRODUCTIONS,
    title: 'operabase.general.m_PERF',
  },
  REGISTER: {
    path: route.REGISTER,
    title: '',
  },
  RENTALS: {
    path: route.RENTALS,
    title: 'operabase.general.m_RENTALS',
  },
  RESET_PASSWORD: {
    path: route.RESET_PASSWORD,
    title: 'operabase.general.m_LOGIN_FORGOT_PASSWORD_BREADCRUMB',
  },
  SEASONS: {
    path: route.SEASONS,
    title: 'operabase.general.m_SEASON',
  },
  SEARCH: {
    path: route.SEARCH,
    title: 'operabase.general.SEARCH',
  },
  SEARCH_HISTORY: {
    path: route.SEARCH_HISTORY,
    title: 'operabase.general.SRCH_RECENT_SEARCHES',
  },
  VISITS_HISTORY: {
    path: route.VISITS_HISTORY,
    title: 'operabase.general.SRCH_RECENTLY_VISITED',
  },
  STATISTICS: {
    path: route.STATISTICS,
    title: 'operabase.general.m_STATISTICS',
  },
  STATS_NUMBERS: {
    path: route.STATS_NUMBERS,
    title: '',
  },
  TERMS_AND_CONDITIOS: {
    path: route.TERMS_AND_CONDITIOS,
    title: '',
  },
  VENUES: {
    path: route.VENUES,
    searchPath: route.VENUES_SEARCH,
    title: 'operabase.general.m_VENUES',
  },
  WORK_WITH_OB: {
    path: route.WORK_WITH_OB,
    title: '',
  },
  WORKS: {
    path: route.WORKS,
    title: 'operabase.general.FN_MUSICAL_WORKS_SECTION_NAME',
  },
  INDUSTRY_PROFESSION: {
    path: route.INTRO_AOS,
    title: 'operabase.general.PRO_PAGE_INDUSTRY',
  },
};

export default routesWithTitle;
